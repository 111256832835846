<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-business-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-edit-business-sidebar>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center">
                <div class="top-heading col-md-3">
                    Business List
                </div>
                <div class="col-md-9">
                    <div class="row align-center" style="justify-content: flex-end;">

                        <div class="col-md-6" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-3">
                            <button class="add-new-emp" (click)="addNewBusiness()">
                                Add New Business
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfBusinessToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Business available.
                </div>
            </div>

            <div *ngIf="listOfBusinessToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="userName" scope="col" class="table-heading-content">Owner Name</th>
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="phone" scope="col" class="table-heading-content">Owner Phone</th>
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="phone" scope="col" class="table-heading-content">Owner Email</th>
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="createdAt" scope="col" class="table-heading-content">Creation Date
                        </th>
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="status" scope="col" class="table-heading-content">Status</th>
                        <th style="cursor: pointer;     width: 10em;
                        text-align: center;" class="table-heading-content">View Turfs</th>
                        <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                            data-name="status" scope="col" class="table-heading-content">Active</th>
                        <th class="table-heading-content">Action</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let business of listOfBusinessToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                class="table-data-content">{{business.userName}}</th>
                            <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                class="table-data-content">{{business.phone}}</th>
                            <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                class="table-data-content">{{business.email}}</th>
                            <th class="table-data-content">{{business.createdAt|date}}</th>

                            <th class="table-data-content">{{business.status}}</th>
                            <th class="table-data-content" style="text-decoration: underline;color: blue;cursor: pointer;    width: 10em;
                            text-align: center;" (click)="viewTurfOfBusiness(business)">view</th>
                            <th class="table-data-content" style="padding-left: 2rem;">
                                <img *ngIf="business.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                    (click)="suspendBusiness(business,false)" slot="start" />
                                <img *ngIf="!business.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                    (click)="suspendBusiness(business,true)" slot="start" />
                            </th>
                            <th class="table-data-content" style="width:10em;text-align-last: center;">
                                <div style="display: contents;">
                                    <img title="Edit" (click)="editBusiness(business)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>

                                    <img title="View" (click)="viewBusinessDetails(business)"
                                        style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/view.png'>
                                    <img title="Delete" (click)="deleteBusiness(business)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>
            </div>

        </div>
    </ng-sidebar-container>
</div>


<!-- <div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Business List
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

                <div class="col-md-6" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="add-new-emp" (click)="addNewBusiness()">
                        Add New Business
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-edit-business-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit">
                </app-add-edit-business-sidebar>
            </ng-sidebar>
            <div ng-sidebar-content style="overflow: hidden;padding: 1em;">


                <div *ngIf="listOfBusinessToDisplay.length==0">
                    <div class="no-available">
                        No Business available.
                    </div>
                </div>
                <div *ngIf="listOfBusinessToDisplay.length!=0">
                    <table class="table-style">
                        <thead class="table-heading">
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="userName" scope="col" class="table-heading-content">Owner Name</th>
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="phone" scope="col" class="table-heading-content">Owner Phone</th>
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="phone" scope="col" class="table-heading-content">Owner Email</th>
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="createdAt" scope="col" class="table-heading-content">Creation Date
                            </th>
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="status" scope="col" class="table-heading-content">Status</th>
                            <th style="cursor: pointer;     width: 10em;
                            text-align: center;" class="table-heading-content">View Turfs</th>
                            <th style="cursor: pointer;" [appSort]="listOfBusinessToDisplay" data-order="desc"
                                data-name="status" scope="col" class="table-heading-content">Active</th>
                            <th class="table-heading-content">Action</th>
                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let business of listOfBusinessToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                                <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                    class="table-data-content">{{business.userName}}</th>
                                <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                    class="table-data-content">{{business.phone}}</th>
                                <th (click)="viewBusinessDetails(business)" style="cursor: pointer;"
                                    class="table-data-content">{{business.email}}</th>
                                <th class="table-data-content">{{business.createdAt|date}}</th>

                                <th class="table-data-content">{{business.status}}</th>
                                <th class="table-data-content" style="text-decoration: underline;color: blue;cursor: pointer;    width: 10em;
                                text-align: center;" (click)="viewTurfOfBusiness(business)">view</th>
                                <th class="table-data-content" style="padding-left: 2rem;">
                                    <img *ngIf="business.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-on"
                                        src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                        (click)="suspendBusiness(business,false)" slot="start" />
                                    <img *ngIf="!business.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-off"
                                        src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                        (click)="suspendBusiness(business,true)" slot="start" />
                                </th>
                                <th class="table-data-content" style="width:10em;text-align-last: center;">
                                    <div style="display: contents;">
                                        <img title="Edit" (click)="editBusiness(business)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>

                                        <img title="View" (click)="viewBusinessDetails(business)"
                                            style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/view.png'>
                                        <img title="Delete" (click)="deleteBusiness(business)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'>
                                    </div>

                                </th>
                            </tr>

                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>

            </div>
        </ng-sidebar-container>
    </div>
</div> -->