<div class="container__campaignList">
    <div class="container__campaignList__title">
      <div class="container__campaignList__title__text1">
        {{ turfId!=null ? 'Edit' : 'Add'}} Venue
      </div>
    </div>
  
    <div class="container__campaignList__tabs">
      <div class="container__campaignList__tabs__box">
        <mat-horizontal-stepper (selectionChange)="onStepperChangeHandler($event)" #stepper>
          <mat-step label="About Venue">
            <div class="dashedLine"></div>
            <app-about-venue [isAdd]="true" (closeEdit)="changeRoute($event)" [listOfBusiness]="listOfBusiness"
              (backToPreviousStepper)="stepperBackHandler()"></app-about-venue>
          </mat-step>
  
          <mat-step label="Address Details">
            <div class="dashedLine"></div>
            <app-address-details [isAdd]="true" (closeEdit)="changeRoute($event)" [turfInfo]="turfInfo"
              (backToPreviousStepper)="stepperBackHandler()"></app-address-details>
          </mat-step>
  
          <mat-step label="Add Slots">
            <div class="dashedLine"></div>
            <app-add-slots (closeEdit)="changeRoute($event)" [listOfSports]="listOfSports" [turfInfo]="turfInfo"
              (backToPreviousStepper)="stepperBackHandler()"></app-add-slots>
          </mat-step>
  
          <mat-step label="Add Photos">
            <div class="dashedLine"></div>
            <app-add-photos (closeEdit)="changeRoute($event)" [turfInfo]="turfInfo"
              (backToPreviousStepper)="stepperBackHandler()"></app-add-photos>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  
    <div style="grid-column: 1/-1">
      <router-outlet></router-outlet>
    </div>
  </div>