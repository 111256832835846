import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import CryptoJS from "crypto-js";

@Component({
  selector: 'app-add-edit-employee',
  templateUrl: './add-edit-employee.component.html',
  styleUrls: ['./add-edit-employee.component.scss'],
})
export class AddEditEmployeeComponent implements OnInit {
  @Input() dataToEdit: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  httpHeaders: any;

  myHeaders = new Headers();
  formdata = new FormData();

  lngDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };

  listOfRights: any = [
    { name: 'Dashboard' },
    { name: 'Booking' },
    { name: 'Business Management' },
    { name: 'Venue Management' },
    { name: 'Rentals' },
    { name: 'Offer Management' },
    { name: 'Sport Management' },
    { name: 'Employee' },
    { name: 'Contests' },
    { name: 'Campaign Management' },
    { name: 'Compliance' },
    { name: 'Withdrawal' },
    { name: 'CRM' },
    { name: 'Report' },
  ];
  selectedRights: any = [];

  fullNameControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
  ]);
  employeeTypeControl = new FormControl('Select Type', [
    Validators.required,
  ]);

  emailControl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$'
    ),
  ]);

  phoneControl = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);

  addEmployeeForm: FormGroup = new FormGroup({
    fullName: this.fullNameControl,
    employeeType: this.employeeTypeControl,
    email: this.emailControl,
    password: this.passwordControl,
  });
  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  async ngOnInit(): Promise<void> {
    this.addEmployeeForm = this.formBuilder.group({
      fullName: this.fullNameControl,
      employeeType: this.employeeTypeControl,
      email: this.emailControl,
      phone: this.phoneControl,
      password: this.passwordControl,
    });
    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    this.assignEditValues();
  }
  async assignEditValues() {
    if (this.dataToEdit != null) {
      this.addEmployeeForm = this.formBuilder.group({
        fullName: this.fullNameControl,
        employeeType: this.employeeTypeControl,
        email: this.emailControl,
        phone: this.phoneControl,
        password: this.passwordControl,
      });

      this.addEmployeeForm
        .get('fullName')
        ?.setValue(this.dataToEdit['fullName']);
      this.addEmployeeForm
        .get('employeeType')
        ?.setValue(this.dataToEdit['employeeType']);
      this.addEmployeeForm.get('email')?.setValue(this.dataToEdit['email']);
      this.addEmployeeForm.get('phone')?.setValue(this.dataToEdit['phone']);

      var bytes = CryptoJS.AES.decrypt(this.dataToEdit['password'], "$Future!@35");
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      this.addEmployeeForm.get('password')?.setValue(decryptedData);
      this.dataToEdit['accessControlList'].forEach((data: any) => {
        this.selectedRights.push({ name: data });
      });
    }
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  editUser() {
    this.isLoading = true;

    var formdata = new FormData();

    if (this.dataToEdit != null) {
      formdata.set('employeeId', this.dataToEdit['_id']);
    } else {
      formdata.set('employeeId', '0');
    }

    formdata.set('fullName', this.addEmployeeForm.get('fullName')?.value);
    formdata.set('employeeType', this.addEmployeeForm.get('employeeType')?.value);
    formdata.set('email', this.addEmployeeForm.get('email')?.value);
    formdata.set('phone', this.addEmployeeForm.get('phone')?.value);
    formdata.set('password', this.addEmployeeForm.get('password')?.value);

    var rights: any = [];

    this.selectedRights.forEach((data: any) => {
      rights.push(data.name);
    });


    formdata.set('accessControlList', JSON.stringify(rights));

    var headers = new Headers();

    headers.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.addAndEditEmployees}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addEmployeeForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                if (res['message'] == 'PHONE_EXIST') {
                  this.showErrorToaster(
                    'Phone Number is already in use.',
                    'Failed'
                  );
                } else if (res['message'] == 'EMAIL_EXIST') {
                  this.showErrorToaster(
                    'Email Address is already in use.',
                    'Failed'
                  );
                } else {
                  this.showErrorToaster('Something went wrong', 'Failed');
                }
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }

  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
