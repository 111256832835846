import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-bank-detail',
  templateUrl: './add-bank-detail.component.html',
  styleUrls: ['./add-bank-detail.component.scss']
})
export class AddBankDetailComponent implements OnInit {
  @Input() businessInfo: any;
  @Input() isAdd: boolean = false;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  userDetails: any;
  businessId: any;
  userId: any;

  isLoading: boolean = false;
  addBankForm: FormGroup;
  formdata = new FormData();
  submitted: boolean = false;

  isAddEditBankDetailLoading: boolean = false;

  selectedPancardImage: any = null;
  selectedPancardImageName: any = null;
  pancardPreview: any;

  cancelledChequeLaefPreview: any;
  selectedChequeLeafImage: any = null;
  selectedChequeLeafImageName: any = null;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) { 
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this._activatedRoute.queryParams.subscribe(params => {
      this.businessId = params['id'];
      console.log('businessId bank',this.businessId);
    });

    this.addBankForm = this._formBuilder.group({
      pancardNumber: new FormControl('', [Validators.required, Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]$')]),
      accountHolderName: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      accountNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      ifscCode: new FormControl('', [Validators.required, Validators.pattern('^[A-Z]{4}[A-Z0-9]{7}$')]),
    });
  }

  ngOnInit(): void {
    if(this.businessId){
      this.fetchBusinessByIdv2();
    }
  }

  trimPancard() {
    const pancardNumberControl = this.addBankForm.get('pancardNumber');
    if (pancardNumberControl && pancardNumberControl.value) {
      pancardNumberControl.setValue(pancardNumberControl.value.trim());
    }
  }

  fetchBusinessByIdv2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchBusinessByIdv2 + `/${this.businessId}`}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.businessInfo = data.result;
          console.log(this.businessInfo);
          if (this.businessInfo && this.businessInfo?.user && this.businessInfo?.user?._id && this.businessInfo?.document && this.businessInfo?.bankDetails) {
            this.userId = this.businessInfo.user._id;
            console.log(this.userId);

          this.addBankForm.get('pancardNumber')?.setValue(this.businessInfo['document'][0]?.docNumber);
          this.addBankForm.get('accountHolderName')?.setValue(this.businessInfo['bankDetails'][0]?.accountHolderName);
          this.addBankForm.get('bankName')?.setValue(this.businessInfo['bankDetails'][0]?.bankName);
          this.addBankForm.get('accountNumber')?.setValue(this.businessInfo['bankDetails'][0]?.accountNumber);
          this.addBankForm.get('ifscCode')?.setValue(this.businessInfo['bankDetails'][0]?.ifscCode);

          this.cancelledChequeLaefPreview = this.businessInfo['bankDetails'][0]?.chequeImageUrl;
          this.pancardPreview =  this.businessInfo['document'][0]?.docUrl;
        } else {
          console.error('User or user._id is undefined or null');
        }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  selectCancelledLeafChequeFile(event: any) {
    this.selectedChequeLeafImage = event.target.files[0];
    this.selectedChequeLeafImageName =this.selectedChequeLeafImage['name'];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.cancelledChequeLaefPreview = event.target.result;
    };
    -reader.readAsDataURL(this.selectedChequeLeafImage);
  }

  selectPancardFile(event: any) {
    this.selectedPancardImage = event.target.files[0];
    this.selectedPancardImageName = this.selectedPancardImage['name'];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.pancardPreview = event.target.result;
    };
    -reader.readAsDataURL(this.selectedPancardImage);
  }

  removeCancelledLeafChequeImage() {
    this.cancelledChequeLaefPreview = null;
    this.selectedChequeLeafImage = null;
  }
  removePancardImage() {
    this.pancardPreview = null;
    this.selectedPancardImage = null;
  }

  downloadImage(url: any) {
    window.open(url);
  }

  get f() {
    return this.addBankForm.controls;
  }

  validateBankDetailStep(): boolean {
    const addressVenueFormValid = this.addBankForm.valid;

    const anyFieldEmpty = Object.values(this.addBankForm.value).some(value => value === '');

    if (anyFieldEmpty) {
      this.showWarningToaster('Please fill in all required fields in the Bank Detail', 'Warning');
    }
    if (!addressVenueFormValid) {
      this.submitted = true;
      this.showWarningToaster('Please fill valid details in the Bank Detail', 'Warning');
    }

    return addressVenueFormValid && !anyFieldEmpty;
  }

  onSubmitBusinessBankDetail() {
    this.isAddEditBankDetailLoading = true;

    if (!this.validateBankDetailStep()) {
      this.isLoading = false;
      this.submitted = true;
      return;
    }


    if (this.businessId != null) {
      this.formdata.set('businessId', this.businessId);
    }

    console.log(this.businessId);

    if(this.businessId){
      this.formdata.set('businessId', this.businessId);
      this.formdata.set('userId', this.userId);
        this.formdata.append(
          'panCardUrl',
          this.pancardPreview
        );
        this.formdata.append(
          'chequeImageUrl',
          this.cancelledChequeLaefPreview
        );
    }

    if (this.selectedPancardImage != null) {
      this.formdata.append(
        'panCardUrl',
        this.selectedPancardImage,
        this.selectedPancardImageName
      );
    }
    if (this.selectedChequeLeafImage != null) {
      this.formdata.append(
        'chequeImageUrl',
        this.selectedChequeLeafImage,
        this.selectedChequeLeafImageName
      );
    }

    this.formdata.set(
      'pancardNumber',
      this.addBankForm.get('pancardNumber')?.value
    );
    this.formdata.set(
      'accountHolderName',
      this.addBankForm.get('accountHolderName')?.value
    );
    this.formdata.set(
      'bankName',
      this.addBankForm.get('bankName')?.value
    );
    this.formdata.set(
      'accountNumber',
      this.addBankForm.get('accountNumber')?.value
    );
    this.formdata.set(
      'ifscCode',
      this.addBankForm.get('ifscCode')?.value
    );

    var requestOptions: RequestInit = {
      method: 'POST',
      body: this.formdata,
      redirect: 'follow',
      headers: new Headers({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      }),
    };
    var URL;
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusinessBankDetailsV2}`;

    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditBankDetailLoading = false;
              if (this.businessId != null) {
                this.showSuccessToaster('Bank details edited successfully', 'Success');
              } else {
                this.showSuccessToaster('Bank details added successfully', 'Success');
              }
              window.history.back();
            } else {
              this.isAddEditBankDetailLoading = false;
              if (this.businessId != null) {
                this.showErrorToaster(res['message'], 'Warning');
              } else {
                this.showErrorToaster(res['message'], 'Warning');
              }
              window.history.back();
            }
          });
        }
      });
    }
    catch (error) {
      this.isAddEditBankDetailLoading = false;
      if (this.businessId) {
        this.showWarningToaster('Unable to edit Bank details', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Bank details', 'Warning');
      }
    } 
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  closeEditBtnHandler() {
    this.closeEdit.emit();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title, {
      titleClass: 'titleClass',
      messageClass: 'messageClass',
    });
  }

}
