<div style="position: relative;">
    <div class="clear-button" (click)="close(null)">
        <mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
    <h2 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;">Convenience Fee</h2>
    <hr>

    <div *ngIf="!data['view']">
        <div class="row">
            <div class="col-md-12">
                <label for="days" class="label required">Select Payment Option:</label>
                <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Payment Option'"
                    [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings" [data]="listOfPayment"
                    [(ngModel)]="selectedPayment">
                </ng-multiselect-dropdown>

            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label for="days" class="label required">Select Convenience Fee Option:</label>
                <select id="business" name="business" class="input-style"
                    (change)="selectConvenienceOption($event.target)"
                    [value]="convenienceOption==null?'Select Option':convenienceOption">
                    <option value="Select Option" disabled selected>Select Option</option>
                    <option value="Same">Same For All</option>
                    <option value="Different">According to Sports</option>
                </select>
            </div>
        </div>

        <div *ngIf="convenienceOption!=null && convenienceOption=='Same'">
            <div *ngIf="selectedPayment.length==0" style="display: flex;justify-content: center;">
                <h4 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;color: red;">Select payment option for
                    entering
                    convenience fee.
                </h4>
            </div>
            <div *ngIf="selectedPayment.length!=0">
                <h4 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;">Set Convenience Fee (as per 30 mins):
                </h4>
                <div class="row">
                    <div class="col-md-6" *ngIf="showOption('Full')">
                        <label for="fullPayment" class="label required">Full Payment ( in Rupees ):</label>
                        <input type="number" placeholder="Full Payment ( in Rupees ):" id="fullPayment"
                            [(ngModel)]="fullPayment" class="input-style" maxlength="10">
                    </div>
                    <div class="col-md-6" *ngIf="showOption('Advance')">
                        <label for="advancePayment" class="label required">Advance Payment ( in Rupees ):</label>
                        <input type="number" placeholder="Full Payment ( in Rupees ):" id="advancePayment"
                            [(ngModel)]="advancePayment" class="input-style" maxlength="10">
                    </div>
                </div>
                <div class="save" style="float: right;margin-top: 1.5rem;">
                    <input type="submit"
                        [ngClass]="{'save-btn-disabled':fullPayment==null || advancePayment==null || selectedPayment.length==0  ,'save-btn':fullPayment!=null && advancePayment!=null  && selectedPayment.length!=0    }"
                        value="{{'Save'}}"
                        [disabled]="fullPayment==null || advancePayment==null || selectedPayment.length==0 "
                        (click)="addConvenienceFee()" />
                </div>
            </div>

        </div>

        <div *ngIf="convenienceOption!=null && convenienceOption!='Same'">
            <div *ngIf="selectedPayment.length==0" style="display: flex;justify-content: center;">
                <h4 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;color: red;">Select payment option for
                    entering
                    convenience fee.
                </h4>
            </div>
            <div *ngIf="selectedPayment.length!=0">
                <h4 mat-dialog-title style="font-weight: 500;margin-bottom: 5px;">Set Convenience Fee (as per 30 mins):
                </h4>
                <div *ngFor="let sport of listOfSport">
                    <label *ngIf="data['turf']['option']=='One On' && sport['sport']=='Cricket'" class="label"
                        style="    margin-bottom: 0;font-size: 1rem;font-weight: bold;">{{sport['sport']}} for
                        Others:</label>
                    <label *ngIf="data['turf']['option']!='One On' " class=" label"
                        style="    margin-bottom: 0;font-size: 1rem;font-weight: bold;">{{sport['sport']}}:</label>
                    <div class="row">
                        <div class="col-md-6" *ngIf="showOption('Full')">
                            <label for="fullPayment" class="label required">Full Payment ( in Rupees ):</label>
                            <input type="number" placeholder="Full Payment ( in Rupees ):" id="fullPayment"
                                [(ngModel)]="sport['fullPayment']" class="input-style" maxlength="10">
                        </div>
                        <div class="col-md-6" *ngIf="showOption('Advance')">
                            <label for="advancePayment" class="label required">Advance Payment ( in Rupees ):</label>
                            <input type="number" placeholder="Full Payment ( in Rupees ):" id="advancePayment"
                                [(ngModel)]="sport['advancePayment']" class="input-style" maxlength="10">
                        </div>
                    </div>
                    <div *ngIf="data['turf']['option']=='One On' && sport['sport']=='Cricket'">
                        <label class="label"
                            style="    margin-bottom: 0;font-size: 1rem;font-weight: bold;">{{sport['sport']}} for
                            1:1:</label>
                        <div class="row">
                            <div class="col-md-6" *ngIf="showOption('Full')">
                                <label for="fullPayment1v1" class="label required">Full Payment ( in Rupees ):</label>
                                <input type="number" placeholder="Full Payment ( in Rupees ):" id="fullPayment1v1"
                                    [(ngModel)]="sport['fullPayment1v1']" class="input-style" maxlength="10">
                            </div>
                            <div class="col-md-6" *ngIf="showOption('Advance')">
                                <label for="advancePayment1v1" class="label required">Advance Payment ( in Rupees
                                    ):</label>
                                <input type="number" placeholder="Full Payment ( in Rupees ):" id="advancePayment1v1"
                                    [(ngModel)]="sport['advancePayment1v1']" class="input-style" maxlength="10">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save" style="float: right;margin-top: 1.5rem;">
                    <input type="submit"
                        [ngClass]="{'save-btn-disabled': selectedPayment.length==0  ,'save-btn': selectedPayment.length!=0    }"
                        value="{{'Save'}}" [disabled]=" selectedPayment.length==0 " (click)="addConvenienceFee()" />
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data['view']">
        View
    </div>

</div>