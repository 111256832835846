<div class="container_addressDetail" *ngIf="!isLoading">
    <div class="container_addressDetail_main">
        <div class="container_addressDetail_title">Address Details</div>
        <div>
            <form [formGroup]="turfAddressForm">
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5 required">Street Name</label>
                        <input type="text" class="input-style"  placeholder="Enter Street Name" formControlName="streetName"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5 required">Landmark</label>

                        <div style="display: flex">
                            <input type="text" class="input-style" placeholder="Enter Landmark" id="landmark" formControlName="landmark"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">City</label>

                        <div style="display: flex">
                            <input type="text" class="input-style" placeholder="Enter City" id="city" formControlName="city"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">State</label>

                        <div style="display: flex">
                            <input type="text" class="input-style" placeholder="Enter State" id="state" formControlName="state"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Pincode</label>

                        <div style="display: flex">
                            <input type="text" class="input-style" placeholder="Enter Pincode" id="pincode" formControlName="pincode" maxlength="6"/>
                        </div>
                        <div class="error" *ngIf="submitted && f['pincode'].errors">
                            <div *ngIf="f['pincode'].errors['required']">This field is required</div>
                            <div *ngIf="f['pincode'].errors['pattern']">Pincode is invalid</div>
                          </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button class="btn-1" mat-button (click)="cancelBtnHandler()">
                Previous
              </button>
              <button class="btn" mat-button (click)="isAddEditAddressLoading?null:onSubmitAddress()" >
                <div *ngIf="!isAddEditAddressLoading">
                    {{'Save and Continue'}}
                </div>
                <div *ngIf="isAddEditAddressLoading">
                  <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                  </mat-progress-spinner>
                </div>
              </button>
        </div>
    </div>
</div>