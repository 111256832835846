import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router
  ) {}
  userDetail: any;
  ngOnInit(): void {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.userDetail != null) {
      // this.as.gotoDefault(this.userDetail);
      this.as.setSideBarState(window.location.href.split('/'));
    }
  }

  logout() {
    localStorage.removeItem('BYS-ADMIN-WEB');
    this.router.navigate(['/auth/login']);
  }
}
