import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-photos',
  templateUrl: './add-photos.component.html',
  styleUrls: ['./add-photos.component.scss'],
})
export class AddPhotosComponent implements OnInit {
  @Input() isAdd: boolean = false;
  @Input() turfInfo: any;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  userDetails: any;
  turfId: any;

  isLoading: boolean = true;
  formdata = new FormData();

  myHeaders = new Headers();

  // Turf Card Image
  selectedTurfImage: any = [];
  selectedTurfImageName: any = [];
  defaultTurfImage: any = [];
  imagePreview: any = [];

  isAddEditAddressLoading: boolean = false;
  isValidTurfImageImage: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this._activatedRoute.queryParams.subscribe((params) => {
      this.turfId = params['id'];
      console.log(this.turfId);
    });
  }

  ngOnInit(): void {
    if (this.turfId) {
      this.fetchTurfByIdV2();
    } else {
      this.isLoading = false;
    }
    console.log(this.appService.selectedTurfImage)
  }

  fetchTurfByIdV2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${
        webApi.domain + webApi.endPoint.fetchTurfByIdV2 + `/${this.turfId}`
      }`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.turfInfo = data.result;
          console.log('photos turfInfo', this.turfInfo);
          this.appService.selectedTurfImage = [];

          // if(this.appService.selectedTurfImage.length >0){
          if(this.turfInfo.images != null){
            this.defaultTurfImage = this.turfInfo['images'].map(
              (data: any) => data['url']
            );
            this.imagePreview = this.turfInfo['images'].map(
              (data: any) => data['url']
            );
          }
          // }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  displayTurf(event: any, index: number) {
    console.log('Step 1', this.appService.selectedTurfImage)
    this.appService.selectedTurfImage[index] = event.target.files[0];
    this.selectedTurfImageName[index] =
      this.appService.selectedTurfImage[index]['name'];
    console.log('Step 2', )

    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.imagePreview[index] = event1.target.result;
    };
    reader.readAsDataURL(this.appService.selectedTurfImage[index]);
    let count = 0;
    this.appService.selectedTurfImage.forEach((val: any) => {
      if (val != undefined) {
        count++;
      }
    });
    if (count >= 3) {
      this.isValidTurfImageImage = true;
    }
  }

  deleteImage(index: number) {
    this.imagePreview.splice(index, 1);
    this.defaultTurfImage.splice(index, 1);
  }

  downloadImage(url: any) {
    window.open(url);
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  addTurfImages() {
    if (this.appService.selectedTurfImage.length < 3 && this.defaultTurfImage.length <3) {
      this.showWarningToaster('Images should be more than 3', 'Warning');
      return;
    }

    if (this.turfId != null) {
      this.formdata.set('turfId', this.turfId);
      this.formdata.set('images', JSON.stringify(this.defaultTurfImage));
    }

    // console.log('defaultTurfImage', this.defaultTurfImage);
    // console.log('selectedTurfImage', this.appService.selectedTurfImage);

    if (this.appService.selectedTurfImage.length > 0) {
      this.appService.selectedTurfImage.forEach(
        (image: any, index: number) => {
          this.formdata.set(
            `turfImage${index}`,
            this.appService.selectedTurfImage[index]
          );
        }
      );
    }

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    URL = `${webApi.domain + webApi.endPoint.createAndEditTurfImagesV2}`;

    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditAddressLoading = false;
              if (this.turfId != null) {
                this.showSuccessToaster(
                  'Turf Images edited successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Turf Images added successfully',
                  'Success'
                );
              }
              this.closeEdit.emit();
              this.router.navigateByUrl('/admin/venue-management');
            } else {
              this.isAddEditAddressLoading = false;
              if (this.turfId != null) {
                this.showWarningToaster(res['message'], 'Warning');
              } else {
                this.showWarningToaster(res['message'], 'Warning');
              }
            }
          });
        }
      });
    } catch (error) {
      this.isAddEditAddressLoading = false;
      if (this.turfId != null) {
        this.showWarningToaster('Unable to edit Turf Images', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Turf Images', 'Warning');
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
