import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-business',
  templateUrl: './add-edit-business.component.html',
  styleUrls: ['./add-edit-business.component.scss'],
})
export class AddEditBusinessComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper | undefined;

  selectedStepperIndex: number = 0;


  isLoading: boolean = true;
  userDetails: any;

  businessId: any;
 
  businessInfo: any;

  constructor(
    private _formBuilder: FormBuilder, private _location: Location,
    private router: Router, private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.businessId = params['id'];
      console.log('businessId', this.businessId);
    });
  }

  cancel() {
    this._location.back();
  }

  changeRoute(event: any) {
    if (event != null && event != undefined && event != '') {
      if (event['businessId'] != null && event['businessId'] != undefined && event['businessId'] != '') {
        this.router.navigate(
          [],
          {
            relativeTo: this._activatedRoute,
            queryParams: { id: event['businessId'] },
            queryParamsHandling: 'merge',
          }
        );
      }
    }
    this.myStepper?.next();
  }

  stepperBackHandler() {
    if (this.selectedStepperIndex == 0) {
      this.cancel();
    } else {
      this.myStepper?.previous();
    }
  }

  onStepperChangeHandler(event: any) {
    console.log(event);
    this.selectedStepperIndex = event.selectedIndex;
    this.cdr.detectChanges();
    // console.log(this.cdr.detectChanges())
  }

}
