import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-venue',
  templateUrl: './add-edit-venue.component.html',
  styleUrls: ['./add-edit-venue.component.scss']
})
export class AddEditVenueComponent implements OnInit {

  @ViewChild('stepper') private myStepper: MatStepper | undefined;

  selectedStepperIndex: number = 0;
  userDetails: any;
  turfInfo: any;

  turfId: any;
  isLoading: boolean = true;
  selectedBusiness: any;

  constructor(
    private _formBuilder: FormBuilder, private _location: Location,
    private router: Router, private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.turfId = params['id'];
      console.log('edit id', this.turfId)
    });
    this.fetchAllBusniess();
  }

  listOfBusiness: any = [];
  listOfSports: any = [];

  async fetchAllBusniess() {
    this.isLoading = true;
    this.listOfBusiness = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchApprovedBusiness}`;
      param = {};
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfBusiness = data['result'];
            console.log(this.listOfBusiness);
          } else {
            this.listOfBusiness = [];
          }
        });
    } catch (error) {
      this.listOfBusiness = [];
    }
  }

async fetchAllSportsCategory() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      param = {};
      await this.appService
        .fetchDetailViaGet(URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSports = data['result'];
            console.log(this.listOfSports);
          } else {
            this.listOfSports = [];
          }
        });
    } catch (error) {
      this.listOfSports = [];
    }
  }

  cancel() {
    this._location.back();
  }

  changeRoute(event: any) {
    if (event != null && event != undefined && event != '') {
      if (event['turfId'] != null && event['turfId'] != undefined && event['turfId'] != '') {
        this.router.navigate(
          [],
          {
            relativeTo: this._activatedRoute,
            queryParams: { id: event['turfId'] },
            queryParamsHandling: 'merge',
          }
        );
      }
    }
    this.myStepper?.next();
  }

  stepperBackHandler() {
    if (this.selectedStepperIndex == 0) {
      this.cancel();
    } else {
      this.myStepper?.previous();
    }
  }

  onStepperChangeHandler(event: any) {
    console.log(event);
    this.selectedStepperIndex = event.selectedIndex;
    this.cdr.detectChanges();
    // console.log(this.cdr.detectChanges())
  }

}
