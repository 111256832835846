<div class="container__employeeList">
  <div class="container__employeeList__header">
    <div class="container__employeeList__header__title">
      Venue Management List
    </div>
    <div class="searchBox">
        <div class="col-md-12">
            <div class="row align-center" style="justify-content: flex-end; display: flex;">

                <div class="col-md-3" style="text-align: right;">
                    <select class="input-field" name="region" id="region" aria-placeholder="Select Business"
                        (change)="onBusinessSelectionChange($event)">
                        <option value="Select Business" selected disabled>Select Business</option>
                        <option value="All">All</option>
                        <option *ngFor="let business of listOfBusiness" [value]="business['_id']"
                            [selected]="selectedBusiness==business['_id']">{{business?.user?.firstName || '-'}} {{business?.user?.lastName || '-'}}</option>
                    </select>
                </div>
                <div class="col-md-2" style="text-align: right;">
                    <select style="width: 100%;" class="input-field" name="region" id="region"
                        aria-placeholder="Select Turf Status" (change)="onStatusSelectionChange($event)">
                        <option value="Select Turf Status" selected disabled>Select Turf Status</option>
                        <option [selected]="selectedStatus=='All'" value="All">All</option>
                        <option [selected]="selectedStatus=='PENDING'" value="PENDING">PENDING</option>
                        <option [selected]="selectedStatus=='APPROVED'" value="APPROVED">APPROVED</option>
                        <option [selected]="selectedStatus=='REJECTED'" value="REJECTED">REJECTED</option>

                    </select>
                </div>
                <div class="col-md-4" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                        placeholder="Search by venue name, owner name, number" (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="container__employeeList__header__addBtn" (click)="navigateToAddVenue()">
                        Add New Venue
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="container__employeeList__list">
    <div class="container__employeeList__list__box">
      <div class="container__employeeList__list__box__header">
        <div class="listTitle listTitle-1">Name & Phone</div>
        <div class="listTitle listTitle-2">Address</div>
        <div class="listTitle listTitle-3">Sport</div>
        <div class="listTitle listTitle-4">Owner Name</div>
        <div class="listTitle listTitle-5">Owner Phone</div>
        <div class="listTitle listTitle-6">Enable WhatsApp</div>
        <div class="listTitle listTitle-7">Convenience Fee</div>
        <div class="listTitle listTitle-8">Copy Link</div>
        <div class="listTitle listTitle-9">Status</div>
        <div class="listTitle listTitle-10">Active</div>
        <div class="listTitle listTitle-11">Action</div>
      </div>
      <div
        class="container__employeeList__list__box__content"
        *ngIf="!isLoading"
      >
        <div
          class="noData"
          style="height: 100%"
          *ngIf="listOfTurfToDisplay.length == 0"
        >
          No Data Available To Show !
        </div>
        <ng-container *ngIf="listOfTurfToDisplay.length != 0">
          <div
            class="container__employeeList__list__box__listItem"
            *ngFor="
              let turf of listOfTurfToDisplay
                | paginate : { itemsPerPage: count, currentPage: p }
            "
          >
            <div class="listItem listItem-1">
              {{ turf.name }} ({{ turf.phone }})
            </div>
            <div class="listItem listItem-2">{{ turf.fullAddress }}</div>
            <div class="listItem listItem-3">{{ turf.sportsName }}</div>
            <div class="listItem listItem-4">{{ turf.ownerName }}</div>
            <div class="listItem listItem-5">{{ turf.ownerPhone }}</div>
            <div class="listTitle listItem-6">
              <img
                *ngIf="turf.enableWhatsApp"
                class="table-data-content__status-icon table-data-content__status-icon-on"
                src="../../../assets/svgIcons/switch-on.svg"
                style="width: 2rem"
                (click)="enableWhatsApp(turf['_id'], false)"
                slot="start"
              />
              <img
                *ngIf="!turf.enableWhatsApp"
                class="table-data-content__status-icon table-data-content__status-icon-off"
                src="../../../assets/svgIcons/switch-off.svg"
                style="width: 2rem"
                (click)="enableWhatsApp(turf['_id'], true)"
                slot="start"
              />
            </div>

            <div
              class="listItem listItem-7"
              *ngIf="turf['status'].toLowerCase() == 'approved'"
            >
              <img
                title="Edit"
                (click)="viewConvenience(turf, false)"
                style="height: 1.1rem; margin-right: 0.5em; cursor: pointer"
                src="../../../assets/img/editing.png"
              />
              <div
                *ngIf="
                  turf['convenience'] == null ||
                  turf['convenience'] == undefined
                "
              >
                (PENDING)
              </div>
            </div>
            <div
              class="listItem listItem-7"
              *ngIf="turf['status'].toLowerCase() != 'approved'"
            >
              N.A.
            </div>
            <div
              class="listItem listItem-8"
              (click)="createLink(turf)"
              style="text-decoration: underline; color: blue; cursor: pointer"
            >
              Copy Link
            </div>
            <div class="listItem listItem-9">{{ turf.status }}</div>
            <div class="listItem listItem-10">
              <img
                *ngIf="turf.isActive"
                class="table-data-content__status-icon table-data-content__status-icon-on"
                src="../../../assets/svgIcons/switch-on.svg"
                style="width: 2rem"
                (click)="suspendTurf(turf, false)"
                slot="start"
              />
              <img
                *ngIf="!turf.isActive"
                class="table-data-content__status-icon table-data-content__status-icon-off"
                src="../../../assets/svgIcons/switch-off.svg"
                style="width: 2rem"
                (click)="suspendTurf(turf, true)"
                slot="start"
              />
            </div>
            <div class="listItem listItem-11">
              <img
                title="Edit"
                (click)="editTurf(turf)"
                style="height: 1.1rem; margin-right: 0.5em; cursor: pointer"
                src="../../../assets/img/editing.png"
              />
              <img
                title="View"
                (click)="viewTurfDetails(turf)"
                style="height: 1.5rem; margin-right: 0.5em; cursor: pointer"
                src="../../../assets/img/view.png"
              />
              <img
                title="Delete"
                (click)="deleteTurf(turf)"
                style="height: 1.1rem; margin-right: 0.5em; cursor: pointer"
                src="../../../assets/img/delete.png"
              />
            </div>
          </div>
        </ng-container>
      </div>
      <div
        class="container__employeeList__list__box__content"
        *ngIf="listOfTurfToDisplay.length == 0"
        style="display: flex; align-items: center"
      >
        <mat-progress-spinner
          mode="indeterminate"
          diameter="30"
          style="margin: 0 auto"
        >
        </mat-progress-spinner>
      </div>
      <div class="text-right" style="font-size: medium; margin: 1em">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>

  <div
    class="text-right"
    style="font-size: medium; margin: 1em"
    *ngIf="isLoadingExtraData"
  >
    <mat-progress-spinner
      mode="indeterminate"
      diameter="20"
      style="margin: 0 auto"
    >
    </mat-progress-spinner>
  </div>
</div>
