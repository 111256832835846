import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessManagementComponent } from './business-management.component';
import { ListBusinessManagementComponent } from './list-business-management/list-business-management.component';
import { BusinessManagementRoutingModule } from './business-management-routing.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { AddEditBusinessComponent } from './add-edit-business/add-edit-business.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AddBusinessDetailComponent } from './add-business-detail/add-business-detail.component';
import { AddBankDetailComponent } from './add-bank-detail/add-bank-detail.component';

@NgModule({
  declarations: [
    BusinessManagementComponent,
    ListBusinessManagementComponent,
    AddEditBusinessComponent,
    AddBusinessDetailComponent,
    AddBankDetailComponent,
  ],
  imports: [
    CommonModule,
    BusinessManagementRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    LightboxModule,
    MatStepperModule,
  ],
})
export class BusinessManagementModule {}
