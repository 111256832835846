import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListOfferComponent } from './list-offer/list-offer.component';


const routes: Routes = [
  {
    path: '',
    component: ListOfferComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/offer' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfferManagementRoutingModule { }
