<h2 mat-dialog-title style="font-size: 18px;font-weight: 600;padding: 1em 1.4em  0em  1.4em !important;">Coupons
</h2>
<mat-dialog-content
    style="max-height: 45vh;min-height: 43vh;overflow-y: auto;max-width: inherit;margin: 0px;padding: 0em 2em  2em  2em !important;">
    <div class="container__filter" *ngIf="!isLoading">
        <div *ngIf="listOfCouponToDisplay.length<=0">
            No Coupon available
        </div>
        <div *ngIf="listOfCouponToDisplay.length>0">
            <div class="coupon-div" *ngFor="let dt of listOfCouponToDisplay"
                [ngClass]="{'coupon-div-selected':dt._id==(selectedCoupon!=null && selectedCoupon!=undefined? selectedCoupon._id:null)}"
                (click)="selectCoupon(dt)">
                <div class="coupon-code">
                    {{dt.code}}
                </div>
                <div class="coupon-benefit">
                    {{dt.benefitType=='Percentage'?(dt.discountPercentage+'% '+'discount up to '+'
                    \u20b9'+dt.benefitAmount):'Flat' +' \u20b9'+dt.benefitAmount +' Off'}}
                </div>
            </div>
        </div>


    </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding: 1em 2em  2em  2em !important;">
    <div style="display: flex; gap: 1rem">
        <button class="btn btn-1" type="button" (click)="clearCoupon()">
            Clear
        </button>
        <button class="btn"
            [ngClass]="{'btn-2-enabled':selectedCoupon!=null && selectedCoupon!=undefined,'btn-2-disabled':selectedCoupon==null || selectedCoupon==undefined }"
            type="button" (click)="selectedCoupon!=null && selectedCoupon!=undefined?applyCoupon():null">
            Apply
        </button>
    </div>

</mat-dialog-actions>