<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-offer-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit">
            </app-add-edit-offer-sidebar>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center">
                <div class="top-heading col-md-3">
                    Offers List
                </div>
                <div class="col-md-9">
                    <div class="row align-center" style="justify-content: flex-end;">
                        <div class="col-md-6" style="text-align: right;margin-right: 2em;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" [(ngModel)]="searchString" (keyup.enter)="searchOfferData()"
                                (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-2">
                            <button class="add-new-emp" (click)="addNewOffer()">
                                Add New Offer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfOfferToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No Coupon found
                </div>
            </div>

            <div *ngIf="listOfOfferToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc" data-name="code"
                            scope="col" class="table-heading-content">
                            Code</th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc"
                            data-name="description" scope="col" class="table-heading-content">Created By
                        </th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc" data-name="code"
                            scope="col" class="table-heading-content">
                            Business</th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc" data-name="code"
                            scope="col" class="table-heading-content">
                            Employee</th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc" data-name="code"
                            scope="col" class="table-heading-content">
                            Venue</th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc" data-name="code"
                            scope="col" class="table-heading-content">
                            Booking Type</th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc"
                            data-name="startDate" scope="col" class="table-heading-content">Validity
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc"
                            data-name="description" scope="col" class="table-heading-content">Description
                        </th>
                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc"
                            data-name="description" scope="col" class="table-heading-content">New User
                        </th>

                        <th style="cursor: pointer;" [appSort]="listOfOfferToDisplay" data-order="desc"
                            data-name="isForNewUser" scope="col" class="table-heading-content">Created Date
                        </th>
                        <th class="table-heading-content">Action</th>
                    </thead>
                    <div style="height: 0.6em;"> </div>
                    <tbody>
                        <tr class="table-data" *ngFor="let offer of listOfOfferToDisplay | paginate
                            : {
                                id: 'pagination',
                                itemsPerPage: count,
                                currentPage: Page
                              }; let i = index">
                            <th class="table-data-content">{{offer.code}}</th>
                            <th class="table-data-content">{{offer.createdSource==null?'N.A.':offer.createdSource}}</th>
                            <th class="table-data-content">
                                {{offer.business==null?'N.A.':offer.business['user']['firstName']+' '+
                                offer.business['user']['lastName']}}</th>
                            <th class="table-data-content">{{offer.employee==null?'N.A.':offer.employee['firstName']+'
                                '+ offer.employee['lastName']}}</th>
                            <th class="table-data-content">{{offer.turf==null?'N.A.':offer.turf.name}}</th>
                            <th class="table-data-content">{{offer.bookingType}}</th>
                            <th class="table-data-content">{{offer.startDate |date}} &nbsp;to&nbsp; {{offer.endDate
                                |date}}</th>
                            <th class="table-data-content" style="width: 20em;">{{offer.description}}</th>
                            <th class="table-data-content">{{offer.isForNewUser?'Yes':'No'}}</th>
                            <th class="table-data-content">{{offer.createdAt |date}}</th>
                            <th class="table-data-content" style="width: 10em;">
                                <!-- <img (click)="viewOfferDetails(offer)" style="height: 1.1rem;margin-right: 1em;cursor: pointer;" src='../../../assets/img/view.png'> -->
                                <div style="display: contents;">
                                    <img (click)="editOffer(offer)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img (click)="deleteOffer(offer)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div> -->
                <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium; margin: 1em">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls style="grid-column: 1/-1; font-size: 1.3rem; justify-self: end" id="pagination"
                        (pageChange)="pageChanged($event)"></pagination-controls>
                </div>

            </div>
        </div>
    </ng-sidebar-container>

</div>