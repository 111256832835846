import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListSportComponent } from './list-sport/list-sport.component';

const routes: Routes = [
  {
    path: '',
    component: ListSportComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'catalog/sport' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SportManagementRoutingModule { }
