import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ViewBookingComponent } from '../view-booking/view-booking.component';
import { ResendMsgPopupComponent } from '../resend-msg-popup/resend-msg-popup.component';
import { FormControl, FormGroup } from '@angular/forms';

export interface IBooking {
  _id: string;
  id: string;
  name: string;
  phone: string;

  bookingMode: string;
  bookingStatus: string;
  paymentStatus: string;
  totalAmount: string;
  tax: string;
  amountPaid: string;
  transactionId: string;
  bookedByType: any;
  bookedByEmployee: string;

  sizeOrSport: string;
  ogBookingDate: Date;
  bookingDate: Date;
  createdAt: Date;
  sport: Object;
  sportName: string;
  label: string;
  rentedItem: Object[];

  user: Object;
  business: Object;
  turfId: Object;
  turfName: Object;
  sportCategory: any;

  bookingSlotStartTime: string;
  bookingSlotEndTime: string;
}
@Component({
  selector: 'app-list-booking',
  templateUrl: './list-booking.component.html',
  styleUrls: ['./list-booking.component.scss'],
})
export class ListBookingComponent implements OnInit {
  selectedStatus: any = 'All';
  Page = 1;
  count = 50;
  isLoadingExtraData: boolean = false;
  limit = 250;
  skip = 0;

  dateResult: any;
  startDate: any;
  endDate: any;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  isSendMessageLoading: boolean = false;
  searchStr: string = '';
  listOfOriginalTurf: any = [];
  listOfBooking: Array<IBooking> = [];
  listOfBookingToDisplay: Array<IBooking> = [];

  listOfBookingStatus: any = [
    { name: 'COMPLETED', value: 'Completed' },
    { name: 'PENDING', value: 'Pending' },
    { name: 'CANCELLED', value: 'Cancelled' },
    { name: 'PARTIALLYPAID', value: 'Partially Paid' },
    { name: 'DONE', value: 'Done' },
    { name: 'UNPAID', value: 'Unpaid' },
    { name: 'RESCHEDULED', value: 'Rescheduled' },
  ];
  selectedStatusList: any = [];

  listOfBookingMode: any = [{ name: 'Offline' }, { name: 'Online' }];
  selectedModeList: any = [];

  listOfPaymentStatus: any = [
    { name: 'SUCCESS', value: 'Success' },
    { name: 'PENDING', value: 'Pending' },
    { name: 'OFFLINE', value: 'Offline' },
    { name: 'REFUND', value: 'Refund' },
    { name: 'FAILED', value: 'Failed' },
  ];
  selectedPaymentStatusList: any = [];

  filterObject: any;
  filterOptions: any = {
    mode: [],
    statusPayment: [],
    statusBooking: [],
    selectedSports: [],
  };

  data: any;
  statusPayment: any;
  statusBooking: any;
  mode: any;
  sport: any;
  selectedSports: any[] = [];

  listOfSport: any = [];
  listOfSportToDisplay: any = [];

  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });

  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.fetchAllBooking(0);

    this.fetchSports();

    if (this.data != null && this.data != undefined) {
      if (
        this.data.filterObject != null &&
        this.data.filterObject != undefined
      ) {
        if (
          this.data.filterObject['statusPayment'] != null &&
          this.data.filterObject['statusPayment'] != undefined
        ) {
          this.statusPayment = this.data.filterObject['statusPayment'];
        }
        if (
          this.data.filterObject['statusBooking'] != null &&
          this.data.filterObject['statusBooking'] != undefined
        ) {
          this.statusBooking = this.data.filterObject['statusBooking'];
        }
        if (
          this.data.filterObject['mode'] != null &&
          this.data.filterObject['mode'] != undefined
        ) {
          this.mode = this.data.filterObject['mode'];
        }
        if (
          this.data.filterObject['sport'] != null &&
          this.data.filterObject['sport'] != undefined
        ) {
          this.sport = this.data.filterObject['sport'];
        }
      }
    }
  }
  getTimeFormat(time: any) {
    if (time >= 12) {
      return `${parseFloat(time).toFixed(2)} pm`;
    } else {
      return `${parseFloat(time).toFixed(2)} am`;
    }
  }
  onPaymentStatusSelectionChange(event: any) {
    console.log(event.target.value);
    let tempSearchList: any = [];
    // this.listOfBookingToDisplay = [];
    this.selectedStatus = event.target.value;
    // if (this.selectedStatus == 'All') {
    //   this.listOfBookingToDisplay = this.listOfBooking;
    // } else {
    //   this.listOfBooking.forEach((booking: any) => {
    //     if (booking['paymentStatus'] == this.selectedStatus) {
    //       tempSearchList.push(booking);
    //     }
    //   });
    //   this.listOfBookingToDisplay = tempSearchList;
    // }
    this.limit = 250;
    this.skip = 0;
    this.fetchAllBooking(0);
  }
  fetchDataBasedOndate(event: any) {
    // console.log(event.value, this.dateResultForCash);
    let dateVal = new Date(this.dateResult);
    if (this.dateResult == '') {
      this.fetchAllBooking(0);
    } else {
      this.startDate = new Date(dateVal.setHours(0, 0, 0, 0));
      this.endDate = new Date(dateVal.setHours(23, 59, 59, 59));
      this.fetchParticularDayAllBooking(this.startDate, this.endDate, 0);
    }
  }
  async fetchAllBooking(businessId: any) {
    this.isLoading = true;
    this.dateResult = null;

    this.listOfBooking = [];
    let param = {
      businessId: businessId,
      limit: this.limit,
      skip: this.skip,
      paymentStatus: this.selectedStatus,
    };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchAllBooking}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalTurf = data['result'];
          this.appService.todaysBookingCount = data['todaysBookingCount'];
          data['result'].forEach((booking: any) => {
            this.listOfBooking.push({
              _id: booking['_id'],
              id: booking['_id'],
              name: booking?.user?.firstName + ' ' + booking?.user?.lastName,
              phone: booking?.user?.phone,
              bookingMode: booking['bookingMode'],
              bookingStatus: booking['bookingStatus'],
              paymentStatus: booking['paymentStatus'],
              totalAmount: booking['totalAmount'],
              tax: booking['tax'],
              label: booking['label'],
              amountPaid: booking['amountPaid'],
              transactionId: booking['transactionId'],
              sizeOrSport: booking['sizeOrSport'],
              bookingDate: booking['bookingDate'],
              createdAt: booking['createdAt'],
              ogBookingDate: booking['ogBookingDate'],
              sport: booking['sport'],
              sportName: booking['sport']['sport'],
              sportCategory: booking['sportCategory'],
              rentedItem: booking['rentedItem'],
              user: booking?.user,
              business: booking['business'],
              turfId: booking['turfId'],
              turfName: booking?.turfId?.name,
              bookingSlotStartTime: booking['bookingSlotStartTime'],
              bookingSlotEndTime: booking['bookingSlotEndTime'],
              bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
              bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking['employeeId']['firstName'] ?? ''} ${booking['employeeId']['lastName'] ?? ''}`).trim()) : `${booking["user"]["firstName"]} ${booking["user"]["lastName"]}`),
            });
          });
          this.listOfBookingToDisplay = this.listOfBooking;
          // console.log(this.listOfBookingToDisplay)
          // this.isLoading = false;
        } else {
        }
      });
  }
  async fetchParticularDayAllBooking(start: any, end: any, businessId: any) {
    this.isLoading = true;
    this.listOfBooking = [];
    let param = { businessId: businessId, startDate: start, endDate: end };
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchParticularDayAllBooking}`;
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalTurf = data['result'];
          data['result'].forEach((booking: any) => {
            this.listOfBooking.push({
              _id: booking['_id'],
              id: booking['_id'],
              name:
                booking['user']['firstName'] +
                ' ' +
                booking['user']['lastName'],
              phone: booking['user']['phone'],
              bookingMode: booking['bookingMode'].toLocaleUpperCase(),
              bookingStatus: booking['bookingStatus'],
              paymentStatus: booking['paymentStatus'],
              totalAmount: booking['totalAmount'],
              tax: booking['tax'],
              amountPaid: booking['amountPaid'],
              transactionId: booking['transactionId'],
              sizeOrSport: booking['sizeOrSport'],
              bookingDate: booking['bookingDate'],
              createdAt: booking['createdAt'],
              ogBookingDate: booking['ogBookingDate'],
              label: booking['label'],
              sport: booking['sport'],
              sportName: booking['sport']['sport'],
              rentedItem: booking['rentedItem'],
              user: booking['user'],
              sportCategory: booking['sportCategory'],
              business: booking['business'],
              turfId: booking['turfId'],
              turfName: booking['turfId']['name'],
              bookingSlotStartTime: booking['bookingSlotStartTime'],
              bookingSlotEndTime: booking['bookingSlotEndTime'],
              bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
              bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking['employeeId']['firstName'] ?? ''} ${booking['employeeId']['lastName'] ?? ''}`).trim()) : `${booking["user"]["firstName"]} ${booking["user"]["lastName"]}`),
            });
          });
          this.listOfBookingToDisplay = this.listOfBooking;
          this.isLoading = false;
        } else {
        }
      });
  }

  async fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        businessId: 0,
        limit: this.limit,
        skip: this.skip,
        paymentStatus: this.selectedStatus,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllBooking}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.appService.todaysBookingCount = data['todaysBookingCount'];
            data['result'].forEach((booking: any) => {
              this.listOfOriginalTurf.push(booking);
              this.listOfBooking.push({
                _id: booking['_id'],
                id: booking['_id'],
                name:
                  booking['user']['firstName'] +
                  ' ' +
                  booking['user']['lastName'],
                phone: booking['user']['phone'],
                bookingMode: booking['bookingMode'],
                bookingStatus: booking['bookingStatus'],
                paymentStatus: booking['paymentStatus'],
                totalAmount: booking['totalAmount'],
                tax: booking['tax'],
                label: booking['label'],
                amountPaid: booking['amountPaid'],
                transactionId: booking['transactionId'],
                sizeOrSport: booking['sizeOrSport'],
                bookingDate: booking['bookingDate'],
                createdAt: booking['createdAt'],
                ogBookingDate: booking['ogBookingDate'],
                sport: booking['sport'],
                sportName: booking['sport']['sport'],
                sportCategory: booking['sportCategory'],
                rentedItem: booking['rentedItem'],
                user: booking['user'],
                business: booking['business'],
                turfId: booking['turfId'],
                turfName: booking['turfId']['name'],
                bookingSlotStartTime: booking['bookingSlotStartTime'],
                bookingSlotEndTime: booking['bookingSlotEndTime'],
                bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
                bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                  : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking['employeeId']['firstName'] ?? ''} ${booking['employeeId']['lastName'] ?? ''}`).trim()) : `${booking["user"]["firstName"]} ${booking["user"]["lastName"]}`),
              });
            });
            this.listOfBookingToDisplay = this.listOfBooking;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (this.Page * this.count >= this.listOfBooking.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) {
        if (this.searchStr == '') {
          this.fetchExtraListData(false);
        } else {
          this.fetchExtraSearchListData(false);
        }
      }
    } else if (this.Page * this.count >= this.listOfBooking.length) {
      this.skip = this.skip + this.limit;
      if (this.searchStr == '') {
        this.fetchExtraListData(true);
      } else {
        this.fetchExtraSearchListData(true);
      }
    }
  }

  searchData(target: any) {
    // console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfBookingToDisplay = [];

      this.listOfBooking.forEach((booking: any, index: number) => {
        if (
          String(booking['turfName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['phone'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['fullAddress'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['ownerName'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['ownerPhone'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['sportsName'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          // String(booking['status'])
          //   .toLowerCase()
          //   .includes(String(serachQuery).toLowerCase()) ||
          String(booking['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(booking);
        }
      });
      this.listOfBookingToDisplay = [];
      this.listOfBookingToDisplay = tempSearchList;
    } else {
      this.listOfBookingToDisplay = this.listOfBooking;
    }
  }

  async searchBookingData() {
    this.isLoading = true;
    if (this.searchStr == '') {
      this.limit = 250;
      this.skip = 0;
      this.fetchAllBooking(0);
    } else {
      this.limit = 250;
      this.skip = 0;
      try {
        this.listOfBooking = [];
        let param = {
          searchedString: this.searchStr,
          limit: this.limit,
          skip: this.skip,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.searchBookingFromAdmin}`;
        await this.appService
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              this.listOfOriginalTurf = data['result'];
              this.listOfOriginalTurf.forEach((booking: any) => {
                this.listOfBooking.push({
                  _id: booking['_id'],
                  id: booking['_id'],
                  name:
                    booking['user']['firstName'] +
                    ' ' +
                    booking['user']['lastName'],
                  phone: booking['user']['phone'],
                  bookingMode: booking['bookingMode'].toLocaleUpperCase(),
                  bookingStatus: booking['bookingStatus'],
                  paymentStatus: booking['paymentStatus'],
                  totalAmount: booking['totalAmount'],
                  tax: booking['tax'],
                  amountPaid: booking['amountPaid'],
                  transactionId: booking['transactionId'],
                  sizeOrSport: booking['sizeOrSport'],
                  bookingDate: booking['bookingDate'],
                  createdAt: booking['createdAt'],
                  ogBookingDate: booking['ogBookingDate'],
                  label: booking['label'],
                  sport: booking['sport'],
                  sportName: booking['sport']['sport'],
                  rentedItem: booking['rentedItem'],
                  user: booking['user'],
                  sportCategory: booking['sportCategory'],
                  business: booking['business'],
                  turfId: booking['turfId'],
                  turfName: booking['turfId']['name'],
                  bookingSlotStartTime: booking['bookingSlotStartTime'],
                  bookingSlotEndTime: booking['bookingSlotEndTime'],
                  bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
                  bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                    : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking['employeeId']['firstName'] ?? ''} ${booking['employeeId']['lastName'] ?? ''}`).trim()) : `${booking["user"]["firstName"]} ${booking["user"]["lastName"]}`),
                });
              });
              this.listOfBookingToDisplay = this.listOfBooking;
              // console.log('search', this.listOfBookingToDisplay);
            } else {
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  async fetchExtraSearchListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        searchedString: this.searchStr,
        limit: this.limit,
        skip: this.skip,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.searchBookingFromAdmin}`;
      await this.appService
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            data['result'].forEach((booking: any) => {
              this.listOfOriginalTurf.push(booking);
              this.listOfBooking.push({
                _id: booking['_id'],
                id: booking['_id'],
                name:
                  booking['user']['firstName'] +
                  ' ' +
                  booking['user']['lastName'],
                phone: booking['user']['phone'],
                bookingMode: booking['bookingMode'],
                bookingStatus: booking['bookingStatus'],
                paymentStatus: booking['paymentStatus'],
                totalAmount: booking['totalAmount'],
                tax: booking['tax'],
                label: booking['label'],
                amountPaid: booking['amountPaid'],
                transactionId: booking['transactionId'],
                sizeOrSport: booking['sizeOrSport'],
                bookingDate: booking['bookingDate'],
                createdAt: booking['createdAt'],
                ogBookingDate: booking['ogBookingDate'],
                sport: booking['sport'],
                sportName: booking['sport']['sport'],
                sportCategory: booking['sportCategory'],
                rentedItem: booking['rentedItem'],
                user: booking['user'],
                business: booking['business'],
                turfId: booking['turfId'],
                turfName: booking['turfId']['name'],
                bookingSlotStartTime: booking['bookingSlotStartTime'],
                bookingSlotEndTime: booking['bookingSlotEndTime'],
                bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
                bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                  : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking['employeeId']['firstName'] ?? ''} ${booking['employeeId']['lastName'] ?? ''}`).trim()) : `${booking["user"]["firstName"]} ${booking["user"]["lastName"]}`),
              });
            });
            this.listOfBookingToDisplay = this.listOfBooking;
            this.isLoadingExtraData = false;
          } else {
          }
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  // searchData(target: any) {
  //   let serachQuery = target.value;
  //   if (serachQuery == '') {
  //     this.limit = 250;
  //     this.skip = 0;
  //     this.fetchAllBooking(0);
  //   }
  //   // if (serachQuery != '') {
  //   //   let tempSearchList: any = [];
  //   //   this.listOfBookingToDisplay = [];

  //   //   this.listOfBooking.forEach((booking: any, index: number) => {
  //   //     if (
  //   //       String(booking['name'])
  //   //         .toLowerCase()
  //   //         .includes(String(serachQuery).toLowerCase()) ||
  //   //       String(booking['phone'])
  //   //         .toLowerCase()
  //   //         .includes(String(serachQuery).toLowerCase()) ||
  //   //       String(booking['sportName'])
  //   //         .toLowerCase()
  //   //         .includes(String(serachQuery).toLowerCase()) ||
  //   //       String(booking['turfName'])
  //   //         .toLowerCase()
  //   //         .includes(String(serachQuery).toLowerCase())
  //   //     ) {
  //   //       tempSearchList.push(booking);
  //   //     }
  //   //   });
  //   //   this.listOfBookingToDisplay = [];
  //   //   this.listOfBookingToDisplay = tempSearchList;
  //   // } else {
  //   //   this.listOfBookingToDisplay = this.listOfBooking;
  //   // }
  // }

  // actionAcceptDelete(booking: any, action: any) {}
  viewBookingDetail(booking: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      booking: booking,
    };
    dialogConfig.width = '40rem';
    dialogConfig.height = 'fit-content';
    let dialogRef = this.matDialog.open(ViewBookingComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Accept Turf') {
          // const param = {
          //   turfId: res['value']['_id'],
          //   status: 'APPROVED',
          //   remark: 'Approved by admin successfully',
          // };
          // const httpHeaders = new HttpHeaders({
          //   Authorization: `Bearer ${this.userDetails['accessToken']}`,
          // });
          // const URL = `${
          //   webApi.domain + webApi.endPoint.verifyAndUnVerifyTurf
          // }`;
          // await this.appService
          //   .updateDataViaPut(param, URL, httpHeaders)
          //   .then((data: any) => {
          //     if (data['success']) {
          //       this.listOfTurf.forEach((turf: any, index: number) => {
          //         if (turf['_id'] == res['value']['_id']) {
          //           this.listOfTurf[index]['status'] = data['result']['status'];
          //           this.listOfTurf[index]['remark'] = data['result']['remark'];
          //         }
          //       });
          //       this.listOfTurfToDisplay = this.listOfTurf;
          //       this.showSuccessToaster(
          //         'Turf Accepted Successfully',
          //         'Success'
          //       );
          //     } else {
          //       this.showErrorToaster(
          //         'Error Occurred while accepting Turf. Try again',
          //         'Error'
          //       );
          //     }
          //   });
        }
      }
    });
  }

  popUpResendMessage(type: string, booking: any) {
    const dialog = new MatDialogConfig();
    dialog.data = {
      booking: booking,
      type: type,
    };
    dialog.panelClass = 'custom-dialog-container';
    dialog.width = ' 44rem';
    dialog.height = '13rem';
    let dialogRef = this.matDialog.open(ResendMsgPopupComponent, dialog);
    dialogRef.updatePosition(dialog.position);
    dialogRef.afterClosed().subscribe((result: undefined) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(
      async (res: null) => {
        if (res == null) {
          return;
        }
      }
    );
    dialogRef.componentInstance.type = type;
  }

  onCheckboxChange(event: any, option: string) {
    if (event.target.checked) {
      this.filterOptions[option].push(event.target.value);
    } else {
      const index = this.filterOptions[option].indexOf(event.target.value);
      if (index !== -1) {
        this.filterOptions[option].splice(index, 1);
      }
    }
  }

  onCheckboxSportChange(event: any, sport: any) {
    const selectedSportId = event.target.value;
    if (event.target.checked) {
      this.filterOptions.selectedSports.push(selectedSportId);
    } else {
      const index = this.filterOptions.selectedSports.indexOf(selectedSportId);
      if (index !== -1) {
        this.filterOptions.selectedSports.splice(index, 1);
      }
    }
  }

  fetchSports() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSport = data['result'];
          this.listOfSportToDisplay = this.listOfSport;
          console.log('listOfSportToDisplay', this.listOfSportToDisplay);
          console.log('listOfSport', this.listOfSport);
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  fetchSearchBookings() {
    this.isLoading = true;
    this.listOfBooking = [];
    this.listOfBookingToDisplay = [];
    try {
      let URL = '';
      let param = {
        filterObject: this.filterOptions,
      };
      URL = `${webApi.domain + webApi.endPoint.filterBooking}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService
        .postMethodWithToken(param, URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfOriginalTurf = data['result'];
            data['result'].forEach((booking: any) => {
              this.listOfBooking.push({
                _id: booking['_id'],
                id: booking['_id'],
                name: booking.user?.firstName + ' ' + booking.user?.lastName,
                phone: booking.user?.phone,
                bookingMode: booking['bookingMode'],
                bookingStatus: booking['bookingStatus'],
                paymentStatus: booking['paymentStatus'],
                totalAmount: booking['totalAmount'],
                tax: booking['tax'],
                label: booking['label'],
                amountPaid: booking['amountPaid'],
                transactionId: booking['transactionId'],
                sizeOrSport: booking['sizeOrSport'],
                bookingDate: booking['bookingDate'],
                createdAt: booking['createdAt'],
                ogBookingDate: booking['ogBookingDate'],
                sport: booking['sport'],
                sportName: booking['sport']['sport'],
                sportCategory: booking['sportCategory'],
                rentedItem: booking['rentedItem'],
                user: booking.user,
                business: booking['business'],
                turfId: booking['turfId'],
                turfName: booking['turfId']['name'],
                bookingSlotStartTime: booking['bookingSlotStartTime'],
                bookingSlotEndTime: booking['bookingSlotEndTime'],
                bookedByType: booking["bookedBy"] == null || booking['bookedBy'] == undefined || booking['bookedBy'] == '' ? 'N.A.' : booking['bookedBy'],
                bookedByEmployee: booking["bookedBy"] == 'CRM' ? (booking["bysEmpId"] == null || booking['bysEmpId'] == undefined ? 'NA' : booking['bysEmpId']['fullName'])
                  : (booking["bookedBy"] == 'Business' || booking["bookedBy"] == 'Employee' ? (booking['employeeId'] == null || booking['employeeId'] == undefined || booking['employeeId'] == '' ? 'NA' : (`${booking?.employeeId?.firstName ?? ''} ${booking?.employeeId?.lastName ?? ''}`).trim()) : `${booking?.user?.firstName} ${booking?.user?.lastName}`),
              });  
            });
            this.listOfBookingToDisplay = this.listOfBooking;
          }
          // this.isLoading = false;
        });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  applyFilter() {
    console.log('Appli filter');
    this.isLoading = true;
    this.fetchSearchBookings();
  }

  clearFilter() {
    this.filterOptions = {
      statusBooking: [],
      mode: [],
      statusPayment: [],
      selectedSports: [],
    };

    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    this.fetchSearchBookings();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
