<div class="container__campaignList">
  <div class="container__campaignList__title">
    <div class="container__campaignList__title__text1">
      {{ businessId!=null ? 'Edit' : 'Add'}} Business
    </div>
  </div>

  <div class="container__campaignList__tabs">
    <div class="container__campaignList__tabs__box">
      <mat-horizontal-stepper (selectionChange)="onStepperChangeHandler($event)" #stepper>
        <mat-step label="Business Details">
          <div class="dashedLine"></div>
          <app-add-business-detail [isAdd]="true" (closeEdit)="changeRoute($event)"
            (backToPreviousStepper)="stepperBackHandler()"></app-add-business-detail>
        </mat-step>
        <mat-step label="Bank Details">
          <div class="dashedLine"></div>
          <app-add-bank-detail [isAdd]="true" (closeEdit)="changeRoute($event)" [businessInfo]="businessInfo"
            (backToPreviousStepper)="stepperBackHandler()"></app-add-bank-detail>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>

  <div style="grid-column: 1/-1">
    <router-outlet></router-outlet>
  </div>
</div>