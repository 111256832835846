<div class="page-content-1">
  <ng-sidebar-container style="height: 100%;">
    <ng-sidebar mode="over" [keyClose]="false" position="right" [dock]="false" [dockedSize]="'50px'"
      [autoCollapseHeight]="500" [autoCollapseWidth]="500" [closeOnClickOutside]="false" [closeOnClickBackdrop]="false"
      [showBackdrop]="true" [animate]="true" [trapFocus]="false" [autoFocus]="true" [ariaLabel]="'Menu'">
      <!-- <app-filter-report (closeSideForm)="_toggleOpened()"></app-filter-report> -->
    </ng-sidebar>
    <div ng-sidebar-content class="sidebar-content">
      <!-- Search Div -->
      <div class="row mb-3 align-items-center justify-content-end" style="width: 100%;">
        <div class="col-md-10">
          <div class="search-box">
            <input class="search-input" type="text" name="searchQuery" id="searchQuery"
              (input)="serachCampaign($event.target)" placeholder="Search by name & status...">
            <div class="search-button display-flex align-items-center">
              <img src="../../../../assets/img/ic_Search.svg" class="mr-2" style="height: 20px;" alt=""><span
                style="font-size: 15px;">Search</span>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center">
          <div class="add-new-campaign" (click)="gotoAddCampaign()" style="width:9rem">
            Add Campaign
          </div>
        </div>
      </div>

      <!-- Table -->
      <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
      </div>
      <div *ngIf="!isLoading && listOfCampaignToDisplay.length<=0"
        class="display-flex align-items-center justify-content-center" style="height: 60vh;">
        No Campaign available
      </div>
      <div *ngIf="!isLoading && listOfCampaignToDisplay.length>0">
        <table cellspacing="0" cellpadding="0" style="width: 100%;padding-right: 10px;">
          <tr class="table-heading tr-style">
            <th class="th-weight" data-order="desc" data-name="name" scope="col">
              Name</th>
            <th class="th-weight" data-order="desc" data-name="campaignDateTime" scope="col">
              Campaign Date</th>
            <th class="th-weight" data-order="desc" data-name="campaignDateTime" scope="col">
              Campaign Time</th>
            <th class="th-weight" data-order="desc" data-name="campaignMedium" scope="col">
              Campaign Medium</th>
            <th class="th-weight" data-order="desc" data-name="type" scope="col">
              Campaign Type</th>
            <th class="th-weight" data-order="desc" data-name="status" scope="col">
              Status</th>
            <th class="th-weight " data-order="desc" data-name="createdAt" scope="col">
              ACTION</th>
          </tr>
          <tbody *ngIf=" listOfCampaignToDisplay.length>0">
            <tr class="table-data"
              *ngFor="let campaign of listOfCampaignToDisplay | paginate: { itemsPerPage: count, currentPage: Page }">
              <th class="table-data-content">{{campaign['name']}}
              </th>

              <th class="table-data-content">{{campaign['campaignDateTime']==null||
                campaign['campaignDateTime']==undefined?'-': getDate(campaign['campaignDateTime']) |
                date :
                'dd/MM/yyyy' }}
              </th>
              <th class="table-data-content">{{campaign['campaignDateTime']==null||
                campaign['campaignDateTime']==undefined?(getOnlyTime(campaign['campaignSchedule']['time'])
                | date :'hh:mm a'): getDate(campaign['campaignDateTime']) |
                date :'hh:mm a' }}
              </th>
              <th class="table-data-content">{{campaign['campaignMedium']}}
              </th>
              <th class="table-data-content">{{campaign['type']}}
              </th>
              <th class="table-data-content"
                (click)="campaign['status']=='Completed'? null:changeCampaignStatus(campaign)">
                <span
                  [ngStyle]="{'cursor':campaign['status']=='Completed'?'not-allowed':'pointer','background-color': campaign['status']=='Active'?'#d0f9d0':'#d2d2d2' }"
                  style="border: solid 1px grey;padding: 5px;">
                  {{campaign['status']}}
                </span>
              </th>

              <th class="table-data-content">
                <!-- [ngStyle]="{cursor:campaign['status']=='Completed'?'not-allowed':'pointer' }" -->
                <img *ngIf="campaign['status']=='Active' || campaign['status']=='Pause'"
                  (click)="editCampaign(campaign)" style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                  src='../../../assets/img/editing.png'>
                <img (click)="viewCampaign(campaign)" style="height: 1.2rem;margin-right: 1em;cursor: pointer;"
                  src='../../../assets/img/view.png'>
                <!-- <span *ngIf="campaign['status']=='Completed'"
                                  style="font-size: 11px;color: #ea1313;">Can't
                                  edit</span> -->
              </th>

            </tr>
          </tbody>
        </table>
        <div *ngIf=" listOfCampaignToDisplay.length>0" class="text-right" style="font-size: medium;margin: 1em;">
          <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
      </div>

      <div style="height: 10em;">

      </div>
    </div>
  </ng-sidebar-container>
</div>