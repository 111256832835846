<div class="main-popup-div">
    <div class="popup-heding">
        <div>
            <h1 class="heading-text">
                {{dataToEdit!=null?'Edit': 'Add'}} Offer
            </h1>
        </div>
        <div (click)="cancel(null)">
            <mat-icon class="clear-icon" style="font-size: medium;">clear</mat-icon>
        </div>
    </div>
</div>
<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="popup-body">
    <form name="addEditOfferForm" [formGroup]="addEditOfferForm">
        <div class="row">
            <div class="col-md-6">
                <label for="createdSource" class="label required">Offer Source:</label>
                <select name="type" id="createdSource" (change)="selectSource($event.target)"
                    formControlName="createdSource" class="input-style">
                    <option value="Select Offer Source" selected disabled>Select Offer Source</option>
                    <option value="CRM">CRM</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="EMPLOYEE">EMPLOYEE</option>
                    <option value="BUSINESS">BUSINESS</option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="offerCode" class="label required">Offer Code</label>
                <input type="text" placeholder="Offer Code" id="offerCode" formControlName="offerCode"
                    class="input-style">
            </div>
            <div class="col-md-6" *ngIf="this.addEditOfferForm.get('createdSource')?.value=='CRM'">
                <label for="benefitType" class="label required">Benefit Type:</label>
                <select name="type" id="benefitType" formControlName="benefitType" class="input-style"
                    (change)="selectBenefitType($event.target)">
                    <option value="Select Benefit Type" selected disabled>Select Benefit Type</option>
                    <option value="Amount">Amount</option>
                    <option value="Percentage">Percentage</option>
                </select>
            </div>

            <div class="col-md-6"
                *ngIf=" this.addEditOfferForm.get('createdSource')?.value=='CRM' && this.addEditOfferForm.get('benefitType')?.value=='Percentage'">
                <label for="discountPercentage" class="label required">Discount Percentage</label>
                <input type="number" placeholder="Enter Percentage" id="discountPercentage"
                    formControlName="discountPercentage" class="input-style">
            </div>
            <div class="col-md-6" *ngIf=" this.addEditOfferForm.get('createdSource')?.value!='CRM' ">
                <label for="discountPercentage" class="label required">Discount Percentage</label>
                <input type="number" placeholder="Enter Percentage" id="discountPercentage"
                    formControlName="discountPercentage" class="input-style">
            </div>
            <div class="col-md-6"
                *ngIf="this.addEditOfferForm.get('createdSource')?.value=='CRM' && this.addEditOfferForm.get('benefitType')?.value=='Amount'">
                <label for="benefitAmount" class="label required">Benefit Amount</label>
                <input type="number" placeholder="Enter amount" id="benefitAmount" formControlName="benefitAmount"
                    class="input-style">
            </div>
            <div class="col-md-6"
                *ngIf="this.addEditOfferForm.get('createdSource')?.value=='CRM' && this.addEditOfferForm.get('benefitType')?.value=='Percentage'">
                <label for="benefitAmount" class="label required">Upto discount amount</label>
                <input type="number" placeholder="Enter amount" id="benefitAmount" formControlName="benefitAmount"
                    class="input-style">
            </div>

            <div class="col-md-6"
                *ngIf="this.addEditOfferForm.get('createdSource')?.value=='ADMIN' || this.addEditOfferForm.get('createdSource')?.value=='EMPLOYEE' || this.addEditOfferForm.get('createdSource')?.value=='BUSINESS'">
                <label for="sport" class="label required">Venue:</label>
                <select name="type" id="cars" formControlName="turf" class="input-style">
                    <option value="Select Venue" selected disabled>Select Venue</option>
                    <option *ngFor="let turf of listOfVenue" [value]="turf['_id']" class="levels">
                        {{turf['name']}}</option>
                </select>
            </div>
            <div class="col-md-6"
                *ngIf="this.addEditOfferForm.get('createdSource')?.value=='ADMIN' || this.addEditOfferForm.get('createdSource')?.value=='EMPLOYEE' || this.addEditOfferForm.get('createdSource')?.value=='BUSINESS'">
                <label for="bookingType" class="label required">Select Booking Type:</label>
                <select name="type" id="bookingType" (change)="selectType($event.target)" formControlName="bookingType"
                    class="input-style">
                    <option value="Select Booking Type" selected disabled>Select Booking Type</option>
                    <option value="Single Booking">Single Booking</option>
                    <option value="Bulk Booking">Bulk Booking</option>
                </select>
            </div>

            <div class="col-md-12" style="margin-bottom: 1rem;">
                <label for="description" class="label required">Description</label>
                <textarea type="text" placeholder="Description" id="description" style="width: 100%;
            padding: 0.5em;" formControlName="description" rows="5" class="textarea-style"></textarea>
            </div>

            <div class="col-md-6">
                <label for="startDate" class="label required">Start Date</label>
                <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate"
                    [max]="maxStartDate" (input)="startDateChange($event)" class="input-style">
            </div>
            <div class="col-md-6">
                <label for="endDate" class="label required">End Date</label>
                <input type="date" placeholder="End Date" id="endDate" formControlName="endDate" [min]="minEndDate"
                    (input)="endDateChange($event)" class="input-style">
            </div>
        </div>
        <div class="col-md-12" style="display: flex;align-items: center;">
            <label for="isForNewUser" class="label " style="margin-bottom: 0px;margin-right: 1em;">Is For New
                User?</label>
            <input type="checkbox" placeholder="Is For New User?" id="isForNewUser" [checked]="isForNewUser"
                (change)="isForNewUserChange($event)">
        </div>
        <div class="save">
            <input type="submit"
                [ngClass]="{'save-btn-disabled':addEditOfferForm.invalid  || (this.addEditOfferForm.get('createdSource')?.value!='CRM' && selectedBooking=='') ,'save-btn':addEditOfferForm.valid && (this.addEditOfferForm.get('createdSource')?.value=='CRM' || selectedBooking!='') }"
                value="{{'Save'}}"
                [disabled]="addEditOfferForm.invalid || (this.addEditOfferForm.get('createdSource')?.value!='CRM' && selectedBooking=='') "
                (click)="addEditOffer()" />
        </div>

    </form>
</div>