<!-- <div class="back-1" *ngIf="back">
    <div style="height: 3rem;width: 3rem; margin-left: 3rem; margin-top: 1rem; cursor: pointer;" class="back"
        (click)="backToHomePage()">
        <img src="../../assets/svgIcons/back.svg" alt="Logo">
    </div>
</div> -->
<div class="main-page">
    <div class="page">
        <h4 style="margin-left: 3rem;">OOPS! PAGE NOT FOUND</h4>
        <h1 style="font-size: 18rem;font-weight: bold;margin-top: -2rem;margin-bottom: -2rem;">404</h1>
        <h4>WE ARE SORRY, BUT THE PAGE YOU REQUESTED<br> WAS NOT FOUND</h4>
    </div>
</div>