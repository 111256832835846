import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-slots',
  templateUrl: './add-slots.component.html',
  styleUrls: ['./add-slots.component.scss'],
})
export class AddSlotsComponent implements OnInit {
  @Input() turfInfo: any;
  @Input() dataToEdit: any;
  @Input() listOfSports: any;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  userDetails: any;
  isLoading: boolean = false;
  turfId: any;

  myHeaders = new Headers();
  formdata = new FormData();

  isAddEditSlotLoading: boolean = false;
  isChangingNetToNonNetting: boolean = false;

  // Days
  selectedDays: any[] = [];
  assignedDays: any[] = [];

  //Sport Category
  sportCategory: any = [];
  selectedSportCategory: any = {};

  // Sport Type
  selectedSport: any = [];
  assignedSport: any = [];

  // Facilities Type
  selectedFacilities: any = [];
  assignedFacilities: any = [];

  selectedTurfSize: any = [];

  listofSlotForMorning: any = [];
  listofSlotForAfternoon: any = [];
  listofSlotForEvening: any = [];
  listofSlotForNight: any = [];
  gameQuantity: any = [];

  isMorningSlotSelected: boolean = false;
  isAfternoonSlotSelected: boolean = false;
  isEveningSlotSelected: boolean = false;
  isNightSlotSelected: boolean = false;
  isValidTurfSlot: boolean = false;
  isValidTurfPrice: boolean = false;

  isNettingButtonSelected: boolean = true;
  isNonNettingButtonSelected: boolean = false;

  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'dayLabel',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 7,
    allowSearchFilter: true,
  };

  sportsTypeDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'sport',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  sportCategoryDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'sport',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  facilitiesDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'facilityName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  listOfFacilities: any = [
    {
      _id: 1,
      facilityName: 'Washroom',
    },
    {
      _id: 2,
      facilityName: 'Cafe & Food court',
    },
    {
      _id: 3,
      facilityName: 'Power Backup',
    },
    {
      _id: 4,
      facilityName: 'Changing Room',
    },
    {
      _id: 5,
      facilityName: 'First Aid',
    },
    {
      _id: 6,
      facilityName: 'Parking',
    },
    {
      _id: 7,
      facilityName: 'Covered',
    },
  ];

  listOfDays: any = [
    { _id: 1, val: 'Monday', dayLabel: 'Mon' },
    { _id: 2, val: 'Tueday', dayLabel: 'Tue' },
    { _id: 3, val: 'Wednesday', dayLabel: 'Wed' },
    { _id: 4, val: 'Thursday', dayLabel: 'Thu' },
    { _id: 5, val: 'Friday', dayLabel: 'Fri' },
    { _id: 6, val: 'Saturday', dayLabel: 'Sat' },
    { _id: 7, val: 'Sunday', dayLabel: 'Sun' },
  ];

  morningSlotTime: any = {
    startTime: 5.0,
    endTime: 12.0,
  };
  afternoonSlotTime: any = {
    startTime: 12.0,
    endTime: 16.0,
  };
  eveningSlotTime: any = {
    startTime: 16.0,
    endTime: 20.0,
  };
  nightSlotTime: any = {
    startTime: 20.0,
    endTime: 24.0,
  };

  listOfTurfSize: any = [
    {
      title: '1:1',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '5:5',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '6:6',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '7:7',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '8:8',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '9:9',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '11:11',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
  ];

  listOfPriceForMorningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];
  listOfPriceForAfternoonOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
  ];
  listOfPriceForEveningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNightOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];

  listOfPriceForMorning: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];

  listOfPriceForAfternoon: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
  ];

  listOfPriceForEvening: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNight: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: [],
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this._activatedRoute.queryParams.subscribe((params) => {
      this.turfId = params['id'];
      // console.log('slots', this.turfId);
    });
  }

  ngOnInit(): void {
    this.fetchSport();
    if (this.turfId) {
      this.fetchTurfByIdV2();
    }
  }

  fetchTurfByIdV2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${
        webApi.domain + webApi.endPoint.fetchTurfByIdV2 + `/${this.turfId}`
      }`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.turfInfo = data.result;
          // console.log('slots turfInfo', this.turfInfo);
          if (this.turfInfo.isNet == false) {
            this.isNonNettingButtonSelected = true;
            this.isNettingButtonSelected = false;
            // this.radioChangeHandler('Non Netting');
          } else {
            this.isNettingButtonSelected = true;
            this.isNonNettingButtonSelected = false;
            // this.radioChangeHandler('Netting');
          }
          if (this.turfInfo.days) {
            for (let index = 0; index < this.turfInfo.days.length; index++) {
              let element = this.turfInfo.days[index];
              // console.log(element)
              if (element == 'Mon') {
                this.selectedDays.push({ _id: 1, dayLabel: element });
              } else if (element == 'Tue') {
                this.selectedDays.push({ _id: 2, dayLabel: element });
              } else if (element == 'Wed') {
                this.selectedDays.push({ _id: 3, dayLabel: element });
              } else if (element == 'Thu') {
                this.selectedDays.push({ _id: 4, dayLabel: element });
              } else if (element == 'Fri') {
                this.selectedDays.push({ _id: 5, dayLabel: element });
              } else if (element == 'Sat') {
                this.selectedDays.push({ _id: 6, dayLabel: element });
              } else if (element == 'Sun') {
                this.selectedDays.push({ _id: 7, dayLabel: element });
              }
            }
            this.appService.selectedDays = this.selectedDays;
            // console.log(this.appService.selectedDays)
          }
          if (this.turfInfo.facilities) {
            for (
              let index = 0;
              index < this.turfInfo.facilities.length;
              index++
            ) {
              let element = this.turfInfo.facilities[index];
              // console.log(element);
              if (element == 'Washroom') {
                this.selectedFacilities.push({ _id: 1, facilityName: element });
              } else if (element == 'Cafe & Food court') {
                this.selectedFacilities.push({ _id: 2, facilityName: element });
              } else if (element == 'Power Backup') {
                this.selectedFacilities.push({ _id: 3, facilityName: element });
              } else if (element == 'Changing Room') {
                this.selectedFacilities.push({ _id: 4, facilityName: element });
              } else if (element == 'First Aid') {
                this.selectedFacilities.push({ _id: 5, facilityName: element });
              } else if (element == 'Parking') {
                this.selectedFacilities.push({ _id: 6, facilityName: element });
              } else if (element == 'Covered') {
                this.selectedFacilities.push({ _id: 7, facilityName: element });
              }
            }
            this.appService.selectedFacilities = this.selectedFacilities;
          }
          this.appService.selectedSportCategory.categoryId =
            this.turfInfo.sportCategory?._id;
          this.appService.selectedSportCategory.categoryName =
            this.turfInfo.sportCategory?.categoryName;
          // console.log('selectedSportCategory', this.appService.selectedSportCategory.categoryId, this.appService.selectedSportCategory.categoryName);
          this.fetchSport();
          this.selectedSport = this.turfInfo.sportsType;
          if (this.turfInfo.slots) {
            for (let index = 0; index < this.turfInfo.slots.length; index++) {
              const element = this.turfInfo.slots[index];
              if (element.session == 'Morning') {
                this.isMorningSlotSelected = true;
                this.listofSlotForMorning = element.priceAndQuantity;
                if (
                  this.appService.selectedSportCategory.categoryName ==
                    'Esport' ||
                  this.appService.selectedSportCategory.categoryName == 'Indoor'
                ) {
                  element.priceAndQuantity[0]['isSelected'] = true;
                  element.priceAndQuantity[0]['advanceAmount'] =
                    this.turfInfo?.advanceAmount;
                  this.listOfPriceForMorning = element.priceAndQuantity;
                } else {
                  this.listOfPriceForMorning.forEach((info: any) => {
                    element.priceAndQuantity.forEach((slot: any) => {
                      if (info.title == slot.title) {
                        info['price'] = slot['price'];
                        info['weekendPrice'] = slot['weekendPrice'];
                        info['quantity'] = slot['quantity'];
                        info['label'] = slot['label'];
                        info['isSelected'] = true;
                      }
                    });
                  });
                }
              } else if (element.session == 'Afternoon') {
                this.isAfternoonSlotSelected = true;
                this.listofSlotForAfternoon = element.priceAndQuantity;
                if (
                  this.appService.selectedSportCategory.categoryName ==
                    'Esport' ||
                  this.appService.selectedSportCategory.categoryName == 'Indoor'
                ) {
                  element.priceAndQuantity[0]['isSelected'] = true;
                  this.listOfPriceForAfternoon = element.priceAndQuantity;
                } else {
                  this.listOfPriceForAfternoon.forEach((info: any) => {
                    element.priceAndQuantity.forEach((slot: any) => {
                      if (info.title == slot.title) {
                        info['price'] = slot['price'];
                        info['weekendPrice'] = slot['weekendPrice'];
                        info['quantity'] = slot['quantity'];
                        info['label'] = slot['label'];
                        info['isSelected'] = true;
                      }
                    });
                  });
                }
              } else if (element.session == 'Evening') {
                this.isEveningSlotSelected = true;
                this.listofSlotForEvening = element.priceAndQuantity;
                if (
                  this.appService.selectedSportCategory.categoryName ==
                    'Esport' ||
                  this.appService.selectedSportCategory.categoryName == 'Indoor'
                ) {
                  element.priceAndQuantity[0]['isSelected'] = true;
                  this.listOfPriceForEvening = element.priceAndQuantity;
                } else {
                  this.listOfPriceForEvening.forEach((info: any) => {
                    element.priceAndQuantity.forEach((slot: any) => {
                      if (info.title == slot.title) {
                        info['price'] = slot['price'];
                        info['weekendPrice'] = slot['weekendPrice'];
                        info['quantity'] = slot['quantity'];
                        info['label'] = slot['label'];
                        info['isSelected'] = true;
                      }
                    });
                  });
                }
              } else if (element.session == 'Night') {
                this.isNightSlotSelected = true;
                this.listofSlotForNight = element.priceAndQuantity;
                if (
                  this.appService.selectedSportCategory.categoryName ==
                    'Esport' ||
                  this.appService.selectedSportCategory.categoryName == 'Indoor'
                ) {
                  element.priceAndQuantity[0]['isSelected'] = true;
                  this.listOfPriceForNight = element.priceAndQuantity;
                } else {
                  this.listOfPriceForNight.forEach((info: any) => {
                    element.priceAndQuantity.forEach((slot: any) => {
                      if (info.title == slot.title) {
                        info['price'] = slot['price'];
                        info['quantity'] = slot['quantity'];
                        info['weekendPrice'] = slot['weekendPrice'];
                        info['label'] = slot['label'];
                        info['isSelected'] = true;
                      }
                    });
                  });
                }
              }
            }

            // this.listOfTurfSize = this.turfInfo.slots.flatMap(
            //   (slot: { priceAndQuantity: any }) => slot.priceAndQuantity
            // );
            this.listOfTurfSize = this.turfInfo.slots
              .flatMap(
                (slot: { priceAndQuantity: any }) => slot.priceAndQuantity
              )
              .filter(
                (item: any, index: number, self: any[]) =>
                  index === self.findIndex((t: any) => t.title === item.title)
              );
            this.gameQuantity = this.listOfTurfSize;
            this.listOfTurfSize.forEach((item: { [x: string]: any }) => {
              item['isSelected'] = true;
            });
          }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
  }

  async fetchSport() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      param = {};
      await this.appService
        .fetchDetailViaGet(URL, httpHeaders)
        .then((data: any) => {
          if (data['success']) {
            this.listOfSports = data['result'];
            // console.log('listOfSports',this.listOfSports);
            this.listOfSports.forEach((sport: any) => {
              const index = this.sportCategory.findIndex(
                (data: any) => data['id'] == sport['sportCategory']['_id']
              );
              if (index == -1) {
                this.sportCategory.push({
                  id: sport['sportCategory']['_id'],
                  categoryName: sport['sportCategory']['categoryName'],
                  isSelected: false,
                });
              }
            });
            this.sportCategory[0]['isSelected'] = true;
            this.selectedSportCategory = {
              categoryId: this.appService.selectedSportCategory
                ? this.appService.selectedSportCategory.categoryId
                : this.sportCategory[0]['id'],
              categoryName: this.appService.selectedSportCategory
                ? this.appService.selectedSportCategory.categoryName
                : this.sportCategory[0]['categoryName'],
            };
            // console.log('sport category', this.sportCategory);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.listOfSports = [];
          }
        });
    } catch (error) {
      this.isLoading = false;
      this.listOfSports = [];
    }
  }

  getSports() {
    return this.listOfSports.filter(
      (sport: any) =>
        sport['sportCategory']['categoryName'] ===
        this.selectedSportCategory?.categoryName
    );
  }

  radioHandlerSportCategory(event: any) {
    this.selectedSport = [];
    this.assignedSport = [];
    this.isMorningSlotSelected = false;
    this.isAfternoonSlotSelected = false;
    this.isEveningSlotSelected = false;
    this.isNightSlotSelected = false;
    this.listOfTurfSize.forEach((data: any) => {
      this.gameQuantity = [];
      data['isSelected'] = false;
      data['enabled'] = false;
    });

    // console.log('event', event.target.value);

    this.sportCategory.forEach((data: any) => {
      if (data['id'] == event.target.value) {
        data['isSelected'] = true;
        this.selectedSportCategory = {
          categoryId: data['id'],
          categoryName: data['categoryName'],
        };
      } else {
        data['isSelected'] = false;
      }
    });

    // console.log('selectedSportCategory', this.selectedSportCategory);
  }

  checkForWeekend() {
    var p = 0;
    for (let index = 0; index < this.appService.selectedDays.length; index++) {
      const element = this.appService.selectedDays[index];
      if (element['dayLabel'] == 'Sat' || element['dayLabel'] == 'Sun') {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }
  checkForWeekday() {
    var p = 0;
    for (let index = 0; index < this.appService.selectedDays.length; index++) {
      const element = this.appService.selectedDays[index];
      if (
        element['dayLabel'] == 'Mon' ||
        element['dayLabel'] == 'Tue' ||
        element['dayLabel'] == 'Wed' ||
        element['dayLabel'] == 'Thu' ||
        element['dayLabel'] == 'Fri'
      ) {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }

  onItemSelect(item: any) {
    // console.log(this.appService.selectedDays);
    // console.log(this.selectedSport);
    // console.log(this.appService.selectedFacilities);
    this.checkForWeekday();
    this.checkForWeekend();
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onItemDeSelect(item: any) {
    console.log(item);
  }

  addOrRemoveSize(title: any, event: any) {
    // console.log(event.target.checked);
    const index = this.selectedTurfSize.findIndex(
      (turfSize: any) => turfSize == title
    );

    if (index == -1) {
      this.selectedTurfSize.push(title);
    } else {
      this.selectedTurfSize.splice(index, 1);
    }
    this.listOfTurfSize.forEach((slot: any) => {
      if (slot.title == title) {
        slot.isSelected = event.target.checked;
        slot.label = '';
      }
    });
    if (this.isNettingButtonSelected) {
      if (title == '8:8') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      } else if (title == '9:9' || title == '7:7') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      }
    } else {
      this.listOfPriceForMorning.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForAfternoon.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForEvening.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForNight.forEach((slot: any) => {
        slot.enabled = true;
      });
    }
    let tempMorningPrice: any = [];
    this.listOfPriceForMorningOriginal.forEach((morningSlot: any) => {
      morningSlot.isSelected = true;
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == morningSlot['title'] && element['isSelected']) {
          tempMorningPrice.push(morningSlot);
        }
      });
    });
    this.listOfPriceForMorning = tempMorningPrice;
    let tempAfternoonPrice: any = [];
    this.listOfPriceForAfternoonOriginal.forEach((afternoonSlot: any) => {
      afternoonSlot.isSelected = true;
      this.listOfTurfSize.forEach((element: any) => {
        if (
          element['title'] == afternoonSlot['title'] &&
          element['isSelected']
        ) {
          tempAfternoonPrice.push(afternoonSlot);
        }
      });
    });
    this.listOfPriceForAfternoon = tempAfternoonPrice;
    let tempEveningPrice: any = [];
    this.listOfPriceForEveningOriginal.forEach((eveningSlot: any) => {
      eveningSlot.isSelected = true;
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == eveningSlot['title'] && element['isSelected']) {
          tempEveningPrice.push(eveningSlot);
        }
      });
    });
    this.listOfPriceForEvening = tempEveningPrice;
    let tempNightPrice: any = [];
    this.listOfPriceForNightOriginal.forEach((nightSlot: any) => {
      nightSlot.isSelected = true;
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == nightSlot['title'] && element['isSelected']) {
          tempNightPrice.push(nightSlot);
        }
      });
    });
    this.listOfPriceForNight = tempNightPrice;
  }

  changeSlotSelection(slot: any, event: any) {
    let isSelected = event.target.checked;
    this.gameQuantity = [];

    this.selectedSport.forEach((data: any) => {
      this.gameQuantity.push({
        quantity: 0,
        title: data['sport'],
        isSelected: true,
        enabled: true,
        label: '',
      });
    });
    if (slot == 'Morning') {
      this.isMorningSlotSelected = isSelected;
      this.listOfPriceForMorning.forEach((element: any) => {
        element['isSelected'] = true;
      });
      const allLists = [
        this.listOfPriceForAfternoon,
        this.listOfPriceForEvening,
        this.listOfPriceForNight,
      ];
      this.listOfPriceForMorning.forEach((item: any) => {
        item.isSelected = false;
      });
      allLists.forEach((currentList) => {
        currentList.forEach((item: any) => {
          const correspondingMorningItem = this.listOfPriceForMorning.find(
            (morningItem: any) => morningItem.title === item.title
          );
          if (item.isSelected) {
            console.log('Item selected:', item.title);
            correspondingMorningItem.isSelected = true;
          }
        });
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForMorning = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForMorning.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForMorning = [];
        }
      }
    } else if (slot == 'Afternoon') {
      this.isAfternoonSlotSelected = isSelected;
      this.listOfPriceForAfternoon.forEach((element: any) => {
        element['isSelected'] = true;
      });
      const allLists = [
        this.listOfPriceForMorning,
        this.listOfPriceForEvening,
        this.listOfPriceForNight,
      ];
      this.listOfPriceForAfternoon.forEach((item: any) => {
        item.isSelected = false;
      });
      allLists.forEach((currentList) => {
        currentList.forEach((item: any) => {
          const correspondingAfternoonItem = this.listOfPriceForAfternoon.find(
            (afternoonItem: any) => afternoonItem.title === item.title
          );
          if (item.isSelected) {
            console.log('Item selected:', item.title);
            correspondingAfternoonItem.isSelected = true;
          }
        });
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForAfternoon = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForAfternoon.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForAfternoon = [];
        }
      }
    } else if (slot == 'Evening') {
      this.isEveningSlotSelected = isSelected;
      this.listOfPriceForEvening.forEach((element: any) => {
        element['isSelected'] = true;
      });
      const allLists = [
        this.listOfPriceForMorning,
        this.listOfPriceForAfternoon,
        this.listOfPriceForNight,
      ];
      this.listOfPriceForEvening.forEach((item: any) => {
        item.isSelected = false;
      });
      allLists.forEach((currentList) => {
        currentList.forEach((item: any) => {
          const correspondingEveningItem = this.listOfPriceForEvening.find(
            (eveningItem: any) => eveningItem.title === item.title
          );
          if (item.isSelected) {
            console.log('Item selected:', item.title);
            correspondingEveningItem.isSelected = true;
          }
        });
      });

      console.log('list');
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForEvening = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForEvening.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForEvening = [];
        }
      }
    } else if (slot == 'Night') {
      this.isNightSlotSelected = isSelected;
      this.listOfPriceForNight.forEach((element: any) => {
        element['isSelected'] = true;
      });
      const allLists = [
        this.listOfPriceForAfternoon,
        this.listOfPriceForEvening,
        this.listOfPriceForMorning,
      ];
      this.listOfPriceForNight.forEach((item: any) => {
        item.isSelected = false;
      });
      allLists.forEach((currentList) => {
        currentList.forEach((item: any) => {
          const correspondingNightItem = this.listOfPriceForNight.find(
            (nightItem: any) => nightItem.title === item.title
          );
          if (item.isSelected) {
            console.log('Item selected:', item.title);
            correspondingNightItem.isSelected = true;
          }
        });
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForNight = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForNight.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForNight = [];
        }
      }
    }

    if (
      this.isMorningSlotSelected ||
      this.isAfternoonSlotSelected ||
      this.isEveningSlotSelected ||
      this.isNightSlotSelected
    ) {
      this.isValidTurfSlot = true;
    }
  }

  radioChangeHandler(event: any) {
    this.isChangingNetToNonNetting = true;
    let radioSelectedValue = event.target?.name;
    // console.log(radioSelectedValue)
    if (radioSelectedValue == 'Netting') {
      this.onAcceptClick();
    } else {
      this.onRejectClick();
    }
  }
  onAcceptClick() {
    this.isNettingButtonSelected = true;
    this.isNonNettingButtonSelected = false;
    this.resetTurfSize();
  }
  onRejectClick() {
    this.isNonNettingButtonSelected = true;
    this.isNettingButtonSelected = false;
    this.resetTurfSize();
  }
  resetTurfSize() {
    this.listOfTurfSize = [
      {
        title: '5:5',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '6:6',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '7:7',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '8:8',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '9:9',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '11:11',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '1:1',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
    ];
    // this.listOfTurfSize.forEach((title: any) => {
    //   title['enabled'] = true;
    //   title['isSelected'] = false;
    // });
    this.isChangingNetToNonNetting = false;

    this.listOfPriceForMorning.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = true;
      slot.price = 0;
    });
    this.listOfPriceForAfternoon.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = true;
      slot.price = 0;
    });
    this.listOfPriceForEvening.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = true;
      slot.price = 0;
    });
    this.listOfPriceForNight.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = true;
      slot.price = 0;
    });
  }

  addQuantityForTurfSize(title: any, target: any) {
    let quantity = target.value;

    if (quantity >= 0) {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForMorning.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            prc['quantity'] = parseInt(quantity);
          }
        });
        this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            prc['quantity'] = parseInt(quantity);
          }
        });
        this.listOfPriceForEvening.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            prc['quantity'] = parseInt(quantity);
          }
        });
        this.listOfPriceForNight.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            prc['quantity'] = parseInt(quantity);
          }
        });
        this.listOfTurfSize.forEach((game: any) => {
          if (game['title'] == title) {
            game['quantity'] = parseInt(quantity);
          }
        });
      } else {
        this.gameQuantity.forEach((data: any) => {
          if (data['title'] == title) {
            data['quantity'] = parseInt(quantity);
          }
        });
        // console.log('gameQuantity',this.gameQuantity);
      }
    } else {
      this.showWarningToaster(
        'Quantity should be in positive number',
        'Warning'
      );
    }
  }

  addLabelForTurfSize(title: any, target: any) {
    let label = target.value;
    if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
      this.listOfPriceForMorning.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          if (!prc['label']) prc['label'] = [];
          const labelIndex = prc['label'].indexOf(label);
          if (labelIndex !== -1) {
            prc['label'] = [];
          }
          prc['label'].push(label);
          console.log(prc['label']);
        }
      });
      this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          if (!prc['label']) prc['label'] = [];
          const labelIndex = prc['label'].indexOf(label);
          if (labelIndex !== -1) {
            prc['label'] = [];
          }
          prc['label'].push(label);
        }
      });
      this.listOfPriceForEvening.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          if (!prc['label']) prc['label'] = [];
          const labelIndex = prc['label'].indexOf(label);
          if (labelIndex !== -1) {
            prc['label'] = [];
          }
          prc['label'].push(label);
        }
      });
      this.listOfPriceForNight.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          if (!prc['label']) prc['label'] = [];
          const labelIndex = prc['label'].indexOf(label);
          if (labelIndex !== -1) {
            prc['label'] = [];
          }
          prc['label'].push(label);
        }
      });
      this.listOfTurfSize.forEach((game: any) => {
        if (game['title'] == title) {
          if (!game['label']) game['label'] = [];
          const labelIndex = game['label'].indexOf(label);
          if (labelIndex !== -1) {
            game['label'] = [];
          }
          game['label'].push(label);
        }
      });
    } else {
      this.gameQuantity.forEach((data: any) => {
        if (data['title'] == title) {
          if (!data['label']) data['label'] = [];
          const labelIndex = data['label'].indexOf(label);
          if (labelIndex !== -1) {
            data['label'] = [];
          }
          data['label'].push(label);
        }
      });
      // console.log('label gameQuantity',this.gameQuantity);
    }
  }

  getLabelInputs(data: any): string[] {
    let labelInputs: string[] = [];
    if (data['isSelected']) {
      const quantity = data['quantity'] > 5 ? 0 : data['quantity'];
      for (let i = 0; i < quantity; i++) {
        if (data['label'] && data['label'][i]) {
          labelInputs.push(data['label'][i]);
        } else {
          labelInputs.push('');
        }
      }
    }
    return labelInputs;
  }

  addPriceForTurfSize(title: any, target: any, slot: any, weekend: boolean) {
    let price = target.value;
    if (price >= 0) {
      if (slot == 'Morning') {
        if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
          this.listOfPriceForMorning.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        } else {
          this.listofSlotForMorning.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        }
      } else if (slot == 'Afternoon') {
        if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
          this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        } else {
          this.listofSlotForAfternoon.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        }
      } else if (slot == 'Evening') {
        if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
          this.listOfPriceForEvening.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        } else {
          this.listofSlotForEvening.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        }
      } else if (slot == 'Night') {
        if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
          this.listOfPriceForNight.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        } else {
          this.listofSlotForNight.forEach((prc: any, index: number) => {
            if (prc['title'] == title && prc['isSelected']) {
              if (weekend) {
                prc['weekendPrice'] = parseInt(price);
              } else {
                prc['price'] = parseInt(price);
              }
            }
          });
        }
      }
    } else {
      this.showWarningToaster('Price Should be in postive number', 'Warning');
    }
  }

  changePricingSelectionForMorning(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForMorning.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForMorning.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForAfternoon(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForAfternoon.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForEvening(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForEvening.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForEvening.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForNight(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForNight.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForNight.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }

  addTimeForSlot(target: any, slot: any, startTime: boolean) {
    let time = parseInt(target.value);

    if (isNaN(time) || time < 0) {
      this.showWarningToaster('Time should be a positive number', 'Warning');
      return;
    }
    if (slot == 'Morning') {
      if (startTime) {
        if (time < 5 || time >= 12) {
          this.showWarningToaster(
            'Start time for Morning slot must be greater than 5 and less than 12',
            'Warning'
          );
          return;
        }
        if (time >= parseInt(this.morningSlotTime.endTime)) {
          this.showWarningToaster(
            'Start time must be earlier than end time',
            'Warning'
          );
          return;
        }
        this.morningSlotTime.startTime = target.value;
      } else {
        if (time <= 5 || time > 12) {
          this.showWarningToaster(
            'End time for Morning slot must be greater than 5 and less than or equal to 12',
            'Warning'
          );
          return;
        }
        if (time <= parseInt(this.morningSlotTime.startTime)) {
          this.showWarningToaster(
            'End time must be later than start time',
            'Warning'
          );
          return;
        }
        this.morningSlotTime.endTime = target.value;
      }
    } else if (slot == 'Afternoon') {
      if (startTime) {
        if (time < 12 || time >= 16) {
          this.showWarningToaster(
            'Start time for Afternoon slot must be greater than 12 and less than 16',
            'Warning'
          );
          return;
        }
        if (time >= parseInt(this.afternoonSlotTime.endTime)) {
          this.showWarningToaster(
            'Start time must be earlier than end time',
            'Warning'
          );
          return;
        }
        this.afternoonSlotTime.startTime = target.value;
      } else {
        if (time <= 12 || time > 16) {
          this.showWarningToaster(
            'End time for Afternoon slot must be greater than 12 and less than or equal to 16',
            'Warning'
          );
          return;
        }
        if (time <= parseInt(this.afternoonSlotTime.startTime)) {
          this.showWarningToaster(
            'End time must be later than start time',
            'Warning'
          );
          return;
        }
        this.afternoonSlotTime.endTime = target.value;
      }
    } else if (slot == 'Evening') {
      if (startTime) {
        if (time < 16 || time >= 20) {
          this.showWarningToaster(
            'Start time for Evening slot must be greater than 16 and less than 20',
            'Warning'
          );
          return;
        }
        if (time >= parseInt(this.eveningSlotTime.endTime)) {
          this.showWarningToaster(
            'Start time must be earlier than end time',
            'Warning'
          );
          return;
        }
        this.eveningSlotTime.startTime = target.value;
      } else {
        if (time <= 16 || time > 20) {
          this.showWarningToaster(
            'End time for Evening slot must be greater than 16 and less than or equal to 20',
            'Warning'
          );
          return;
        }
        if (time <= parseInt(this.eveningSlotTime.startTime)) {
          this.showWarningToaster(
            'End time must be later than start time',
            'Warning'
          );
          return;
        }
        this.eveningSlotTime.endTime = target.value;
      }
    } else if (slot == 'Night') {
      if (startTime) {
        if (time < 20 || time >= 24) {
          this.showWarningToaster(
            'Start time for Night slot must be greater than 20 and less than 24',
            'Warning'
          );
          return;
        }
        if (time >= parseInt(this.nightSlotTime.endTime)) {
          this.showWarningToaster(
            'Start time must be earlier than end time',
            'Warning'
          );
          return;
        }
        this.nightSlotTime.startTime = target.value;
      } else {
        if (time <= 20 || time > 24) {
          this.showWarningToaster(
            'End time for Night slot must be greater than 20 and less than or equal to 24',
            'Warning'
          );
          return;
        }
        if (time <= parseInt(this.nightSlotTime.startTime)) {
          this.showWarningToaster(
            'End time must be later than start time',
            'Warning'
          );
          return;
        }
        this.nightSlotTime.endTime = target.value;
      }
    }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  addTurfSlots() {
    this.isLoading = false;

    if (
      (this.turfId == null && this.appService.selectedDays.length === 0) ||
      this.appService.selectedFacilities.length === 0 ||
      this.selectedSport.length === 0 ||
      !this.appService.selectedSportCategory
    ) {
      this.isAddEditSlotLoading = false;
      this.showWarningToaster('Please fill in all required fields', 'Warning');
      return;
    }

    if (this.turfId != null) {
      this.formdata.set('turfId', this.turfId);
      // this.formdata.set('sportCategory', this.selectedSportCategory['_id']);
    }

    // Days
    this.assignedDays = [];
    for (let index = 0; index < this.appService.selectedDays.length; index++) {
      const element = this.appService.selectedDays[index];
      // console.log(element);
      this.assignedDays.push(element);
    }
    // console.log('selecedt days', this.selectedDays);
    this.formdata.set('days', JSON.stringify(this.appService.selectedDays));
    // console.log(this.formdata.get('days'));
    // console.log('assigned days', this.assignedDays)

    // Facility
    let facilityNames = this.appService.selectedFacilities.map(
      (facility: any) => facility['facilityName']
    );
    this.formdata.set('facilities', JSON.stringify(facilityNames));

    // Sport Type
    // this.assignedSport = [];
    this.selectedSport.forEach((sport: any) => {
      this.assignedSport.push(sport['categoryName']);
    });
    // console.log('assigned sprot type', this.assignedSport);
    this.formdata.set('sportsType', JSON.stringify(this.selectedSport));

    // Sport Category
    // console.log('selected selectedSportCategory,', this.selectedSportCategory);

    this.formdata.set(
      'sportCategory',
      this.appService.selectedSportCategory.categoryId
    );

    // Turf Price
    let priceToInsert: any = [];
    if (this.selectedSportCategory?.categoryName == 'Outdoor') {
      if (this.listOfPriceForMorning.length > 0 && this.isMorningSlotSelected) {
        priceToInsert.push({
          session: 'Morning',
          startTime: this.morningSlotTime['startTime'],
          endTime: this.morningSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Morning') {
            index = i;
          }
        });
        this.listOfPriceForMorning.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (
        this.listOfPriceForAfternoon.length > 0 &&
        this.isAfternoonSlotSelected
      ) {
        priceToInsert.push({
          session: 'Afternoon',
          startTime: this.afternoonSlotTime['startTime'],
          endTime: this.afternoonSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Afternoon') {
            index = i;
          }
        });
        this.listOfPriceForAfternoon.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (this.listOfPriceForEvening.length > 0 && this.isEveningSlotSelected) {
        priceToInsert.push({
          session: 'Evening',
          startTime: this.eveningSlotTime['startTime'],
          endTime: this.eveningSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Evening') {
            index = i;
          }
        });
        this.listOfPriceForEvening.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (this.listOfPriceForNight.length > 0 && this.isNightSlotSelected) {
        priceToInsert.push({
          session: 'Night',
          startTime: this.nightSlotTime['startTime'],
          endTime: this.nightSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Night') {
            index = i;
          }
        });
        this.listOfPriceForNight.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }

      priceToInsert.forEach((price: any) => {
        price['priceAndQuantity'].forEach((quantity: any) => {
          this.listOfTurfSize.forEach((game: any) => {
            if (game['title'] == quantity['title']) {
              quantity['quantity'] = game['quantity'];
              quantity['label'] = game['label'];
            }
          });
        });
      });
    } else {
      if (this.isMorningSlotSelected) {
        priceToInsert.push({
          session: 'Morning',
          startTime: this.morningSlotTime['startTime'],
          endTime: this.morningSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Morning') {
            index = i;
          }
        });
        this.listofSlotForMorning.forEach((data: any) => {
          if (data['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: data['title'],
              price: data['price'],
              weekendPrice: data['weekendPrice'],
              quantity: data['quantity'],
              label: data['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (this.isAfternoonSlotSelected) {
        priceToInsert.push({
          session: 'Afternoon',
          startTime: this.afternoonSlotTime['startTime'],
          endTime: this.afternoonSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Afternoon') {
            index = i;
          }
        });
        this.listofSlotForAfternoon.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (this.isEveningSlotSelected) {
        priceToInsert.push({
          session: 'Evening',
          startTime: this.eveningSlotTime['startTime'],
          endTime: this.eveningSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Evening') {
            index = i;
          }
        });
        this.listofSlotForEvening.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      if (this.isNightSlotSelected) {
        priceToInsert.push({
          session: 'Night',
          startTime: this.nightSlotTime['startTime'],
          endTime: this.nightSlotTime['endTime'],
          priceAndQuantity: [],
        });
        let index = 0;
        priceToInsert.forEach((element: any, i: number) => {
          if (element['session'] == 'Night') {
            index = i;
          }
        });
        this.listofSlotForNight.forEach((prc: any) => {
          if (prc['isSelected']) {
            priceToInsert[index]['priceAndQuantity'].push({
              title: prc['title'],
              price: prc['price'],
              weekendPrice: prc['weekendPrice'],
              quantity: prc['quantity'],
              label: prc['label'],
              advanceAmount: this.turfInfo?.advanceAmount,
            });
          }
        });
      }
      priceToInsert.forEach((price: any) => {
        price['priceAndQuantity'].forEach((quantity: any) => {
          this.gameQuantity.forEach((game: any) => {
            if (game['title'] == quantity['title']) {
              quantity['quantity'] = game['quantity'];
              quantity['label'] = game['label'];
            }
          });
        });
      });
    }

    // console.log(priceToInsert);

    this.formdata.set('slots', JSON.stringify(priceToInsert));

    // this.formdata.set('isNet', this.isNettingButtonSelected ? this.isNettingButtonSelected?.toString() : this.isNonNettingButtonSelected.toString());
    this.formdata.set('isNet', this.isNettingButtonSelected.toString());

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    URL = `${webApi.domain + webApi.endPoint.createAndEditTurfSlotsV2}`;
    try {
      fetch(URL, requestOptions).then((result) => {
        // this.isLoading = true;
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditSlotLoading = false;
              if (this.turfId != null) {
                this.showSuccessToaster(
                  'Turf Slots edited successfully',
                  'Success'
                );
              } else {
                this.showSuccessToaster(
                  'Turf Slots added successfully',
                  'Success'
                );
              }
              this.closeEdit.emit();
            } else {
              this.isAddEditSlotLoading = false;
              if (this.turfId != null) {
                this.showWarningToaster(res['message'], 'Warning');
              } else {
                this.showWarningToaster(res['message'], 'Warning');
              }
            }
          });
        }
      });
    } catch (error) {
      this.isAddEditSlotLoading = false;
      if (this.turfId != null) {
        this.showWarningToaster('Unable to edit Turf Slots', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Turf Slots', 'Warning');
      }
    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
