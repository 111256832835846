import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VenueManagementComponent } from './venue-management.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { MatStepperModule } from '@angular/material/stepper';
import { ListVenueManagementComponent } from './list-venue-management/list-venue-management.component';
import { VenueManagementRoutingModule } from './venue-management-routing.component';
import { AboutVenueComponent } from './about-venue/about-venue.component';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { AddSlotsComponent } from './add-slots/add-slots.component';
import { AddPhotosComponent } from './add-photos/add-photos.component';
import { AddEditVenueComponent } from './add-edit-venue/add-edit-venue.component';



@NgModule({
  declarations: [
    VenueManagementComponent,
    ListVenueManagementComponent,
    AboutVenueComponent,
    AddressDetailsComponent,
    AddSlotsComponent,
    AddPhotosComponent,
    AddEditVenueComponent
  ],
  imports: [
    CommonModule,
    VenueManagementRoutingModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    LightboxModule,
    MatStepperModule,
  ]
})
export class VenueManagementModule { }
