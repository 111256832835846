import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { SuspendBusinessDialogComponent } from '../suspend-business-dialog/suspend-business-dialog.component';
import { ViewBusinessComponent } from '../view-business/view-business.component';
export interface IBusiness {
  _id: string;
  id: string;
  user: Object;
  document: [];
  bankDetails: [];
  status: string;
  isActive: boolean;
  isDeleted: boolean;
  userName: string;
  phone: string;
  email: string;
  createdAt: string;
  remark: string;
}
@Component({
  selector: 'app-list-business',
  templateUrl: './list-business.component.html',
  styleUrls: ['./list-business.component.scss'],
})
export class ListBusinessComponent implements OnInit {
  isAddEditCalled: boolean = false;
  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;

  locationId: any;
  p: any = 1;
  count: any = 50;
  dataToEdit: any;
  userDetails: any;
  isLoading: boolean = true;
  listOfOriginalBusiness: any = [];
  listOfBusiness: Array<IBusiness> = [];
  listOfBusinessToDisplay: Array<IBusiness> = [];
  constructor(
    public appService: AppService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.locationId = params.id; // same as :username in route
    });

    this.fetchAllBusiness();
  }

  async fetchAllBusiness() {
    this.isLoading = true;
    this.listOfBusiness = [];
    let param = {};
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.userDetails['accessToken']}`,
    });
    let URL;
    URL = `${webApi.domain + webApi.endPoint.fetchAllBusiness}`;
    param =
      this.locationId == undefined || this.locationId == null
        ? {}
        : { clusterId: this.locationId };
    await this.appService
      .fetchDetail(param, URL, httpHeaders)
      .then((data: any) => {
        if (data['success']) {
          this.listOfOriginalBusiness = data['result'];
          data['result'].forEach((business: any) => {
            if (business['user'] != null) {
              this.listOfBusiness.push({
                _id: business['_id'],
                id: business['_id'],
                user: business['user'],
                document: business['document'],
                bankDetails: business['bankDetails'],
                status: business['status'],
                isActive: business['isActive'],
                isDeleted: business['isDeleted'],
                userName:
                  business['user']['firstName'] +
                  ' ' +
                  business['user']['lastName'],
                phone: business['user']['phone'],
                email: business['user']['email'],
                createdAt: business['createdAt'],
                remark: business['remark'],
              });
            }
          });
          // this.listOfBusiness = data['result'];
          console.log(this.listOfBusiness);
          this.listOfBusinessToDisplay = this.listOfBusiness;

          this.isLoading = false;
        } else {
        }
      });
  }
  searchData(target: any) {
    // console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfBusinessToDisplay = [];

      this.listOfBusiness.forEach((business: any, index: number) => {
        if (
          String(business['userName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(business['phone'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(business['status'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(business);
        }
      });
      this.listOfBusinessToDisplay = [];
      this.listOfBusinessToDisplay = tempSearchList;
    } else {
      this.listOfBusinessToDisplay = this.listOfBusiness;
    }
  }
  viewTurfOfBusiness(business: any) {
    this.appService.selectedBusiness = business._id;
    // this.router.navigate(['/admin/turf']);
    this.appService.gotoComponent('Venue Management', 'Admin');
  }
  actionAcceptDelete(business: any, action: any) {}
  addNewBusiness() {
    this.toggleOpened();
  }
  viewBusinessDetails(business: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      business: business,
    };
    dialogConfig.maxHeight = `75vh`;
    let dialogRef = this.matDialog.open(ViewBusinessComponent, dialogConfig);
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      // console.log(res);
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Accept Business') {
          const param = {
            businessId: res['value']['_id'],
            status: 'APPROVED',
            remark: 'Approved by admin successfully',
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${
            webApi.domain + webApi.endPoint.verifyAndUnVerifyBusiness
          }`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfBusiness.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfBusiness[index]['status'] =
                      data['result']['status'];
                    this.listOfBusiness[index]['remark'] =
                      data['result']['remark'];
                  }
                });
                this.listOfBusinessToDisplay = this.listOfBusiness;

                this.showSuccessToaster(
                  'Business Accepted Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while accepting Business. Try again',
                  'Error'
                );
              }
            });
        } else if (res['action'] == 'Reject Business') {
          const param = {
            businessId: res['value']['_id'],
            status: 'REJECTED',
            remark: res['moreVal'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${
            webApi.domain + webApi.endPoint.verifyAndUnVerifyBusiness
          }`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfBusiness.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfBusiness[index]['status'] =
                      data['result']['status'];
                    this.listOfBusiness[index]['remark'] =
                      data['result']['remark'];
                  }
                });
                this.listOfBusinessToDisplay = this.listOfBusiness;

                this.showSuccessToaster(
                  'Business rejected Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while rejecting Business. Try again',
                  'Error'
                );
              }
            });
        }
      }
    });
  }
  editBusiness(soc: any) {
    this.listOfOriginalBusiness.forEach((data: any) => {
      if (data['_id'] == soc['_id']) {
        this.dataToEdit = data;
      }
    });
    this.toggleOpened();
  }
  deleteBusiness(bus: any) {
    let business;
    this.listOfOriginalBusiness.forEach((data: any) => {
      if (data['_id'] == bus['_id']) {
        business = data;
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { business: business, source: 'Delete' };

    let dialogRef = this.matDialog.open(
      SuspendBusinessDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Business') {
          const param = {
            businessId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteBusiness}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfBusiness.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfBusiness.splice(index, 1);
                  }
                });
                this.listOfBusinessToDisplay = this.listOfBusiness;

                this.showSuccessToaster(
                  'Business Deleted Successfully',
                  'Success'
                );
              } else {
                this.showErrorToaster(
                  'Error Occurred while deleting Business. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }
  suspendBusiness(bus: any, actionToUpdate: boolean) {
    let business;
    this.listOfBusiness.forEach((data: any) => {
      if (data['_id'] == bus['_id']) {
        business = data;
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { business: business, source: 'Suspend' };

    let dialogRef = this.matDialog.open(
      SuspendBusinessDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Suspend Business') {
          const param = {
            businessId: res['value']['_id'],
            action: actionToUpdate,
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.suspendBusiness}`;
          await this.appService
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                this.listOfBusiness.forEach((business: any, index: number) => {
                  if (business['_id'] == res['value']['_id']) {
                    this.listOfBusiness[index].isActive = actionToUpdate;
                  }
                });
                this.listOfBusinessToDisplay = this.listOfBusiness;
                if (!actionToUpdate) {
                  this.showSuccessToaster(
                    'Business Suspended Successfully',
                    'Success'
                  );
                } else {
                  this.showSuccessToaster(
                    'Business Suspension Revoked Successfully',
                    'Success'
                  );
                }
              } else {
                this.showErrorToaster(
                  'Error Occurred while suspending Business. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }
  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing business details.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding business details.Kindly try again',
            'Error'
          );
        }

        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfOriginalBusiness.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalBusiness[index] = event;
          }
        });
        this.listOfBusiness.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalBusiness[index] = event;
            this.listOfBusiness[index] = {
              _id: event['_id'],
              id: event['_id'],
              user: event['user'],
              document: event['document'],
              bankDetails: event['bankDetails'],
              status: event['status'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
              userName:
                event['user']['firstName'] + ' ' + event['user']['lastName'],
              phone: event['user']['phone'],
              email: event['user']['email'],

              createdAt: event['createdAt'],
              remark: event['remark'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfBusiness.push(event);
        }
        this.showSuccessToaster('Business Edited Successfully', 'Success');
      } else {
        let isExist = false;
        this.listOfOriginalBusiness.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            this.listOfOriginalBusiness[index] = event;
          }
        });
        this.listOfBusiness.forEach((business: any, index: number) => {
          if (business['_id'] == event['_id']) {
            // this.listOfBusiness[index] = event;
            this.listOfBusiness[index] = {
              _id: event['_id'],
              id: event['_id'],
              user: event['user'],
              document: event['document'],
              bankDetails: event['bankDetails'],
              status: event['status'],
              isActive: event['isActive'],
              isDeleted: event['isDeleted'],
              userName:
                event['user']['firstName'] + ' ' + event['user']['lastName'],
              email: event['user']['email'],
              phone: event['user']['phone'],
              createdAt: event['createdAt'],
              remark: event['remark'],
            };
            isExist = true;
          }
        });
        if (!isExist) {
          this.listOfOriginalBusiness.push(event);
          this.listOfBusiness.push({
            _id: event['_id'],
            id: event['_id'],
            user: event['user'],
            document: event['document'],
            bankDetails: event['bankDetails'],
            status: event['status'],
            isActive: event['isActive'],
            isDeleted: event['isDeleted'],
            userName:
              event['user']['firstName'] + ' ' + event['user']['lastName'],
            phone: event['user']['phone'],
            email: event['user']['email'],
            createdAt: event['createdAt'],
            remark: event['remark'],
          });
        }
        this.showSuccessToaster('Society Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
