import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-about-venue',
  templateUrl: './about-venue.component.html',
  styleUrls: ['./about-venue.component.scss'],
})
export class AboutVenueComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfBusiness: any;
  @Input() isAdd: boolean = false;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  userDetails: any;
  turfId: any;
  turfInfo: any;
  formdata = new FormData();
  isAddEditAboutLoading: boolean = false;
  submitted: boolean = false;

  myHeaders = new Headers();

  addTurfForm!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this._activatedRoute.queryParams.subscribe((params) => {
      this.turfId = params['id'];
      console.log(this.turfId);
    });
    console.log('about Id', this.turfId);

    this.addTurfForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      recommendedVenue: new FormControl(false),
      description: new FormControl('', [Validators.required]),
      business: new FormControl('Select Business', [Validators.required]),
      turfType: new FormControl('Select Turf Type', [Validators.required]),
      commission: new FormControl('', [Validators.required,  Validators.pattern(/^\d*$/)]),
      status: new FormControl('Select Status', [Validators.required]),
      advanceAmount: new FormControl('', [Validators.required,  Validators.pattern(/^\d*$/)]),
      ownerWhatsAppConfirmation: new FormControl('Select Option', []),
      recommendedPosition: new FormControl(0, [Validators.required]),
    });
  }

  ngOnInit(): void {
    if (this.turfId) {
      this.fetchTurfByIdV2();
    } else {
      this.appService.selectedDays = [];
      this.appService.selectedFacilities = [];
      this.appService.selectedSportCategory = {};

      if (!this.addTurfForm.get('recommendedVenue')?.value) {
        this.addTurfForm.addControl(
          'recommendedPosition',
          new FormControl('', [Validators.required])
        );
      }
    }
  }

  validateAboutVenueStep(): boolean {
    const aboutVenueFormValid = this.addTurfForm.valid;

    const anyFieldEmpty = Object.values(this.addTurfForm.value).some(value => value === '');

    if (anyFieldEmpty) {
      this.showWarningToaster('Please fill in all required fields in the About Venue', 'Warning');
    }
    if (!aboutVenueFormValid) {
      this.submitted = true;
      // this.showWarningToaster('Please fill valid details in the About Venue', 'Warning');
    }

    return aboutVenueFormValid && !anyFieldEmpty;
  }

  fetchTurfByIdV2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${
        webApi.domain + webApi.endPoint.fetchTurfByIdV2 + `/${this.turfId}`
      }`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.turfInfo = data.result;
          // console.log('about turfInfo', this.turfInfo);
          this.addTurfForm.get('name')?.setValue(this.turfInfo['name']);
          this.addTurfForm.get('phone')?.setValue(this.turfInfo['phone']);
          this.addTurfForm
            .get('isRecommended')
            ?.setValue(this.turfInfo['isRecommended']);
          this.addTurfForm
            .get('description')
            ?.setValue(this.turfInfo['description']);
          this.addTurfForm
            .get('business')
            ?.setValue(this.turfInfo['business']['_id']);
          this.addTurfForm.get('turfType')?.setValue(this.turfInfo['turfType']);
          this.addTurfForm
            .get('commission')
            ?.setValue(this.turfInfo['commission']);
          this.addTurfForm.get('status')?.setValue(this.turfInfo['status']);
          this.addTurfForm
            .get('advanceAmount')
            ?.setValue(this.turfInfo['advanceAmount']);
          if (
            this.turfInfo['ownerWhatsAppConfirmation'] != null &&
            this.turfInfo['ownerWhatsAppConfirmation'] != undefined &&
            this.turfInfo['ownerWhatsAppConfirmation'] != ''
          ) {
            this.addTurfForm
              .get('ownerWhatsAppConfirmation')
              ?.setValue(this.turfInfo['ownerWhatsAppConfirmation']);
          }
          this.addTurfForm
            .get('recommendedVenue')
            ?.setValue(this.turfInfo['isRecommended']);
          // if (this.turfInfo['isRecommended']) {
          //   this.addTurfForm.get('recommendedPosition')?.setValue(this.turfInfo['recommendedPosition']);
          // }
          if (this.turfInfo['isRecommended']) {
            this.addTurfForm.addControl(
              'recommendedPosition',
              new FormControl('', [Validators.required])
            );
            this.addTurfForm
              .get('recommendedPosition')
              ?.setValue(parseInt(this.turfInfo['recommendedPosition']));
          } else {
            this.addTurfForm.removeControl('recommendedPosition');
          }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  get f() {
    return this.addTurfForm.controls;
  }

  recommendedVenueChange(event: any) {
    console.log(event.target.value);
    if (event.target.value == 'true') {
      this.addTurfForm.addControl(
        'recommendedPosition',
        new FormControl('', [Validators.required])
      );
      this.addTurfForm.get('recommendedVenue')?.setValue(true);
    } else {
      this.addTurfForm.removeControl('recommendedPosition');
      this.addTurfForm.get('recommendedVenue')?.setValue(false);
    }
  }

  onSubmitAboutTurf() {
    if (!this.validateAboutVenueStep()) {
      this.isLoading = false;
      this.submitted = true;
      return;
    }

    if (this.turfId != null) {
      this.formdata.set('turfId', this.turfId);
    } else {
      this.formdata.set('turfId', '0');
    }

    this.formdata.set('businessId', this.addTurfForm.get('business')?.value);
    this.formdata.set(
      'isRecommended',
      this.addTurfForm.get('recommendedVenue')?.value
    );
    this.formdata.set(
      'recommendedPosition',
      this.addTurfForm.get('recommendedVenue')?.value
        ? this.addTurfForm.get('recommendedPosition')?.value
        : 0
    );
    this.formdata.set('name', this.addTurfForm.get('name')?.value);
    this.formdata.set('turfType', this.addTurfForm.get('turfType')?.value);
    this.formdata.set('status', this.addTurfForm.get('status')?.value);
    this.formdata.set('phone', this.addTurfForm.get('phone')?.value);
    this.formdata.set('commission', this.addTurfForm.get('commission')?.value);
    this.formdata.set(
      'advanceAmount',
      this.addTurfForm.get('advanceAmount')?.value
    );
    if (
      this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != null &&
      this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != undefined &&
      this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != ''
    ) {
      this.formdata.set(
        'ownerWhatsAppConfirmation',
        this.addTurfForm.get('ownerWhatsAppConfirmation')?.value
      );
    }
    this.formdata.set(
      'description',
      this.addTurfForm.get('description')?.value
    );

    this.myHeaders.set(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.turfId != null) {
      URL = `${webApi.domain + webApi.endPoint.addEditTurfAboutInfoV2}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.addEditTurfAboutInfoV2}`;
    }
    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditAboutLoading = false;
              if (this.turfId != null) {
                this.showSuccessToaster('Turf edited successfully', 'Success');
              } else {
                this.showSuccessToaster('Turf added successfully', 'Success');
              }
              this.closeEdit.emit({ turfId: res['result']['_id'] });
            } else {
              this.isAddEditAboutLoading = false;
              if (this.turfId != null) {
                this.showWarningToaster(res['message'], 'Warning');
              } else {
                this.showWarningToaster(res['message'], 'Warning');
              }
            }
          });
        }
      });
    } catch (error) {
      this.isAddEditAboutLoading = false;
      if (this.turfId != null) {
        this.showWarningToaster('Unable to edit Turf', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Turf', 'Warning');
      }
    }
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  closeEditBtnHandler() {
    this.closeEdit.emit();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
