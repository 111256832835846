import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-turf-sidebar',
  templateUrl: './add-edit-turf-sidebar.component.html',
  styleUrls: ['./add-edit-turf-sidebar.component.scss'],
})
export class AddEditTurfSidebarComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfSports: any;
  @Input() listOfBusiness: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  isChangingNetToNonNetting: boolean = false;
  isValidTurfPrice: boolean = false;
  isValidTurfSlot: boolean = false;
  isValidTurfImageImage: boolean = false;

  // Turf Card Image
  selectedTurfImage: any = [];
  selectedTurfImageName: any = [];
  defaultTurfImage: any = [];

  myHeaders = new Headers();
  formdata = new FormData();

  turfNameControl = new FormControl('', [Validators.required]);
  turfContactControl = new FormControl('', [Validators.required]);
  descriptionControl = new FormControl('', [Validators.required]);
  businessControl = new FormControl('Select Business', [Validators.required]);
  turfTypeControl = new FormControl('BOOK', [Validators.required]);
  recommendedVenueControl = new FormControl(false);

  streetNameControl = new FormControl('', [Validators.required]);
  landmarkControl = new FormControl('', [Validators.required]);
  pincodeControl = new FormControl('', [Validators.required]);
  cityControl = new FormControl('', [Validators.required]);
  commissionControl = new FormControl('', [Validators.required]);
  stateControl = new FormControl('', [Validators.required]);
  advanceAmountControl = new FormControl('', [Validators.required]);
  statusControl = new FormControl('Select Status', [Validators.required]);
  ownerWhatsAppConfirmationControl = new FormControl('Select Option', []);

  isNettingButtonSelected: boolean = true;
  isNonNettingButtonSelected: boolean = false;
  sportsTypeDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'sport',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  daysDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'dayLabel',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 7,
    allowSearchFilter: true,
  };
  facilitiesDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'facilityName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  listOfDays: any = [
    { _id: 1, val: 'Monday', dayLabel: 'Mon' },
    { _id: 2, val: 'Tueday', dayLabel: 'Tue' },
    { _id: 3, val: 'Wednesday', dayLabel: 'Wed' },
    { _id: 4, val: 'Thursday', dayLabel: 'Thu' },
    { _id: 5, val: 'Friday', dayLabel: 'Fri' },
    { _id: 6, val: 'Saturday', dayLabel: 'Sat' },
    { _id: 7, val: 'Sunday', dayLabel: 'Sun' },
  ];
  listOfFacilities: any = [
    {
      _id: 1,
      facilityName: 'Washroom',
    },
    {
      _id: 2,
      facilityName: 'Cafe & Food court',
    },
    {
      _id: 3,
      facilityName: 'Power Backup',
    },
    {
      _id: 4,
      facilityName: 'Changing Room',
    },
    {
      _id: 5,
      facilityName: 'First Aid',
    },
    {
      _id: 6,
      facilityName: 'Parking',
    },
  ];

  listofSlotForMorning: any = [];
  listofSlotForAfternoon: any = [];
  listofSlotForEvening: any = [];
  listofSlotForNight: any = [];
  gameQuantity: any = [];

  addTurfForm: FormGroup = new FormGroup({
    turfName: this.turfNameControl,
    recommendedVenue: this.recommendedVenueControl,
    turfContact: this.turfContactControl,
    description: this.descriptionControl,
    business: this.businessControl,
    turfType: this.turfTypeControl,
    streetName: this.streetNameControl,
    landmark: this.landmarkControl,
    pincode: this.pincodeControl,
    city: this.cityControl,
    state: this.stateControl,
    status: this.statusControl,
    advanceAmount: this.advanceAmountControl,
    ownerWhatsAppConfirmation: this.ownerWhatsAppConfirmationControl,
    commission: this.commissionControl,
  });

  // Sport Type
  selectedSport: any = [];
  assignedSport: any = [];

  //Sport Category
  sportCategory: any = [];
  selectedSportCategory: any = {};

  // Days
  selectedDays: any = [];
  assignedDays: any = [];

  // Facilities Type
  selectedFacilities: any = [];
  assignedFacilities: any = [];

  // listOfSlots any = [];
  selectedPrices: any = [];

  isMorningSlotSelected: boolean = false;
  isAfternoonSlotSelected: boolean = false;
  isEveningSlotSelected: boolean = false;
  isNightSlotSelected: boolean = false;

  morningSlotTime: any = {
    startTime: 5.0,
    endTime: 12.0,
  };
  afternoonSlotTime: any = {
    startTime: 12.0,
    endTime: 16.0,
  };
  eveningSlotTime: any = {
    startTime: 16.0,
    endTime: 20.0,
  };
  nightSlotTime: any = {
    startTime: 20.0,
    endTime: 24.0,
  };

  listOfPriceForMorningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];
  listOfPriceForAfternoonOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },

  ];
  listOfPriceForEveningOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNightOriginal: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];
  listOfPriceForMorning: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 5.0,
      endTime: 12.0,
    },
  ];
  listOfPriceForAfternoon: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 12.0,
      endTime: 16.0,
    },
  ];
  listOfPriceForEvening: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 16.0,
      endTime: 20.0,
    },
  ];
  listOfPriceForNight: any = [
    {
      title: '1:1',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '5:5',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '6:6',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '7:7',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '8:8',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '9:9',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
    {
      title: '11:11',
      price: 0,
      weekendPrice: 0,
      quantity: 0,
      label: '',
      isSelected: false,
      enabled: true,
      startTime: 20.0,
      endTime: 24.0,
    },
  ];
  listOfTurfSize: any = [
    {
      title: '1:1',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '5:5',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '6:6',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '7:7',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '8:8',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '9:9',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
    {
      title: '11:11',
      isSelected: false,
      enabled: true,
      quantity: 0,
      label: '',
    },
  ];

  checkForWeekend() {
    var p = 0;
    for (let index = 0; index < this.selectedDays.length; index++) {
      const element = this.selectedDays[index];
      if (element['dayLabel'] == 'Sat' || element['dayLabel'] == 'Sun') {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }
  checkForWeekday() {
    var p = 0;
    for (let index = 0; index < this.selectedDays.length; index++) {
      const element = this.selectedDays[index];
      if (
        element['dayLabel'] == 'Mon' ||
        element['dayLabel'] == 'Tue' ||
        element['dayLabel'] == 'Wed' ||
        element['dayLabel'] == 'Thu' ||
        element['dayLabel'] == 'Fri'
      ) {
        p = 1;
      }
    }
    if (p == 1) {
      return true;
    } else {
      return false;
    }
  }

  radioChangeHandler(event: any) {
    this.isChangingNetToNonNetting = true;
    let radioSelectedValue = event.target.name;
    if (radioSelectedValue == 'Netting') {
      this.onAcceptClick();
    } else {
      this.onRejectClick();
    }
  }
  onAcceptClick() {
    this.isNettingButtonSelected = true;
    this.isNonNettingButtonSelected = false;
    this.resetTurfSize();
  }
  onRejectClick() {
    this.isNonNettingButtonSelected = true;
    this.isNettingButtonSelected = false;
    this.resetTurfSize();
  }

  resetTurfSize() {
    this.listOfTurfSize = [
      {
        title: '5:5',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '6:6',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '7:7',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '8:8',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '9:9',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '11:11',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
      {
        title: '1:1',
        isSelected: false,
        enabled: true,
        quantity: 0,
      },
    ];
    // this.listOfTurfSize.forEach((title: any) => {
    //   title['enabled'] = true;
    //   title['isSelected'] = false;
    // });
    this.isChangingNetToNonNetting = false;

    this.listOfPriceForMorning.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = false;
      slot.price = 0;
    });
    this.listOfPriceForAfternoon.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = false;
      slot.price = 0;
    });
    this.listOfPriceForEvening.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = false;
      slot.price = 0;
    });
    this.listOfPriceForNight.forEach((slot: any) => {
      slot.enabled = true;
      slot.isSelected = false;
      slot.price = 0;
    });
  }

  userDetails: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.addTurfForm = this.formBuilder.group({
      turfName: this.turfNameControl,
      turfContact: this.turfContactControl,
      recommendedVenue: this.recommendedVenueControl,
      description: this.descriptionControl,
      business: this.businessControl,
      turfType: this.turfTypeControl,
      streetName: this.streetNameControl,
      landmark: this.landmarkControl,
      pincode: this.pincodeControl,
      city: this.cityControl,
      commission: this.commissionControl,
      state: this.stateControl,
      status: this.statusControl,
      advanceAmount: this.advanceAmountControl,
      ownerWhatsAppConfirmation: this.ownerWhatsAppConfirmationControl,
    });
    this.fetchSport();
    this.assignEditValues();
  }

  async fetchSport() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      param = {};
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSports = data['result'];
          this.listOfSports.forEach((sport: any) => {
            const index = this.sportCategory.findIndex(
              (data: any) => data['id'] == sport['sportCategory']['_id']
            );
            if (index == -1) {
              this.sportCategory.push({
                id: sport['sportCategory']['_id'],
                categoryName: sport['sportCategory']['categoryName'],
                isSelected: false,
              });
            }
          });
          this.sportCategory[0]['isSelected'] = true;
          this.selectedSportCategory = {
            categoryId: this.sportCategory[0]['id'],
            categoryName: this.sportCategory[0]['categoryName'],
          };
          this.isLoading = false;
          this.assignEditValues();
        } else {
          this.isLoading = false;
          this.listOfSports = [];
          this.assignEditValues();
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.listOfSports = [];
      this.assignEditValues();
    }
  }

  getSports() {
    return this.listOfSports.filter(
      (sport: any) =>
        sport['sportCategory']['_id'] ==
        this.selectedSportCategory['categoryId']
    );
  }

  radioHandlerSportCategory(event: any) {
    this.selectedSport = [];
    this.assignedSport = [];
    this.isMorningSlotSelected = false;
    this.isAfternoonSlotSelected = false;
    this.isEveningSlotSelected = false;
    this.isNightSlotSelected = false;
    this.listOfTurfSize.forEach((data: any) => {
      this.gameQuantity = [];
      data['isSelected'] = false;
      data['enabled'] = false;
    });

    this.sportCategory.forEach((data: any) => {
      if (data['id'] == event.target.name) {
        data['isSelected'] = true;
        this.selectedSportCategory = {
          categoryId: data['id'],
          categoryName: data['categoryName'],
        };
      } else {
        data['isSelected'] = false;
      }
    });
  }
  recommendedVenueChange(event: any) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.addTurfForm.addControl(
        'recommendedPosition',
        new FormControl('', [Validators.required])
      );
    } else {
      this.addTurfForm.removeControl('recommendedPosition');
    }
  }
  assignEditValues() {
    if (this.dataToEdit != null && !this.isLoading) {
      this.addTurfForm.get('turfName')?.setValue(this.dataToEdit['name']);
      this.addTurfForm.get('turfContact')?.setValue(this.dataToEdit['phone']);
      this.addTurfForm
        .get('description')
        ?.setValue(this.dataToEdit['description']);
      this.addTurfForm.get('turfType')?.setValue(this.dataToEdit['turfType']);
      this.addTurfForm
        .get('recommendedVenue')
        ?.setValue(this.dataToEdit['isRecommended']);
      if (this.dataToEdit['isRecommended']) {
        this.addTurfForm.addControl(
          'recommendedPosition',
          new FormControl('', [Validators.required])
        );
        this.addTurfForm
          .get('recommendedPosition')
          ?.setValue(parseInt(this.dataToEdit['recommendedPosition']));
      }
      this.addTurfForm
        .get('commission')
        ?.setValue(this.dataToEdit['commission']);

      this.addTurfForm
        .get('advanceAmount')
        ?.setValue(this.dataToEdit['advanceAmount']);

      if (this.dataToEdit['ownerWhatsAppConfirmation'] != null && this.dataToEdit['ownerWhatsAppConfirmation'] != undefined && this.dataToEdit['ownerWhatsAppConfirmation'] != '') {
        this.addTurfForm
          .get('ownerWhatsAppConfirmation')
          ?.setValue(this.dataToEdit['ownerWhatsAppConfirmation']);
      }

      this.addTurfForm.get('status')?.setValue(this.dataToEdit['status']);
      this.addTurfForm
        .get('business')
        ?.setValue(this.dataToEdit['business']['_id']);
      this.addTurfForm
        .get('streetName')
        ?.setValue(this.dataToEdit['address']['streetName']);
      this.addTurfForm
        .get('landmark')
        ?.setValue(this.dataToEdit['address']['landmark']);
      this.addTurfForm
        .get('pincode')
        ?.setValue(this.dataToEdit['address']['pincode']);
      this.addTurfForm
        .get('city')
        ?.setValue(this.dataToEdit['address']['city']);
      this.addTurfForm
        .get('state')
        ?.setValue(this.dataToEdit['address']['state']);

      // Turf title
      this.dataToEdit['slots'].forEach((slot: any) => {
        if (slot['session'] == 'Morning') {
          this.morningSlotTime = {
            startTime: slot['startTime'],
            endTime: slot['endTime'],
          };
        } else if (slot['session'] == 'Afternoon') {
          this.afternoonSlotTime = {
            startTime: slot['startTime'],
            endTime: slot['endTime'],
          };
        } else if (slot['session'] == 'Evening') {
          this.eveningSlotTime = {
            startTime: slot['startTime'],
            endTime: slot['endTime'],
          };
        } else if (slot['session'] == 'Night') {
          this.nightSlotTime = {
            startTime: slot['startTime'],
            endTime: slot['endTime'],
          };
        }
        slot['priceAndQuantity'].forEach((price: any) => {
          this.listOfTurfSize.forEach((title: any) => {
            if (title['title'] == price['title']) {
              title['isSelected'] = true;
              title['enabled'] = true;
              title['quantity'] = price['quantity'];
              title['label'] =
                price['label'] == null || price['label'] == undefined
                  ? ''
                  : price['label'];
            }
          });
        });
      });
      this.selectedSportCategory = {
        categoryId: this.dataToEdit['sportCategory']['_id'],
        categoryName: this.dataToEdit['sportCategory']['categoryName'],
      };

      this.sportCategory.forEach((sport: any) => {
        if (
          sport['categoryName'] == this.selectedSportCategory['categoryName']
        ) {
          sport['isSelected'] = true;
        } else {
          sport['isSelected'] = false;
        }
      });

      if (this.dataToEdit['sportCategory']['categoryName'] == 'Outdoor') {
        if (this.dataToEdit['isNet']) {
          this.listOfTurfSize.forEach((title: any) => {
            if (
              (title['title'] == '6:6' && title['isSelected']) ||
              (title['title'] == '8:8' && title['isSelected'])
            ) {
              this.listOfTurfSize.forEach((slot: any) => {
                if (
                  slot.title == '9:9' ||
                  slot.title == '5:5' ||
                  slot.title == '7:7'
                ) {
                  slot.enabled = false;
                }
              });
            } else if (
              (title['title'] == '5:5' && title['isSelected']) ||
              (title['title'] == '7:7' && title['isSelected']) ||
              (title['title'] == '9:9' && title['isSelected'])
            ) {
              this.listOfTurfSize.forEach((slot: any) => {
                if (slot.title == '8:8' || slot.title == '6:6') {
                  slot.enabled = false;
                }
              });
            }
          });
        }

        let tempMorningPrice: any = [];
        this.listOfPriceForMorningOriginal.forEach((morningSlot: any) => {
          this.listOfTurfSize.forEach((element: any) => {
            if (
              element['title'] == morningSlot['title'] &&
              element['isSelected']
            ) {
              tempMorningPrice.push(morningSlot);
            }
          });
        });
        this.listOfPriceForMorning = tempMorningPrice;
        let tempAfternoonPrice: any = [];
        this.listOfPriceForAfternoonOriginal.forEach((afternoonSlot: any) => {
          this.listOfTurfSize.forEach((element: any) => {
            if (
              element['title'] == afternoonSlot['title'] &&
              element['isSelected']
            ) {
              tempAfternoonPrice.push(afternoonSlot);
            }
          });
        });
        this.listOfPriceForAfternoon = tempAfternoonPrice;
        let tempEveningPrice: any = [];
        this.listOfPriceForEveningOriginal.forEach((eveningSlot: any) => {
          this.listOfTurfSize.forEach((element: any) => {
            if (
              element['title'] == eveningSlot['title'] &&
              element['isSelected']
            ) {
              tempEveningPrice.push(eveningSlot);
            }
          });
        });
        this.listOfPriceForEvening = tempEveningPrice;
        let tempNightPrice: any = [];
        this.listOfPriceForNightOriginal.forEach((nightSlot: any) => {
          this.listOfTurfSize.forEach((element: any) => {
            if (
              element['title'] == nightSlot['title'] &&
              element['isSelected']
            ) {
              tempNightPrice.push(nightSlot);
            }
          });
        });
        this.listOfPriceForNight = tempNightPrice;

        // Turn Price

        this.dataToEdit['slots'].forEach((slot: any) => {
          if (slot['session'] == 'Morning') {
            this.isMorningSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listOfPriceForMorning.forEach((prc: any) => {
                if (prc['title'] == price['title']) {
                  prc['price'] = price['price'];
                  prc['weekendPrice'] = price['weekendPrice'];
                  prc['isSelected'] = true;
                  this.isValidTurfPrice = true;
                }
              });
            });
          } else if (slot['session'] == 'Afternoon') {
            this.isAfternoonSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listOfPriceForAfternoon.forEach((prc: any) => {
                if (prc['title'] == price['title']) {
                  prc['price'] = price['price'];
                  prc['weekendPrice'] = price['weekendPrice'];
                  prc['isSelected'] = true;
                  this.isValidTurfPrice = true;
                }
              });
            });
          } else if (slot['session'] == 'Evening') {
            this.isEveningSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listOfPriceForEvening.forEach((prc: any) => {
                if (prc['title'] == price['title']) {
                  prc['price'] = price['price'];
                  prc['weekendPrice'] = price['weekendPrice'];
                  prc['isSelected'] = true;
                  this.isValidTurfPrice = true;
                }
              });
            });
          } else if (slot['session'] == 'Night') {
            this.isNightSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listOfPriceForNight.forEach((prc: any) => {
                if (prc['title'] == price['title']) {
                  prc['price'] = price['price'];
                  prc['weekendPrice'] = price['weekendPrice'];
                  prc['isSelected'] = true;
                  this.isValidTurfPrice = true;
                }
              });
            });
          }
        });
      } else {
        var quantity: any = [];
        this.dataToEdit['slots'].forEach((slot: any) => {
          if (slot['session'] == 'Morning') {
            this.isMorningSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listofSlotForMorning.push({
                title: price['title'],
                price: price['price'],
                weekendPrice: price['weekendPrice'],
                quantity: price['quantity'],
                label: price['label'],
                isSelected: true,
                enabled: true,
              });
            });
          } else if (slot['session'] == 'Afternoon') {
            this.isAfternoonSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listofSlotForAfternoon.push({
                title: price['title'],
                price: price['price'],
                weekendPrice: price['weekendPrice'],
                quantity: price['quantity'],
                label: price['label'],
                isSelected: true,
                enabled: true,
              });
            });
          } else if (slot['session'] == 'Evening') {
            this.isEveningSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listofSlotForEvening.push({
                title: price['title'],
                price: price['price'],
                weekendPrice: price['weekendPrice'],
                quantity: price['quantity'],
                label: price['label'],
                isSelected: true,
                enabled: true,
              });
            });
          } else if (slot['session'] == 'Night') {
            this.isNightSlotSelected = true;
            slot['priceAndQuantity'].forEach((price: any) => {
              this.listofSlotForNight.push({
                title: price['title'],
                price: price['price'],
                weekendPrice: price['weekendPrice'],
                quantity: price['quantity'],
                label: price['label'],
                isSelected: true,
                enabled: true,
              });
            });
          }
          slot['priceAndQuantity'].forEach((price: any) => {
            const index = quantity.findIndex(
              (data: any) => data['title'] == price['title']
            );
            if (index == -1) {
              quantity.push({
                title: price['title'],
                quantity: price['quantity'],
                isSelected: true,
                enabled: true,
                label:
                  price['label'] == null || price['label'] == undefined
                    ? ''
                    : price['label'],
              });
            }
          });
        });
        this.gameQuantity = [...quantity];
      }

      //Days Pre fill
      this.dataToEdit['days'].forEach((data: any) => {
        this.listOfDays.forEach((day: any) => {
          if (day['dayLabel'] == data) {
            this.selectedDays.push(day);
            // this.assignedDays.push(data);
          }
        });
      });

      // Facility Pre fill
      this.dataToEdit['facilities'].forEach((data: any) => {
        this.listOfFacilities.forEach((facility: any) => {
          if (facility['facilityName'] == data) {
            this.selectedFacilities.push(facility);
            // this.assignedFacilities.push(facility);
          }
        });
      });

      // SportType Pre fill
      this.dataToEdit['sportsType'].forEach((data: any) => {
        this.listOfSports.forEach((sport: any) => {
          if (sport['_id'] == data['_id']) {
            this.selectedSport.push(sport);
            // this.assignedSport.push(sport['_id']);
          }
        });
      });

      // Images Of Turf
      this.dataToEdit['sport'].forEach((data: any, index: number) => {
        this.defaultTurfImage[index] = data['url'];
      });
    }
  }

  changeSlotSelection(slot: any, event: any) {
    let isSelected = event.target.checked;
    this.gameQuantity = [];
    this.selectedSport.forEach((data: any) => {
      this.gameQuantity.push({
        quantity: 0,
        title: data['sport'],
        isSelected: true,
        enabled: true,
        label: '',
      });
    });
    if (slot == 'Morning') {
      this.isMorningSlotSelected = isSelected;
      this.listOfPriceForMorning.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForMorning = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForMorning.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForMorning = [];
        }
      }
    } else if (slot == 'Afternoon') {
      this.isAfternoonSlotSelected = isSelected;
      this.listOfPriceForAfternoon.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForAfternoon = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForAfternoon.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForAfternoon = [];
        }
      }
    } else if (slot == 'Evening') {
      this.isEveningSlotSelected = isSelected;
      this.listOfPriceForEvening.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForEvening = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForEvening.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForEvening = [];
        }
      }
    } else if (slot == 'Night') {
      this.isNightSlotSelected = isSelected;
      this.listOfPriceForNight.forEach((element: any) => {
        element['isSelected'] = false;
      });
      if (this.selectedSportCategory['categoryName'] != 'Outdoor') {
        if (isSelected) {
          this.listofSlotForNight = [];
          this.selectedSport.forEach((data: any) => {
            this.listofSlotForNight.push({
              price: 0,
              quantity: 0,
              weekendPrice: 0,
              title: data['sport'],
              isSelected: true,
              enabled: false,
            });
          });
        } else {
          this.listofSlotForNight = [];
        }
      }
    }

    if (
      this.isMorningSlotSelected ||
      this.isAfternoonSlotSelected ||
      this.isEveningSlotSelected ||
      this.isNightSlotSelected
    ) {
      this.isValidTurfSlot = true;
    }
  }

  selectedTurfSize: any = [];

  selectSize(title: any, event: any) {
    if (this.isNettingButtonSelected) {
      if (title == '6:6' || title == '8:8') {
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (
            slot.title == '9:9' ||
            slot.title == '5:5' ||
            slot.title == '7:7'
          ) {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (
            slot.title == '9:9' ||
            slot.title == '5:5' ||
            slot.title == '7:7'
          ) {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (
            slot.title == '9:9' ||
            slot.title == '5:5' ||
            slot.title == '7:7'
          ) {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (
            slot.title == '9:9' ||
            slot.title == '5:5' ||
            slot.title == '7:7'
          ) {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      } else {
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '8:8' || slot.title == '6:6') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '8:8' || slot.title == '6:6') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '8:8' || slot.title == '6:6') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '8:8' || slot.title == '6:6') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      }
    } else {
      this.listOfPriceForMorning.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForAfternoon.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForEvening.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForNight.forEach((slot: any) => {
        slot.enabled = true;
      });
    }
  }

  addOrRemoveSize(title: any, event: any) {
    console.log(event.target.checked);
    const index = this.selectedTurfSize.findIndex(
      (turfSize: any) => turfSize == title
    );
    if (index == -1) {
      this.selectedTurfSize.push(title);
    } else {
      this.selectedTurfSize.splice(index, 1);
    }
    this.listOfTurfSize.forEach((slot: any) => {
      if (slot.title == title) {
        slot.isSelected = event.target.checked;
      }
    });
    if (this.isNettingButtonSelected) {
      if (title == '8:8') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '9:9' || slot.title == '7:7') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      } else if (title == '9:9' || title == '7:7') {
        this.listOfTurfSize.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForMorning.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForAfternoon.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForEvening.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
        this.listOfPriceForNight.forEach((slot: any) => {
          if (slot.title == '8:8') {
            slot.enabled = false;
            slot.isSelected = false;
          }
        });
      }
    } else {
      this.listOfPriceForMorning.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForAfternoon.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForEvening.forEach((slot: any) => {
        slot.enabled = true;
      });
      this.listOfPriceForNight.forEach((slot: any) => {
        slot.enabled = true;
      });
    }
    let tempMorningPrice: any = [];
    this.listOfPriceForMorningOriginal.forEach((morningSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == morningSlot['title'] && element['isSelected']) {
          tempMorningPrice.push(morningSlot);
        }
      });
    });
    this.listOfPriceForMorning = tempMorningPrice;
    let tempAfternoonPrice: any = [];
    this.listOfPriceForAfternoonOriginal.forEach((afternoonSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (
          element['title'] == afternoonSlot['title'] &&
          element['isSelected']
        ) {
          tempAfternoonPrice.push(afternoonSlot);
        }
      });
    });
    this.listOfPriceForAfternoon = tempAfternoonPrice;
    let tempEveningPrice: any = [];
    this.listOfPriceForEveningOriginal.forEach((eveningSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == eveningSlot['title'] && element['isSelected']) {
          tempEveningPrice.push(eveningSlot);
        }
      });
    });
    this.listOfPriceForEvening = tempEveningPrice;
    let tempNightPrice: any = [];
    this.listOfPriceForNightOriginal.forEach((nightSlot: any) => {
      this.listOfTurfSize.forEach((element: any) => {
        if (element['title'] == nightSlot['title'] && element['isSelected']) {
          tempNightPrice.push(nightSlot);
        }
      });
    });
    this.listOfPriceForNight = tempNightPrice;
  }

  changePricingSelectionForMorning(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForMorning.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForMorning.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForAfternoon(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForAfternoon.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForEvening(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForEvening.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForEvening.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  changePricingSelectionForNight(title: any, event: any) {
    let isSelected = event.target.checked;
    this.listOfPriceForNight.forEach((prc: any, index: number) => {
      if (prc['title'] == title) {
        prc['isSelected'] = isSelected;
      }
    });
    let isValid = false;
    this.listOfPriceForNight.forEach((val: any) => {
      if (val['isSelected']) {
        isValid = true;
      }
    });
    if (isValid) {
      this.isValidTurfPrice = true;
    }
  }
  addPriceForTurfSize(title: any, target: any, slot: any, weekend: boolean) {
    let price = target.value;
    if (slot == 'Morning') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForMorning.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForMorning.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Afternoon') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForAfternoon.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Evening') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForEvening.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForEvening.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    } else if (slot == 'Night') {
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        this.listOfPriceForNight.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      } else {
        this.listofSlotForNight.forEach((prc: any, index: number) => {
          if (prc['title'] == title && prc['isSelected']) {
            if (weekend) {
              prc['weekendPrice'] = parseInt(price);
            } else {
              prc['price'] = parseInt(price);
            }
          }
        });
      }
    }
  }

  addTimeForSlot(target: any, slot: any, startTime: boolean) {
    if (slot == 'Morning') {
      if (startTime) {
        this.morningSlotTime['startTime'] = target.value;
      } else {
        this.morningSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Afternoon') {
      if (startTime) {
        this.afternoonSlotTime['startTime'] = target.value;
      } else {
        this.afternoonSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Evening') {
      if (startTime) {
        this.eveningSlotTime['startTime'] = target.value;
      } else {
        this.eveningSlotTime['endTime'] = target.value;
      }
    } else if (slot == 'Night') {
      if (startTime) {
        this.nightSlotTime['startTime'] = target.value;
      } else {
        this.nightSlotTime['endTime'] = target.value;
      }
    }
  }

  addQuantityForTurfSize(title: any, target: any) {
    let quantity = target.value;
    if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
      this.listOfPriceForMorning.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['quantity'] = parseInt(quantity);
        }
      });
      this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['quantity'] = parseInt(quantity);
        }
      });
      this.listOfPriceForEvening.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['quantity'] = parseInt(quantity);
        }
      });
      this.listOfPriceForNight.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['quantity'] = parseInt(quantity);
        }
      });
      this.listOfTurfSize.forEach((game: any) => {
        if (game['title'] == title) {
          game['quantity'] = parseInt(quantity);
        }
      });
    } else {
      this.gameQuantity.forEach((data: any) => {
        if (data['title'] == title) {
          data['quantity'] = parseInt(quantity);
        }
      });
      console.log(this.gameQuantity);
    }
  }

  addLabelForTurfSize(title: any, target: any) {
    let label = target.value;
    if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
      this.listOfPriceForMorning.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['label'] = label;
        }
      });
      this.listOfPriceForAfternoon.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['label'] = label;
        }
      });
      this.listOfPriceForEvening.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['label'] = label;
        }
      });
      this.listOfPriceForNight.forEach((prc: any, index: number) => {
        if (prc['title'] == title && prc['isSelected']) {
          prc['label'] = label;
        }
      });
      this.listOfTurfSize.forEach((game: any) => {
        if (game['title'] == title) {
          game['label'] = label;
        }
      });
    } else {
      this.gameQuantity.forEach((data: any) => {
        if (data['title'] == title) {
          data['label'] = label;
        }
      });
      console.log(this.gameQuantity);
    }
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onItemDeSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  cancel(success: any) {
    this.closeSideForm.emit(success);
  }
  displayTurf(event: any, index: number) {
    this.selectedTurfImage[index] = event.target.files[0];
    this.selectedTurfImageName[index] = this.selectedTurfImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultTurfImage[index] = event1.target.result;
    };
    reader.readAsDataURL(this.selectedTurfImage[index]);
    let count = 0;
    this.selectedTurfImage.forEach((val: any) => {
      if (val != undefined) {
        count++;
      }
    });
    if (count >= 3) {
      this.isValidTurfImageImage = true;
    }
  }
  deleteImage(index: number) {
    this.defaultTurfImage.splice(index, 1);
    this.selectedTurfImage.splice(index, 1);
  }

  downloadImage(url: any) {
    window.open(url);
  }
  addTurf() {
    try {
      this.isLoading = true;

      if (this.dataToEdit != null) {
        this.formdata.set('turfId', this.dataToEdit['_id']);
        this.formdata.set(
          'existingImage',
          JSON.stringify(this.defaultTurfImage)
        );
      } else {
        this.formdata.set('turfId', '0');
      }
      this.formdata.set('businessId', this.addTurfForm.get('business')?.value);
      this.formdata.set(
        'isRecommended',
        this.addTurfForm.get('recommendedVenue')?.value
      );
      this.formdata.set(
        'recommendedPosition',
        this.addTurfForm.get('recommendedVenue')?.value
          ? this.addTurfForm.get('recommendedPosition')?.value
          : 'NA'
      );
      if (this.selectedTurfImage.length > 0) {
        this.selectedTurfImage.forEach((image: any, index: number) => {
          this.formdata.set(
            `turfImage${index}`,
            this.selectedTurfImage[index],
            this.selectedTurfImageName[index]
          );
        });
      }

      // Days
      this.assignedDays = [];
      this.selectedDays.forEach((day: any) => {
        this.assignedDays.push(day['dayLabel']);
      });
      this.formdata.set('days', JSON.stringify(this.assignedDays));
      // Facility
      this.selectedFacilities.forEach((facility: any) => {
        this.assignedFacilities.push(facility['facilityName']);
      });
      this.formdata.set('facilities', JSON.stringify(this.assignedFacilities));
      // Sport
      this.assignedSport = [];
      this.selectedSport.forEach((sport: any) => {
        this.assignedSport.push(sport['_id']);
      });
      this.formdata.set('sportsType', JSON.stringify(this.assignedSport));

      // Turf Price
      let priceToInsert: any = [];
      if (this.selectedSportCategory['categoryName'] == 'Outdoor') {
        if (
          this.listOfPriceForMorning.length > 0 &&
          this.isMorningSlotSelected
        ) {
          priceToInsert.push({
            session: 'Morning',
            startTime: this.morningSlotTime['startTime'],
            endTime: this.morningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Morning') {
              index = i;
            }
          });
          this.listOfPriceForMorning.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (
          this.listOfPriceForAfternoon.length > 0 &&
          this.isAfternoonSlotSelected
        ) {
          priceToInsert.push({
            session: 'Afternoon',
            startTime: this.afternoonSlotTime['startTime'],
            endTime: this.afternoonSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Afternoon') {
              index = i;
            }
          });
          this.listOfPriceForAfternoon.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (
          this.listOfPriceForEvening.length > 0 &&
          this.isEveningSlotSelected
        ) {
          priceToInsert.push({
            session: 'Evening',
            startTime: this.eveningSlotTime['startTime'],
            endTime: this.eveningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Evening') {
              index = i;
            }
          });
          this.listOfPriceForEvening.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.listOfPriceForNight.length > 0 && this.isNightSlotSelected) {
          priceToInsert.push({
            session: 'Night',
            startTime: this.nightSlotTime['startTime'],
            endTime: this.nightSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Night') {
              index = i;
            }
          });
          this.listOfPriceForNight.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
              });
            }
          });
        }

        priceToInsert.forEach((price: any) => {
          price['priceAndQuantity'].forEach((quantity: any) => {
            this.listOfTurfSize.forEach((game: any) => {
              if (game['title'] == quantity['title']) {
                quantity['quantity'] = game['quantity'];
                quantity['label'] = game['label'];
              }
            });
          });
        });
      } else {
        if (this.isMorningSlotSelected) {
          priceToInsert.push({
            session: 'Morning',
            startTime: this.morningSlotTime['startTime'],
            endTime: this.morningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Morning') {
              index = i;
            }
          });
          this.listofSlotForMorning.forEach((data: any) => {
            if (data['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: data['title'],
                price: data['price'],
                weekendPrice: data['weekendPrice'],
                quantity: data['quantity'],
                label: data['label'],
              });
            }
          });
        }
        if (this.isAfternoonSlotSelected) {
          priceToInsert.push({
            session: 'Afternoon',
            startTime: this.afternoonSlotTime['startTime'],
            endTime: this.afternoonSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Afternoon') {
              index = i;
            }
          });
          this.listofSlotForAfternoon.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.isEveningSlotSelected) {
          priceToInsert.push({
            session: 'Evening',
            startTime: this.eveningSlotTime['startTime'],
            endTime: this.eveningSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Evening') {
              index = i;
            }
          });
          this.listofSlotForEvening.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        if (this.isNightSlotSelected) {
          priceToInsert.push({
            session: 'Night',
            startTime: this.nightSlotTime['startTime'],
            endTime: this.nightSlotTime['endTime'],
            priceAndQuantity: [],
          });
          let index = 0;
          priceToInsert.forEach((element: any, i: number) => {
            if (element['session'] == 'Night') {
              index = i;
            }
          });
          this.listofSlotForNight.forEach((prc: any) => {
            if (prc['isSelected']) {
              priceToInsert[index]['priceAndQuantity'].push({
                title: prc['title'],
                price: prc['price'],
                weekendPrice: prc['weekendPrice'],
                quantity: prc['quantity'],
                label: prc['label'],
              });
            }
          });
        }
        priceToInsert.forEach((price: any) => {
          price['priceAndQuantity'].forEach((quantity: any) => {
            this.gameQuantity.forEach((game: any) => {
              if (game['title'] == quantity['title']) {
                quantity['quantity'] = game['quantity'];
                quantity['label'] = game['label'];
              }
            });
          });
        });
      }

      console.log(priceToInsert);

      this.formdata.set('slots', JSON.stringify(priceToInsert));

      this.formdata.set('turfName', this.addTurfForm.get('turfName')?.value);
      this.formdata.set('turfType', this.addTurfForm.get('turfType')?.value);
      this.formdata.set('status', this.addTurfForm.get('status')?.value);
      this.formdata.set(
        'turfContact',
        this.addTurfForm.get('turfContact')?.value
      );
      this.formdata.set(
        'streetName',
        this.addTurfForm.get('streetName')?.value
      );
      this.formdata.set(
        'sportCategory',
        this.selectedSportCategory['categoryId']
      );
      this.formdata.set('landmark', this.addTurfForm.get('landmark')?.value);
      this.formdata.set(
        'commission',
        this.addTurfForm.get('commission')?.value
      );
      this.formdata.set('pincode', this.addTurfForm.get('pincode')?.value);
      this.formdata.set('city', this.addTurfForm.get('city')?.value);
      this.formdata.set('state', this.addTurfForm.get('state')?.value);
      this.formdata.set(
        'advanceAmount',
        this.addTurfForm.get('advanceAmount')?.value
      );
      if (this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != null && this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != undefined && this.addTurfForm.get('ownerWhatsAppConfirmation')?.value != '') {
        this.formdata.set(
          'ownerWhatsAppConfirmation',
          this.addTurfForm.get('ownerWhatsAppConfirmation')?.value
        );
      }
      this.formdata.set(
        'description',
        this.addTurfForm.get('description')?.value
      );
      this.formdata.set('isNet', this.isNettingButtonSelected.toString());

      this.myHeaders.set(
        'Authorization',
        `Bearer ${this.userDetails['accessToken']}`
      );
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: this.myHeaders,
        body: this.formdata,
        redirect: 'follow',
      };
      var URL;
      if (this.dataToEdit != null) {
        URL = `${webApi.domain + webApi.endPoint.createAndEditTurf}`;
      } else {
        URL = `${webApi.domain + webApi.endPoint.createAndEditTurf}`;
      }
      try {
        fetch(URL, requestOptions)
          .then((result) => {
            if (result) {
              let data = result.json();
              data.then((res) => {
                if (res['success']) {
                  this.isLoading = false;
                  this.addTurfForm.reset();
                  this.cancel(res['result']);
                } else {
                  this.isLoading = false;
                  this.cancel('Error');
                }
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.cancel('Error');
            console.log('error', error);
          });
        return;
      } catch (error) {
        this.isLoading = false;
        this.cancel('Error');
      }
      this.isLoading = false;
      // this.isLoading=false;
    } catch (err) {
      this.cancel('Error');
      this.isLoading = false;
    }
  }
}
