<div class="container_editUser" *ngIf="!isLoading">
    <div class="container_editUser_main">
        <div class="container_editUser_title">Business Details</div>
        <div>
            <form [formGroup]="addBusinessForm">
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">First Name</label>
                        <input type="text" class="input-style"  placeholder="Enter First Name" formControlName="firstName"/>
                        <div class="error" *ngIf="submitted && f['firstName'].errors">
                            <div *ngIf="f['firstName'].errors['required']">This field is required</div>
                            <div *ngIf="f['firstName'].errors['pattern']">First Name is invalid</div>
                          </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">Last Name</label>
                        <input type="text" class="input-style"  placeholder="Enter Last Name" formControlName="lastName"/>
                        <div class="error" *ngIf="submitted && f['lastName'].errors">
                            <div *ngIf="f['lastName'].errors['required']">This field is required</div>
                            <div *ngIf="f['lastName'].errors['pattern']">Phone no. is invalid</div>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Phone</label>
                        <input type="text" class="input-style"  placeholder="Add Contact Number" formControlName="phone" maxlength="10"/>
                        <div class="error" *ngIf="submitted && f['phone'].errors">
                            <div *ngIf="f['phone'].errors['required']">This field is required</div>
                            <div *ngIf="f['phone'].errors['pattern']">Phone is invalid</div>
                          </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5">Email</label>
                        <input type="email" class="input-style" name="email" 
                        placeholder="Enter email" id="recommendedPosition" formControlName="email" />
                        <div class="error" *ngIf="submitted && f['email'].errors">
                            <div *ngIf="f['email'].errors['required']">This field is required</div>
                            <div *ngIf="f['email'].errors['pattern']">Email is invalid</div>
                          </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button *ngIf="isAdd" class="btn-1" mat-button (click)="cancelBtnHandler()">
                Cancel
            </button>
            <button *ngIf="!isAdd" class="btn-1" mat-button (click)="closeEditBtnHandler()">
                Cancel
            </button>
            <button class="btn" mat-button (click)="isAddEditBusinessLoading?null:onSubmitBusinessDetail()">
                <div *ngIf="!isAddEditBusinessLoading">
                    {{isAdd?'Save and Continue':'Save Changes'}}
                </div>
                <div *ngIf="isAddEditBusinessLoading">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
            </button>
        </div>
    </div>
</div>