import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignComponent } from './campaign.component';
import { ListCampaignComponent } from './list-campaign/list-campaign.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddEditCampaignComponent } from './add-edit-campaign/add-edit-campaign.component';
import { ChangeCampaignStatusComponent } from './change-campaign-status/change-campaign-status.component';
import { ViewCampaignStatisticComponent } from './view-campaign-statistic/view-campaign-statistic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarModule } from 'ng-sidebar';
import { ToastrModule } from 'ngx-toastr';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    CampaignComponent,
    ListCampaignComponent,AddEditCampaignComponent,ChangeCampaignStatusComponent,ViewCampaignStatisticComponent
  ],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    NgxPaginationModule,
    MatTableModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    ToastrModule.forRoot(), NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline'], // toggled buttons
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          ['link'],
        ],
      },
    }),
  ]
})
export class CampaignModule { }
