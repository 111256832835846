<div class="container_addressDetail" *ngIf="!isLoading">
    <div class="container_addressDetail_main">
        <div class="container_addressDetail_title">Venue Images</div>
        <div>
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5">Photos</label>
                        <div>
                            <label class="label required">3 Photos are mandatory</label>
                        </div>
                        <div class="row">

                            <div style="margin-left: 2rem;">
                                <label for="turfImage1" class="label" style="margin-top: 1em;">Venue Image 1</label>
                                <div class="row">
                                    <div>
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="imagePreview[0]!=null" src="{{imagePreview[0]}}" class="proofImage">
                                                <label *ngIf="imagePreview[0]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,0)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="imagePreview[0]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="imagePreview[0]==null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                <label *ngIf="imagePreview[0]!=null  && imagePreview[0]!=''"
                                                (click)="deleteImage(0)" class="imageLabelEdit">
                                                    <mat-icon class="matIcon delete">delete</mat-icon>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem;">
                                <label for="turfImage2" class="label" style="margin-top: 1em;">Venue Image 2</label>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="imagePreview[1]!=null" src="{{imagePreview[1]}}" class="proofImage">
                                                <label *ngIf="imagePreview[1]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,1)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="imagePreview[1]==null"  style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="imagePreview[1]==null"  style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                  <label *ngIf="imagePreview[1]!=null  && imagePreview[1]!=''"
                                                  (click)="deleteImage(1)" class="imageLabelEdit">
                                                      <mat-icon class="matIcon delete">delete</mat-icon>
                                                  </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="margin-left: 2rem;">
                                <label for="turfImage3" class="label" style="margin-top: 1em;">Venue Image 3</label>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="imagePreview[2]!=null" src="{{imagePreview[2]}}" class="proofImage">
                                                <!-- <label *ngIf="imagePreview[2]==null" class="imageLabel"> Upload
                                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                                </label> -->
                                                <label *ngIf="imagePreview[2]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,2)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="imagePreview[2]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="imagePreview[2]==null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                  <label *ngIf="imagePreview[2]!=null  && imagePreview[2]!=''"
                                                  (click)="deleteImage(2)" class="imageLabelEdit">
                                                      <mat-icon class="matIcon delete">delete</mat-icon>
                                                  </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem;">
                                <label for="turfImage4" class="label" style="margin-top: 1em;">Venue Image 4</label>
                                <div class="row">
                                    <div>
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="imagePreview[3]!=null" src="{{imagePreview[3]}}" class="proofImage">
                                                <label *ngIf="imagePreview[3]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,3)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="imagePreview[3]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="imagePreview[3]==null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                  <label *ngIf="imagePreview[3]!=null  && imagePreview[3]!=''"
                                                  (click)="deleteImage(3)" class="imageLabelEdit">
                                                      <mat-icon class="matIcon delete">delete</mat-icon>
                                                  </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem;">
                                <label for="turfImage5" class="label" style="margin-top: 1em;">Venue Image 5</label>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="image">
                                            <div class="proof">
                                                <img *ngIf="imagePreview[4]!=null" src="{{imagePreview[4]}}" class="proofImage">
                                                <label *ngIf="imagePreview[4]==null" class="imageLabel">
                                                    <img
                                                        src="../../../../../assets/svgIcons/upload-img.svg"
                                                        alt=""
                                                        srcset=""
                                                    />
                                                    <input (change)="displayTurf($event,4)" type="file" accept="image/*">
                                                </label>
                                                <div *ngIf="imagePreview[4]==null" style="color: #5e5e5e; font-size: 1rem; font-weight: 400; padding: 2rem 2rem 1rem 2rem;">
                                                    <span style="color: #798dff">Browse files</span>,
                                                    Supports jpeg and jpg format
                                                  </div>
                                                  <div *ngIf="imagePreview[4]==null" style="color: #acacb4; font-size: 1rem; font-weight: 400; padding: 0rem 2rem 1rem 2rem;">
                                                    Less than 2 Mb. 280 x 203 px
                                                  </div>
                                                  <label *ngIf="imagePreview[4]!=null  && imagePreview[4]!=''"
                                                  (click)="deleteImage(4)" class="imageLabelEdit">
                                                      <mat-icon class="matIcon delete">delete</mat-icon>
                                                  </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button class="btn-1" mat-button (click)="cancelBtnHandler()">
                Previous
              </button>
              <button class="btn" mat-button (click)="isAddEditAddressLoading?null:addTurfImages()">
                <div *ngIf="!isAddEditAddressLoading">
                    {{'Save'}}
                </div>
                <div *ngIf="isAddEditAddressLoading">
                  <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                  </mat-progress-spinner>
                </div>
              </button>
        </div>
    </div>
</div>