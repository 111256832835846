import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { DeleteOfferDialogComponent } from '../delete-offer-dialog/delete-offer-dialog.component';
// export interface IOffer {
//   id: number;
//   user: Object;
//   isActive: boolean;
//   name: string;
//   code: string;
//   isForNewUser: boolean;
//   description: string;
//   startDate: string;
//   endDate: string;
//   discountPercentage: number;
//   employee: any,
//   business: any,
//   createdSource: string;
//   turf: any;
//   createdAt: any;
//   bookingType: string;
// }
@Component({
  selector: 'app-list-offer',
  templateUrl: './list-offer.component.html',
  styleUrls: ['./list-offer.component.scss'],
})
export class ListOfferComponent implements OnInit {
  isAddEditCalled: boolean = false;
  isLoadingExtraData: boolean = false;
  dataToEdit: any;

  Page = 1;
  count = 50;
  limit = 250;
  skip = 0;
  searchString: string = '';

  opened: boolean = false;
  modeNum: number = 0;
  positionNum: number = 0;
  dock: boolean = false;
  closeOnClickOutside: boolean = false;
  closeOnClickBackdrop: boolean = true;
  showBackdrop: boolean = false;
  animate: boolean = true;
  trapFocus: boolean = true;
  autoFocus: boolean = true;
  keyClose: boolean = false;
  toggleSortDiv: boolean = false;
  downloadReport: boolean = false;

  listOfOffer: any = [];
  listOfOfferToDisplay: any = [];
  // listOfOfferOriginal: any = [];

  userDetails: any;
  isLoading: boolean = true;
  myHeaders = new Headers();
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this.fetchAllCoupons();
  }

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  toggleSortOption() {
    this.toggleSortDiv = !this.toggleSortDiv;
  }
 
  addNewOffer() {
    this.toggleOpened();
  }
  editOffer(offer: any) {
    let offerToEdit;
    // this.listOfOfferOriginal.forEach((ofr: any) => {
    //   if (ofr['_id'] == offer.id) {
    //     offerToEdit = ofr;
    //   }
    // });
    // this.dataToEdit = offerToEdit;
    this.dataToEdit = offer;
    this.toggleOpened();
  }

  async fetchAllCoupons() {
    this.isLoading = true;
    this.listOfOffer = [];
    this.listOfOfferToDisplay = [];
    try {
      let param = {
        limit: this.limit,searchedString: this.searchString.trim(),
        skip: this.skip,
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchAllCouponsForAdminDashboard}`;
      await this.as.fetchDetail(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfOffer = data['result'];
          this.listOfOfferToDisplay = this.listOfOffer;
          console.log(this.listOfOfferToDisplay);
          this.isLoading = false;
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  fetchExtraListData(load: boolean) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        limit: this.limit,searchedString: this.searchString.trim(),
        skip: this.skip,
      };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchAllCouponsForAdminDashboard}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.as
        .fetchDetail(param, URL, httpHeaders)
        .then((data: any) => {
          console.log(data);
          if (data['success']) {
            data['result'].forEach((corporate: any) => {
              this.listOfOffer.push(corporate);
            });
            this.listOfOfferToDisplay = [...this.listOfOffer];
          }
          this.isLoadingExtraData = false;
        });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (this.Page * this.count >= this.listOfOffer.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraListData(false);
    } else if (this.Page * this.count >= this.listOfOffer.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraListData(true);
    }
  }

  async searchOfferData() {
    this.isLoading = true;
    if (this.searchString == '') {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.fetchAllCoupons();
    } else {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      try {
        this.listOfOffer = [];
        this.listOfOfferToDisplay = this.listOfOffer;
        let param = {
          searchedString: this.searchString.trim(),
          limit: this.limit,
          skip: this.skip,
        };
        const httpHeaders = new HttpHeaders({
          Authorization: `Bearer ${this.userDetails['accessToken']}`,
        });
        let URL;
        URL = `${webApi.domain + webApi.endPoint.fetchAllCouponsForAdminDashboard}`;
        await this.as
          .fetchDetail(param, URL, httpHeaders)
          .then((data: any) => {
            if (data['success']) {
              data['result'].forEach((patient: any) => {
                this.listOfOffer.push(patient);
              });
              this.listOfOfferToDisplay = [...this.listOfOffer];
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
  searchData(target: any) {
    let serachQuery = target.value;
    if (serachQuery == '') {
      this.limit = 250;
      this.skip = 0;
      this.Page = 1;
      this.searchString = '';
      this.fetchAllCoupons();
    }
  }

  deleteOffer(offer: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { offer: offer };

    let dialogRef = this.matDialog.open(
      DeleteOfferDialogComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      if (res == null) {
        return;
      } else {
        if (res['action'] == 'Delete Offer') {
          const param = {
            couponId: res['value']['_id'],
          };
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetails['accessToken']}`,
          });
          const URL = `${webApi.domain + webApi.endPoint.deleteCoupon}`;
          await this.as
            .deleteData(param, URL, httpHeaders)
            .then((data: any) => {
              if (data['success']) {
                var i = 0;
                this.listOfOfferToDisplay.forEach(
                  (offer: any, index: number) => {
                    if (offer['_id'] == res['value']['_id']) {
                      i = index;
                    }
                  }
                );
                this.listOfOfferToDisplay.splice(i, 1);
                this.showSuccessToaster('Offer Delete Successfully', 'Success');
              } else {
                this.showErrorToaster(
                  'Error Occurred while deleteing Offer. Try again',
                  'Error'
                );
              }
            });
        } else {
          this.toggleClose(null);
        }
      }
    });
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

  MODES: Array<string> = ['over', 'push', 'slide'];
  POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];

  toggleOpened(): void {
    this.isAddEditCalled = true;
    this.opened = !this.opened;
  }
  toggleClose(event: any): void {
    this.opened = !this.opened;
    this.isAddEditCalled = false;
    this.isLoading = true;
    if (event != null) {
      if (event == 'Error') {
        if (this.dataToEdit != null) {
          this.showErrorToaster(
            'Error while editing Offer.Kindly try again',
            'Error'
          );
        } else {
          this.showErrorToaster(
            'Error while adding Offer.Kindly try again',
            'Error'
          );
        }
        this.dataToEdit = null;
        this.isLoading = false;
        return;
      }
      if (this.dataToEdit != null) {
        let isExist = false;
        this.listOfOfferToDisplay.forEach((offer: any, index: number) => {
          if (offer['id'] == event['_id']) {
            // this.listOfOffer[index] = event;
            this.listOfOfferToDisplay[index] = {
              id: event['_id'],
              user: event['user'],
              isActive: event['active'],
              name: event['name'],
              code: event['code'],
              isForNewUser: event['isForNewUser'],
              discountPercentage: event['discountPercentage'],
              description: event['description'],
              startDate: event['startDate'],
              endDate: event['endDate'],
              bookingType: event['bookingType'],
              turf: event['turf'],
              employee: event['employee'],
              business: event['business'],
              createdSource: event['createdSource'],
              createdAt: event['createdAt'],
            };
            this.listOfOfferToDisplay[index] = event;
            isExist = true;
          }
        });
        this.listOfOfferToDisplay.forEach((offer: any, index: number) => {
          if (offer['_id'] == event['_id']) {
            this.listOfOfferToDisplay[index] = event;
            isExist = true;
          }
        });
        if (!isExist) {
          // this.listOfOffer.push(event);
          this.listOfOfferToDisplay.unshift({
            id: event['_id'],
            user: event['user'],
            isForNewUser: event['isForNewUser'],
            isActive: event['active'],
            name: event['name'],
            discountPercentage: event['discountPercentage'],
            code: event['code'],
            description: event['description'],
            startDate: event['startDate'],
            endDate: event['endDate'],
            turf: event['turf'],
            employee: event['employee'],
            bookingType: event['bookingType'],
            business: event['business'],
            createdSource: event['createdSource'],
            createdAt: event['createdAt'],

          });
          // this.listOfOfferOriginal.push(event);
        }
        // this.listOfOfferToDisplay = this.listOfOffer;
        this.showSuccessToaster('Offer Edited Successfully', 'Success');
      } else {
        this.listOfOfferToDisplay.unshift({
          id: event['_id'],
          user: event['user'],
          isForNewUser: event['isForNewUser'],
          isActive: event['active'],
          name: event['name'],
          code: event['code'],
          description: event['description'],
          startDate: event['startDate'],
          discountPercentage: event['discountPercentage'],
          endDate: event['endDate'],
          turf: event['turf'],
          bookingType: event['bookingType'],
          employee: event['employee'],
          business: event['business'],
          createdSource: event['createdSource'],
          createdAt: event['createdAt'],

        });
        this.showSuccessToaster('Offer Added Successfully', 'Success');
      }
    }

    this.dataToEdit = null;
    this.isLoading = false;
  }

  onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
}
