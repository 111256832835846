<ng-sidebar-container style="height: 100vh;background-color: #f8fcff;">
    <ng-sidebar [(opened)]="_opened" mode="over" [keyClose]="false" position="left" [dock]="false" [dockedSize]="'50px'"
        [autoCollapseHeight]="500" [autoCollapseWidth]="500" [closeOnClickOutside]="false" [closeOnClickBackdrop]="true"
        [showBackdrop]="true" [animate]="true" [trapFocus]="false" [autoFocus]="true" [ariaLabel]="'Menu'">
        <app-left-sidebar (closeSideForm)="_toggleOpened()"></app-left-sidebar>
    </ng-sidebar>
    <div ng-sidebar-content>
        <div class="display-flex">
            <div class="Header main-content">
                <div class="header-wrap">
                    <div class="menu-field" (click)="_toggleOpened()">
                        <img src="../../../../assets/img/menu.png" alt="" class="navlogo" /><span
                            style="font-size: 17px;font-weight: 600;"></span>
                    </div>
                    <!-- <div class="display-flex align-items-center justify-content-center p-3">
                        <img src="../../../assets/logo/logo.png" style="height: 2em;" alt="" srcset="">
                    </div> -->
                    <!-- <div class="header-logo">
                        <img src="../../../assets/image/logo/logo.png" alt="" class="logo" />
                    </div> -->
                    <div class="header-nav text-right" *ngIf="appService.userDetail!=null">

                        <div class="profile-logo-container">
                            <app-dropdown (callBackFunction)="callBackHandler($event)" dropDownType="Menu"
                                [dropDownItem]="dropDownItem" dropDownLabel="Select Item"
                                [dropDownStyle]="dropDownStyle" [suffixStyle]="suffixStyle">
                                <div toggleContent style="display: flex;align-items: center;">
                                    <div style="width: 2.6em;">
                                        <app-avatar size="extra-small" [showStatusIndicator]="true" shape="circular"
                                            [url]="appService.userDetail['imageUrl']==null||appService.userDetail['imageUrl']==undefined||appService.userDetail['imageUrl']==''? '../../../../assets/img/avatar-1.jpg':appService.userDetail['imageUrl']">
                                        </app-avatar>
                                    </div>
                                    <span
                                        style="font-size: 12px;font-weight: 500;">{{appService.userDetail['name']==null
                                        ||appService.userDetail['name']==undefined ? '':
                                        appService.userDetail['name']}}</span> <img
                                        src="../../../../assets/img/next.png" style="height: 15px;width: 15px;" alt=""
                                        srcset="">
                                </div>
                            </app-dropdown>
                        </div>

                    </div>

                </div>
            </div>
            <div class="sidebar">
                <div class="display-flex align-items-center justify-content-center m-3">
                    <img src="../../../assets/logo/logo.png" style="height: 4em;" alt="" srcset="">
                </div>
                <div class="components">
                    <div *ngFor="let data of this.appService.sidebarOptions">

                        <div *ngIf="data!=null && data!=undefined && data['title']!='Booking'"
                            [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}">
                            <div class="div-style" [routerLink]="data['route']"
                                (click)="this.appService.gotoComponent(data['title'],'Admin')">
                                <img style="height: 1.5em;" [src]="data['svgUrl']" class="img" alt=""
                                    srcset=""><span>{{data['title']}}</span>
                            </div>
                        </div>
                        <div *ngIf="data['title']=='Booking'"
                            [ngClass]="{'selected':data['isSelected'],'unselected':!data['isSelected']}">
                            <div class="div-style" [routerLink]="data['route']"
                                style="justify-content: space-between;width: 90%;"
                                (click)="this.appService.gotoComponent(data['title'],'Admin')">
                                <div>
                                    <img style="height: 1.5em;" [src]="data['svgUrl']" class="img" alt=""
                                        srcset=""><span>{{data['title']}}</span>
                                </div>
                                <div
                                    style="font-size: 10px;background: #3199D8;border-radius: 10px;padding: 12px 15px;color: white;line-height: 0px;font-weight: 500;">
                                    New ( {{this.appService.todaysBookingCount}} )
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="logout">
                    <div class="div-style" (click)="this.appService.gotoComponent('Logout')">
                        <img style="height: 1.5em;margin-right: 10px;" src="../assets/img/logout.svg" class="img" alt=""
                            srcset=""><span>{{'Logout'}}</span>
                    </div>
                </div> -->

            </div>
        </div>
        <div class="main-content ">

            <ng-content select="[mainContent]"></ng-content>
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</ng-sidebar-container>