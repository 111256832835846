<div class="container__business">
  <div
    class="container__business__header"
    style="align-items: center; justify-content: space-between"
  >
    <div class="container__business__header__title">Business Details</div>
    <div class="clear-button" (click)="close()">
      Close<mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
  </div>

  <div class="container__business__details">
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/ion_business-outline.svg"
            alt="" /></span
        >Business Details:
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <div class="title">Business ID</div>
          <div>:</div>
          <div class="value">{{ data["business"]["_id"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Owner Name</div>
          <div>:</div>
          <div class="value">{{ data["business"]["userName"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Owner Phone</div>
          <div>:</div>
          <div class="value">{{ data["business"]["phone"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Email</div>
          <div>:</div>
          <div class="value">{{ data["business"]["email"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Registration Date</div>
          <div>:</div>
          <div class="value">{{ data["business"]["createdAt"] | date }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/ph_bank.svg"
            alt="" /></span
        >Bank Details:
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <div class="title">Holder Name</div>
          <div>:</div>
          <div class="value">
            {{ data["business"]["bankDetails"][0]?.accountHolderName }}
          </div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Account Number</div>
          <div>:</div>
          <div style="width: 18rem" class="value">
            {{ data["business"]["bankDetails"][0]?.accountNumber }}
          </div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Bank Name</div>
          <div>:</div>
          <div style="width: 18rem" class="value">
            {{ data["business"]["bankDetails"][0]?.bankName }}
          </div>
        </div>
        <div class="business-details__content__business">
          <div class="title">IFSC Code</div>
          <div>:</div>
          <div style="width: 18rem" class="value">
            {{ data["business"]["bankDetails"][0]?.ifscCode }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/basil_document-outline.svg"
            alt="" /></span
        >Documents:
        <div *ngFor="let item of _albums; let i=index" class="pancard">
            <div class="pancard__title">PAN Card Number</div>
            <div style="margin-right: 1rem;">:</div>
            <div style="font-weight: 800;" class="value">{{item['docNumber']}}</div>
        </div>
      </div>
      <div class="business-details__content">
        <div style="display: flex; gap: 3.2rem;">
            <div *ngFor="let item of _bank; let i=index">
                <div *ngIf="item['docUrl']!=''" class="business-details__content__business">
                    <div class="title">Cancelled Check Image :</div>
                </div>
                <div style="margin-top: 1rem;">
                    <img class="prod-img" [src]="item.thumb" (click)="openPopup(_bank,i)" />
                </div>
            </div>
            <div *ngFor="let item of _albums; let i=index">
                <div *ngIf="item['docUrl']!=''" class="business-details__content__business">
                    <div class="title">PAN Card Image :</div>
                </div>
                <div style="margin-top: 1rem;">
                    <img class="prod-img" [src]="item.thumb" (click)="openPopup(_albums,i)" />
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__content">
        <div class="business-details__content__business">
            <div class="row" *ngIf="data['business']['status']!='PENDING'">
                <div class="col-md-12">
                    <div style="display: contents;" *ngIf="data['business']['status']=='APPROVED'">
                        <img src="../../../../../assets/svgIcons/mdi_approve.svg" alt="">
                    </div>
        
                    <div style="display: contents;" *ngIf="data['business']['status']!='PENDING'"
                        [ngClass]="{'rejected-order':data['business']['status']=='REJECTED','accepted-order':data['business']['status']=='APPROVED' }">
                        {{data['business']['status']=='REJECTED'?'Rejected':(data['business']['status']=='APPROVED'? 'Approved'
                        :'Pending')}}
                    </div>
        
                </div>
            </div>
            <div class="row" *ngIf="data['business']['status']=='PENDING'">
        
                <div class="col-md-12" style="display: flex;align-items: center;">
        
                    <input type="radio" name="radio-btn" id="Approve" name="Approve" [checked]="isAcceptButtonSelected"
                        (change)="radioChangeHandler($event)">
                    <label for="Approve" class="lbl-radio">Approve</label>
                    <input type="radio" name="radio-btn" id="Reject" name="Reject" [checked]="isRejectButtonSelected"
                        (change)="radioChangeHandler($event)">
                    <label for="Reject" class="lbl-radio">Reject</label>
                </div>
            </div>
        
            <div *ngIf="isAcceptButtonSelected">
                <div>
                    <div style="width: 25em;font-size: medium;margin-top: 1em;" mat-dialog-content>Are you sure you want to
                        approve this Business?</div>
        
                </div>
                <div mat-dialog-actions>
                    <button class="canel-btn" mat-dialog-close (click)="close()">No</button>
                    <button class="ok-btn" (click)="acceptBusiness()">Yes</button>
                </div>
            </div>
            <div *ngIf="isRejectButtonSelected">
                <div>
                    <label for="remark" class="label required">Remark</label>
                    <input type="text" id="remark" class="input-style" placeholder="Enter remark" [(ngModel)]="remark"
                        [ngModelOptions]="{standalone: true}">
                </div>
                <div mat-dialog-actions>
                    <button class="no-btn" mat-dialog-close (click)="close()">No</button>
                    <button class="yes-btn" [disabled]="remark==null" (click)="remark==null? null:rejectBusiness()">Yes</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>