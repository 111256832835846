import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-box-report-list',
  templateUrl: './box-report-list.component.html',
  styleUrls: ['./box-report-list.component.scss'],
})
export class BoxReportListComponent implements OnInit {
  isLoading: boolean = true;
  listOfBusiness: any = [];
  listOfTurf: any = [];
  listOfBox: any = [
    {
      label: 'User Registration',
      route: '/admin/reports/registered-user',
      routeId: 'registered-user',
      img: '../../../../../assets/img/RegisteredUser.png',
    },
    {
      label: 'User Last Active',
      route: '/admin/reports/last-active-user',
      routeId: 'last-active-user',
      img: '../../../../../assets/img/UserLastActive.png',
    },
    {
      label: 'Booking Report',
      route: '/admin/reports/booking-report',
      routeId: 'booking-report',
      img: '../../../../../assets/img/collection.svg',
    },
  ];
  isOptionSelected: any;
  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.isOptionSelected = null;

    console.log('Box list Report');
  }
  userDetails: any;
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    this.listOfBox = [
      // {
      //   label: 'User Registration',
      //   route: '/admin/reports/registered-user',
      //   routeId: 'registered-user',
      //   img: '../../../../../assets/img/RegisteredUser.png',
      // },
      // {
      //   label: 'User Last Active',
      //   route: '/admin/reports/last-active-user',
      //   routeId: 'last-active-user',
      //   img: '../../../../../assets/img/UserLastActive.png',
      // },
      {
        label: 'Booking Report',
        route: '/admin/reports/booking-report',
        routeId: 'booking-report',
        img: '../../../../../assets/img/collection.svg',
      },
    ];
    this.isLoading = false;
    this.fetchAllBusinessForReport();
  }
  async fetchAllBusinessForReport() {
    this.isLoading = true;
    this.listOfBusiness = [];
    let param = {};
    try {
      const URL = `${webApi.domain + webApi.endPoint.fetchAllBusinessForReport}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfBusiness = data['result'];
          this.listOfBusiness.forEach((val: any) => {
            val['nameToShow'] = val['user']['firstName'] + ' ' + val['user']['lastName'];
          });
          console.log(this.listOfBusiness);
        } else {
          this.listOfBusiness = [];
        }
        this.fetchAllTurfForReport();
      });
    } catch (error) {
      this.listOfBusiness = [];
      this.fetchAllTurfForReport();
    }
  }
  async fetchAllTurfForReport() {
    this.isLoading = true;
    this.listOfTurf = [];
    let param = {};
    try {
      const URL = `${webApi.domain + webApi.endPoint.fetchAllTurfForReport}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.as.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfTurf = data['result'];
          console.log(this.listOfTurf);
        } else {
          this.listOfTurf = [];
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.listOfTurf = [];
      this.isLoading = false;
    }
  }
  closeTab() {
    this.isOptionSelected = null;
    this.as.typeOfReport = null;
  }
  navigateToRoute(route: string) {
    let routeId;
    this.listOfBox.forEach((box: any) => {
      if (box['route'] == route) {
        this.isOptionSelected = box['label'];
        routeId = box['routeId'];
        this.as.typeOfReport = box['label'];
      }
    });
    // this.location.go(route);
    this.as.listOfBusinessForReport = this.listOfBusiness;
    this.as.listOfTurfForReport = this.listOfTurf;
    this.router.navigate([route]);
  }
}
