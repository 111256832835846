import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from 'src/app/api';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-business-detail',
  templateUrl: './add-business-detail.component.html',
  styleUrls: ['./add-business-detail.component.scss'],
})
export class AddBusinessDetailComponent implements OnInit {
  @Input() isAdd: boolean = false;
  @Output() backToPreviousStepper: EventEmitter<any> = new EventEmitter();
  @Output() closeEdit: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  userDetails: any;
  businessId: any;
  businessInfo: any;
  userId: any;
  formdata = new FormData();
  isAddEditBusinessLoading: boolean = false;
  submitted: boolean = false;

  myHeaders = new Headers();

  addBusinessForm!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this._activatedRoute.queryParams.subscribe((params) => {
      this.businessId = params['id'];
      console.log('edit id', this.businessId)
    });
    console.log(this.businessId);

    this.addBusinessForm = this._formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    });
  }

  ngOnInit(): void {
    if(this.businessId){
      this.fetchBusinessByIdv2();
    }
  }

  fetchBusinessByIdv2() {
    this.isLoading = true;
    try {
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchBusinessByIdv2 + `/${this.businessId}`}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.businessInfo = data.result;
          console.log(this.businessInfo);
          if (this.businessInfo && this.businessInfo.user && this.businessInfo.user._id) {
            this.userId = this.businessInfo.user._id;
            console.log(this.userId);
          this.addBusinessForm
            .get('firstName')
            ?.setValue(this.businessInfo['user']['firstName']);
          this.addBusinessForm.get('lastName')?.setValue(this.businessInfo['user']['lastName']);
          this.addBusinessForm.get('email')?.setValue(this.businessInfo['user']['email']);
          this.addBusinessForm.get('phone')?.setValue(this.businessInfo['user']['phone']);
        } else {
          console.error('User or user._id is undefined or null');
        }
        }
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.log(err);
    }
  }

  get f() {
    return this.addBusinessForm.controls;
  }

  validateAboutVenueStep(): boolean {
    const aboutVenueFormValid = this.addBusinessForm.valid;

    const anyFieldEmpty = Object.values(this.addBusinessForm.value).some(value => value === '');

    if (anyFieldEmpty) {
      this.showWarningToaster('Please fill in all required fields in the About Venue', 'Warning');
    }
    if (!aboutVenueFormValid) {
      this.submitted = true;
      // this.showWarningToaster('Please fill valid details in the About Venue', 'Warning');
    }

    return aboutVenueFormValid && !anyFieldEmpty;
  }

  onSubmitBusinessDetail() {

    if (!this.validateAboutVenueStep()) {
      this.isLoading = false;
      this.submitted = true;
      return;
    }
    this.isAddEditBusinessLoading = true

    console.log(this.businessId);

    if(this.businessId){
      this.formdata.set('businessId', this.businessId);
      this.formdata.set('userId', this.userId);
    }else{
      this.formdata.append('businessId', '0');
    }


    this.formdata.set(
      'firstName',
      this.addBusinessForm.get('firstName')?.value
    );
    this.formdata.set(
      'lastName',
      this.addBusinessForm.get('lastName')?.value
    );
    this.formdata.set('email', this.addBusinessForm.get('email')?.value);
    this.formdata.set('phone', this.addBusinessForm.get('phone')?.value);

    var requestOptions: RequestInit = {
      method: 'POST',
      body: this.formdata,
      redirect: 'follow',
      headers: new Headers({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      }),
    };
    var URL;
      URL = `${webApi.domain + webApi.endPoint.createAndEditUserAndBusinessDetailsV2}`;
    

    try {
      fetch(URL, requestOptions).then((result) => {
        if (result) {
          let data = result.json();
          data.then((res) => {
            if (res['success']) {
              this.isAddEditBusinessLoading = false;
              if (this.businessId != null) {
                this.showSuccessToaster('Business edited successfully', 'Success');
              } else {
                this.showSuccessToaster('Business added successfully', 'Success');
              }
              this.closeEdit.emit({ businessId: res['result']['_id'] });
            } else {
              this.isAddEditBusinessLoading = false;
              if (this.businessId != null) {
                this.showErrorToaster(res['message'], 'Warning');
              } else {
                this.showErrorToaster(res['message'], 'Warning');
              }
              window.history.back();
            }
          });
        }
      });
    }
    catch (error) {
      this.isAddEditBusinessLoading = false;
      if (this.businessId) {
        this.showWarningToaster('Unable to edit Business details', 'Warning');
      } else {
        this.showWarningToaster('Unable to add Business', 'Warning');
      }
    } 
  }

  cancelBtnHandler() {
    this.backToPreviousStepper.emit();
  }

  closeEditBtnHandler() {
    this.closeEdit.emit();
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
}
