import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-add-edit-view-convenience-fee',
  templateUrl: './add-edit-view-convenience-fee.component.html',
  styleUrls: ['./add-edit-view-convenience-fee.component.scss']
})
export class AddEditViewConvenienceFeeComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  listOfPayment: any = [{ title: 'Full' }, { title: 'Advance' }]
  selectedPayment: any = []

  selectedPaymentOption: any = [];

  listOfSport: any = [];
  isLoading: boolean = true;
  userDetails: any;

  convenienceOption: any;
  fullPayment: number = 0;
  advancePayment: number = 0;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'title',
    textField: 'title',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
  };

  constructor(
    public dialogRef: MatDialogRef<AddEditViewConvenienceFeeComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }



  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this.data['turf']['sports'].forEach((sport: any) => {
      this.listOfSport.push({
        'sport': sport['sport'],
        'id': sport['_id'],
        'fullPayment': 0,
        'advancePayment': 0,
        'fullPayment1v1': 0,
        'advancePayment1v1': 0,
      })
    });

    if (this.data['turf']['convenience'] != null && this.data['turf']['convenience'] != undefined) {

      let convenience: any = this.data['turf']['convenience'];

      convenience['paymentOption'].forEach((data: any) => {
        this.selectedPayment.push({ title: data });
      });

      this.convenienceOption = convenience['sameForAll'] ? 'Same' : 'Different';

      if (convenience['sameForAll']) {
        this.advancePayment = convenience['advancePayment'];
        this.fullPayment = convenience['fullPayment'];
      } else {

        convenience['charges'].forEach((data: any) => {
          var index = this.listOfSport.findIndex((sport: any) => sport['id'] == data['id']);

          if (index != -1) {
            this.listOfSport[index]['fullPayment'] = data['fullPayment'];
            this.listOfSport[index]['fullPayment1v1'] = data['fullPayment1v1'];
            this.listOfSport[index]['advancePayment'] = data['advancePayment'];
            this.listOfSport[index]['advancePayment1v1'] = data['advancePayment1v1'];
          }
        });
      }

    }
  }

  showOption(title: string) {
    let index = this.selectedPayment.findIndex((data: any) => data['title'] == title)
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }

  close(success: any) {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(success);
  }

  selectConvenienceOption(event: any) {
    this.convenienceOption = event.value;
    console.log(this.convenienceOption);
  }


  addConvenienceFee() {
    this.isLoading = true;

    var formdata = new FormData();

    let paymentOption: any = []

    this.selectedPayment.forEach((data: any) => {
      paymentOption.push(data['title']);
    });

    formdata.append('turfId', this.data['turf']['_id']);

    formdata.append('paymentOption', JSON.stringify(paymentOption));

    formdata.append('sameForAll', this.convenienceOption == 'Same' ? 'true' : 'false');

    formdata.append('fullPayment', this.fullPayment.toString());

    formdata.append('advancePayment', this.advancePayment.toString());

    formdata.append('charges', this.convenienceOption == 'Same' ? JSON.stringify([]) : JSON.stringify(this.listOfSport));


    var headers = new Headers();

    headers.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'PUT',
      headers: headers,
      body: formdata,
      redirect: 'follow',
    };
    const URL = `${webApi.domain + webApi.endPoint.addEditConvenienceFee}`;
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.showSuccessToaster('Convenience fee updated successfully.', 'Success');
                this.close(res['result']);
              } else {
                this.isLoading = false;
                this.showErrorToaster('Error Occured while updating convenience fee', 'Error');
                this.close(null);
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.close(null);
          this.showErrorToaster('Error Occured while updating convenience fee', 'Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.showErrorToaster('Error Occured while updating convenience fee', 'Error');
      console.log('error', error);
      this.close(null);

    }
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }


}
