<div *ngIf="isLoading">
    <div class="center-loading">
        <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
        </mat-progress-spinner>
    </div>
</div>
<div class="container_addressDetail" *ngIf="!isLoading">
    <div class="container_addressDetail_main">
        <div class="container_addressDetail_title">Opened On Days</div>
        <div>
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Days</label>

                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Days'"
                            [ngModelOptions]="{standalone: true}" [settings]="daysDropdownSettings" [data]="listOfDays"
                            [(ngModel)]="appService.selectedDays" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="col-md-6">
                        <label for="selectedSportCategory" class="label mt-5 required">Select Sports Category</label>
                        <select id="selectedSportCategory" name="selectedSportCategory" class="input-style" [(ngModel)]="appService.selectedSportCategory.categoryId"  (change)="radioHandlerSportCategory($event)" [ngModelOptions]="{standalone: true}">
                            <option value="Select Sport Category" disabled>Select Sport Category</option>
                            <option *ngFor="let data of sportCategory" name="selectedSportCategory" value="{{data.id}}">{{data['categoryName']}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Available Sports</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Sport'"
                         [settings]="sportsTypeDropdownSettings" [data]="getSports()"
                        [(ngModel)]="selectedSport" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)" [ngModelOptions]="{standalone: true}" (click)="toggleDropdown($event)">
                    </ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="row" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
                    <div class="col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Select Netting Option:-
                    </div>
                </div>
                <div class="row" style="margin-bottom: 1rem;" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
                    <div class="col-md-12" style="display: flex;align-items: center;">
        
                        <div style="margin-right: 3rem;">
                            <input type="radio" name="radio-btn" id="Netting" name="Netting" value="Netting"
                                [checked]="isNettingButtonSelected" (change)="radioChangeHandler($event)">
                            <label for="Netting" class="lbl-radio" style="margin-left: 1rem;">Turf with Division</label>
                        </div>
                        <div>
                            <input type="radio" name="radio-btn" id="Non Netting" name="Non Netting" value="Non Netting"
                                [checked]="isNonNettingButtonSelected" (change)="radioChangeHandler($event)">
                            <label for="Non Netting" class="lbl-radio" style="margin-left: 1rem;">Turf without Division</label>
        
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
                    <div class="col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Select Venue title:-
                    </div>
                </div>
                <div *ngIf="!isChangingNetToNonNetting && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-6" *ngFor="let data of listOfTurfSize;">
                        <div>
                            <input type="checkbox" [checked]="data['isSelected']"
                                (click)="addOrRemoveSize(data['title'],$event)" [value]="data['title']"
                                >
                            <label style=" width: 3em;margin-left: 1em; margin-right: 1em;" for="">{{data['title']}}</label>
                        </div>
                    </div>
                </div>

                <div class="dashedLine"></div>

                <div class="row">
                    <div class="col-md-12 venue-timing required"  style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                        Venue Timing & Pricing:-
                    </div>
                </div>
                <div style="display: flex;">
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em;">
                        <input type="checkbox" [checked]="isMorningSlotSelected"
                            (change)="changeSlotSelection('Morning',$event)">
                        <label class="checkbox-label" style="margin-left: 1em;">Morning Slot</label>
                    </div>
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isAfternoonSlotSelected"
                            (change)="changeSlotSelection('Afternoon',$event)"> 
                        <label class="checkbox-label" style="margin-left: 1em;">Afternoon Slot</label>
                    </div>    
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isEveningSlotSelected"
                            (change)="changeSlotSelection('Evening',$event)"> 
                        <label class="checkbox-label" style="margin-left: 1em;">Evening Slot</label>
                    </div>        
                    <div style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; margin-left: 1rem;">
                        <input type="checkbox" [checked]="isNightSlotSelected" (change)="changeSlotSelection('Night',$event)">
                        <label class="checkbox-label" style="margin-left: 1em;">Night Slot</label>
                    </div>        
                </div>
                <div *ngIf="isMorningSlotSelected && selectedSportCategory && selectedSportCategory['categoryName'] =='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listOfPriceForMorning;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']"
                                (change)="changePricingSelectionForMorning(data['title'],$event)" style="margin-bottom: 0.5em;">
                            <label class="type-checkbox" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" min="0" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;" min="0" maxlength="5"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isMorningSlotSelected && selectedSportCategory && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listofSlotForMorning;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForMorning(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter weekdays price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter weekend price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Morning',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForAfternoon;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                            <label class="type-checkbox" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isAfternoonSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listofSlotForAfternoon;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForAfternoon(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Afternoon',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">

                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForEvening;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForEvening(data['title'],$event)">
                            <label class="type-checkbox" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isEveningSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">

                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listofSlotForEvening;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForEvening(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%; margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Evening',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']=='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekend price</div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngFor="let data of listOfPriceForNight;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForNight(data['title'],$event)">
                            <label class="type-checkbox" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                        </div>
                    </div>
                </div>
                <div *ngIf="isNightSlotSelected && selectedSportCategory['categoryName']!='Outdoor'" class="row"
                    style="width: 38em;">
                    <div class="col-md-12" style="margin-bottom: 1em;">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div *ngIf="checkForWeekday()" class="col-md-3" style="text-align: center;margin-left:1.2em;">
                                Weekday price</div>
                            <div *ngIf="checkForWeekend()" class="col-md-3" style="text-align: center;">Weekend price</div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngFor="let data of listofSlotForNight;">
                        <div style="display: flex; margin-bottom: 1rem;">
                            <input type="checkbox" [checked]="data['isSelected']" style="margin-bottom: 0.5em;"
                                (change)="changePricingSelectionForNight(data['title'],$event)">
                            <label style=" width: 6em;margin-left: 1em; margin-right: 2rem;" for="">{{data['title']}}</label>
                            <input *ngIf="checkForWeekday()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['price']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',false)">
                            <input *ngIf="checkForWeekend()" type="number" placeholder="Enter price"
                                [disabled]="!data['isSelected']" class="input-style" [value]="data['weekendPrice']"
                                style="width: 25%;margin-right: 1rem;" maxlength="5" min="0"
                                (input)="addPriceForTurfSize(data['title'],$event.target,'Night',true)">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isMorningSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Morning Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label  class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="morningSlotTime['startTime']" max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Morning',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="morningSlotTime['endTime']" max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Morning',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isAfternoonSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Afternoon Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label  class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="afternoonSlotTime['startTime']" max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Afternoon',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="afternoonSlotTime['endTime']"max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Afternoon',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isEveningSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Evening Slot Time :-
                    </div>
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                    <input type="number" placeholder="Start Time" class="input-style"
                                        [value]="eveningSlotTime['startTime']" max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Evening',true)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                    <input type="number" placeholder="End Time" class="input-style"
                                        [value]="eveningSlotTime['endTime']" max="24" min="0"
                                        (input)="addTimeForSlot($event.target,'Evening',false)">
                                </div>
                            </div>
                        </div>
                </div>
                <div class="row" *ngIf="isNightSlotSelected">
                    <div class=" col-md-12 slot-time" style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Night Slot Time :-
                    </div>
                            <div class="col-md-12" style="display: flex;">
                                <div class="col-md-6">
                                    <div>
                                        <label class="time" style=" width: 6em; margin-right: 1em;" for="">Start Time</label>
                                        <input type="number" placeholder="Start Time" class="input-style"
                                            [value]="nightSlotTime['startTime']" max="24" min="0"
                                            (input)="addTimeForSlot($event.target,'Night',true)">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label class="time" style=" width: 6em; margin-right: 1em;" for="">End Time</label>
                                        <input type="number" placeholder="End Time" class="input-style"
                                            [value]="nightSlotTime['endTime']" max="24" min="0"
                                            (input)="addTimeForSlot($event.target,'Night',false)">
                                    </div>
                                </div>
                            </div>
                </div>

                <div class="row">
                    <div *ngIf="selectedSportCategory['categoryName']=='Outdoor'" class="col-md-12 slot-time"
                        style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Venue {{isNettingButtonSelected? 'Quantity':'Quantity'}} & Label:-
                    </div>
                    <div *ngIf="selectedSportCategory['categoryName']!='Outdoor'" class="col-md-12 slot-time"
                        style=" margin-bottom: 0.6em; font-weight: 700; margin-top: 1em;">
                        Game Quantity & Label:-
                    </div>
                </div>
                <div *ngIf="selectedSportCategory['categoryName']=='Outdoor'">
                    <div   *ngFor="let data of listOfTurfSize;" style="display: flex !important; margin-bottom: 1.5rem;">
                        <div class="col-md-3">
                            <div *ngIf="data['isSelected']" style="display: flex;">
                                <label style=" width: 3em;margin-left: 1em; margin-right: 1em; margin-top: 3rem;" for="">{{data['title']}}</label>
                                <div>
                                    <label style=" width: 3em;margin-left: 1em; margin-right: 1em; font-size: 1rem;" for="" class="time">Quantity</label>
                                    <input type="number" placeholder="Enter number" class="input-style" [value]="data['quantity']"
                                        style="width: 82%;" min="0" 
                                        (input)="addQuantityForTurfSize(data['title'],$event.target)">
                                    </div>
        
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div *ngIf="data['isSelected']" class="submiss" style="display: grid; grid-template-columns: 10rem 10rem 10rem 10rem;">
                                
                                <div *ngFor="let labelInput of getLabelInputs(data)" style="margin-right: 2rem;">
                                    <label style=" width: 3em;margin-left: 1em; margin-right: 1em;font-size: 1rem; " for="" class="time">Label</label>
                                    <input type="text" placeholder="Enter label" class="input-style" [value]="labelInput"
                                        style="width: 8rem;" (change)="addLabelForTurfSize(data['title'],$event.target)">
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedSportCategory['categoryName']!='Outdoor'">
                    <div class="row" *ngFor="let data of gameQuantity;" style="display: flex !important; margin-bottom: 1.5rem;">
                        <div class="col-md-4" style="margin-bottom: 2rem;">
                            <div *ngIf="data['isSelected']" style="display: flex;">
                                <label style=" width: 6em;margin-left: 1em; margin-right: 2rem; margin-top: 3rem;"
                                    for="">{{data['title']}}</label>
                                    <div>
                                        <label style=" width: 3em;margin-left: 1em; margin-right: 1em; font-size: 1rem;" for="" class="time">Quantity</label>
                                        <input type="number" placeholder="Enter number" class="input-style" [value]="data['quantity']"
                                            style="width: 82%;" min="0" 
                                            (input)="addQuantityForTurfSize(data['title'],$event.target)">
                                        </div>
                            </div>
        
                        </div>
                        <div class="col-md-8">
                            <div *ngIf="data['isSelected']" class="submiss" style="display: grid; grid-template-columns: 10rem 10rem 10rem 10rem;">
                                <div *ngFor="let labelInput of getLabelInputs(data)" style="margin-right: 2rem;">
                                    <label style=" width: 3em;margin-left: 1em; margin-right: 1em;font-size: 1rem; " for="" class="time">Label</label>
                                    <input type="text" placeholder="Enter label" class="input-style" [value]="labelInput"
                                        style="width: 8rem;" (change)="addLabelForTurfSize(data['title'],$event.target)">
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dashedLine"></div>

                <div class="row">
                    <div class="col-md-12 venue-timing" style=" margin-bottom: 0.6em; font-weight: 700;margin-top: 1em; ">
                        Venue Facility:-
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label for="facility" class="label required">Facility</label>
                        <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Facility'"
                            [ngModelOptions]="{standalone: true}" [settings]="facilitiesDropdownSettings"
                            [data]="listOfFacilities" [(ngModel)]="appService.selectedFacilities" (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                


            </form>
        </div>
        <div class="buttons">
            <button class="btn-1" mat-button (click)="cancelBtnHandler()">
                Previous
              </button>
              <button class="btn" mat-button (click)="isAddEditSlotLoading?null:addTurfSlots()">
                <div *ngIf="!isAddEditSlotLoading">
                  {{'Save and Continue'}}
                </div>
                <div *ngIf="isAddEditSlotLoading">
                  <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                  </mat-progress-spinner>
                </div>
              </button>
        </div>
    </div>
</div>