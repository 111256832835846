<div style="width: 35em;">
    <h1 mat-dialog-title style="font-weight: 500;">{{data['type']}} Report</h1>

    <div>
        <form name="generateReportForm" [formGroup]="generateReportForm">
            <label for="duration" class="label required">Select Duration</label>
            <select id="duration" name="duration" class="input-style" formControlName="duration"
                (change)="onDurationSelectionChange($event)">
                <option value="Select Duration" selected disabled>Select Duration</option>
                <option *ngFor="let duration of listOfDuration" [value]="duration['days']">
                    {{duration['label']}}</option>
            </select>



            <label for="type" class="label required">File Name</label>
            <input type="text" placeholder="Enter File Name" id="fileName" formControlName="fileName"
                class="input-style">

            <label for="type" class="label required">Start Date</label>
            <input type="date" placeholder="Start Date" id="startDate" formControlName="startDate" [max]="maxStartDate"
                class="input-style" (input)="startDateChange($event)">

            <label for="type" class="label required">End Date</label>
            <input type="date" placeholder="End Date" id="endDate" [min]="minEndDate" formControlName="endDate"
                class="input-style" (input)="endDateChange($event)">


            <label for="business" class="label">Select Business</label>
            <select name="business" id="business" class="input-style" (change)="onBusinessSelectionChange($event)"  formControlName="business">
                <option value="Select Business" selected disabled>Select Business</option>
                <option value="All">All</option>
                <option [value]="busniess['_id']" *ngFor="let busniess of listOfBusiness">{{busniess['nameToShow']}}
                </option>
            </select>

            <label class="label" >Select Turf</label>
            <ng-multiselect-dropdown style="width: 100%;" [placeholder]="'Select Turf'"
                [ngModelOptions]="{standalone: true}" [settings]="turfdropdownSettings" [data]="listOfTurfToDisplay"
                [(ngModel)]="selectedTurf" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>


        </form>
    </div>
    <div mat-dialog-actions>
        <button class="cancel-btn" (click)="isLoading?null:close()">Cancel</button>

        <button *ngIf="isLoading"
            [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }"
            [disabled]="generateReportForm.invalid ">
            <mat-progress-spinner mode="indeterminate" diameter="20" style="margin:0 auto;">
            </mat-progress-spinner>
        </button>

        <button *ngIf="!isLoading && (data['type'] == 'Services')"
            [ngClass]="{'ok-btn-disabled':generateReportForm.invalid || generateReportForm.get('status')!.value=='Select Status', 'ok-btn-enabled':generateReportForm.valid  && generateReportForm.get('status')!.value!='Select Status'}"
            [disabled]="generateReportForm.invalid || generateReportForm.get('status')!.value=='Select Status' "
            (click)=" generateReport()">Save</button>
        <button *ngIf="!isLoading && (data['type'] != 'Services')"
            [ngClass]="{'ok-btn-disabled':generateReportForm.invalid ,'ok-btn-enabled':generateReportForm.valid }"
            [disabled]="generateReportForm.invalid " (click)=" generateReport()">Save</button>

    </div>
</div>