import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BoxReportListComponent } from './box-report-list/box-report-list.component';
import { FileListComponent } from './file-list/file-list.component';

const routes: Routes = [
  {
    path: '',
    component: BoxReportListComponent,
  },
  {
    path: 'registered-user',
    component: FileListComponent,
  },
  {
    path: 'last-active-user',
    component: FileListComponent,
  },
  {
    path: 'booking-report',
    component: FileListComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: 'admin/reports' },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule { }
