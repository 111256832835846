import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { webApi } from 'src/app/api';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required]),
    userRole: new FormControl('', [Validators.required]),
  });

  userDetail: any;
  isAdmin: boolean = true;
  isValidUser: boolean = false;
  isContinueClicked: boolean = false;
  selectedRole: any;
  windowRef: any;

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.windowRef = this.as.windowRef;
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response: any) {
          console.log(response);
        },
        'expired-callback': function () {
          // Reset reCAPTCHA?
        },
      }
    );
    this.windowRef.recaptchaVerifier.render();
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.userDetail != null) {
      this.as.gotoDefault(this.userDetail);
    }
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  async checkForValidUser() {
    this.isContinueClicked = true;
    if (this.loginForm.invalid) {
      this.isContinueClicked = false;
      return false;
    }
    let param;
    let URL;
    if (this.isAdmin) {
      param = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value,
      };
      URL = `${webApi.domain + webApi.endPoint.isValidEmployeeWithPassword}`;
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['success']) {
          this.isValidUser = true;

          localStorage.setItem(
            'BYS-ADMIN-WEB',
            JSON.stringify({
              accessToken: data['accessToken'],
              userId: data['result']['_id'],
              name: data['result']['fullName'],
              phone: data['result']['phone'],
              imageUrl: data['result']['imageUrl'],
              email: data['result']['email'],
              role: data['result']['employeeType'],
              accessControlList:
                data['result']['accessControlList'],
            })
          );
          this.isContinueClicked = false;
          this.as.updatdUserDetailFromLocalStorage();

          this.as.setSideBarState('/admin/dashboard'.split('/'));

        } else {
          this.isValidUser = false;
          this.isContinueClicked = false;

          this.showErrorToaster(
            'Invalid Email id or password. Kindly check and try again',
            'Invalid User'
          );
        }
      });
    }

    return;
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  async gotoConfirmOtp() {
    if (this.loginForm.invalid) {
      this.isContinueClicked = false;

      return false;
    }
    const appVerifier = this.windowRef.recaptchaVerifier;
    const phoneNumberString =
      '+91' + String(this.loginForm.get('mobileNo')!.value);
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumberString, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
        this.isContinueClicked = false;

        this.router.navigate(['auth/verify-otp'], {
          queryParams: {
            mobileNo: this.loginForm.get('mobileNo')!.value,
            role: this.selectedRole,
          },
        });
      })
      .catch((error) => console.log(error));

    return;
  }
}
