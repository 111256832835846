import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { ChangeCampaignStatusComponent } from '../change-campaign-status/change-campaign-status.component';

@Component({
  selector: 'app-list-campaign',
  templateUrl: './list-campaign.component.html',
  styleUrls: ['./list-campaign.component.scss'],
})
export class ListCampaignComponent implements OnInit {

  Page = 1;
  count=50;
  isLoadingExtraData: boolean = false;
  limit = 100;
  skip = 0;
  isLoading: boolean = true;
  userDetail: any;

  listOfCampaign: any = [];
  listOfCampaignToDisplay: any = [];
  constructor(public routes: ActivatedRoute,
    private router: Router, private matDialog: MatDialog, private toastr: ToastrService,
    public appService: AppService) {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }

  ngOnInit(): void {
    this.fetchAllCampaign();
    // this.isLoading = false;
  }
  async fetchAllCampaign() {
    this.isLoading = true;
    this.listOfCampaign = [];
    this.listOfCampaignToDisplay = this.listOfCampaign;
    let param = {
      limit: this.limit,
      skip: this.skip,
    };
    try {
      let URL = `${webApi.domain + webApi.endPoint.fetchCampaign}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCampaign = data['result'];
          this.listOfCampaignToDisplay = this.listOfCampaign;
          this.isLoading = false;
        } else {
          this.listOfCampaign = [];
          this.listOfCampaignToDisplay = this.listOfCampaign;
        } this.isLoading = false;

      });
    } catch (error) {
      this.listOfCampaign = [];
      this.listOfCampaignToDisplay = this.listOfCampaign;
      this.isLoading = false;

    }
  }
  fetchExtraListData(load: boolean,) {
    this.isLoadingExtraData = load;
    try {
      let param = {
        limit: this.limit,
        skip: this.skip,
      };
      let URL = '';
      URL = `${webApi.domain + webApi.endPoint.fetchCampaign}`;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetail['accessToken']}`,
      });
      this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        console.log(data);
        if (data['success']) {
          data['result'].forEach((complaint: any) => {
            this.listOfCampaign.push(complaint);
          });
          this.listOfCampaignToDisplay = this.listOfCampaign;
        }
        this.isLoadingExtraData = false;
      });
    } catch (err) {
      this.isLoadingExtraData = false;
      console.log(err);
    }
  }
  getDate(campaignDateTime: any) {
    return new Date(campaignDateTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  }
  getOnlyTime(time: any) {
    let times = String(time).split('.');
    if (times.length == 1) {
      times.push('0');
    }
    return new Date(new Date(new Date().setHours(parseInt(times[0]), parseInt(String(times[1]).length > 1 ? times[1] : times[1] + '0'), 0, 0)).toISOString());
  }
  serachCampaign(target: any) {
    console.log(target.value);
    let serachQuery = target.value;
    if (serachQuery != '') {
      let tempSearchList: any = [];
      this.listOfCampaignToDisplay = [];
      this.listOfCampaign.forEach((campaign: any, index: number) => {
        if (
          String(campaign['fileName'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(campaign['name'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase()) ||
          String(campaign['status'])
            .toLowerCase()
            .includes(String(serachQuery).toLowerCase())
        ) {
          tempSearchList.push(campaign);
        }
      });
      this.listOfCampaignToDisplay = [];
      this.listOfCampaignToDisplay = tempSearchList;
    } else {
      this.listOfCampaignToDisplay = this.listOfCampaign;
    }
  }
  changeCampaignStatus(campaign: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      campaign: campaign,
    };
    dialogConfig.maxHeight = `95vh`;
    let dialogRef = this.matDialog.open(
      ChangeCampaignStatusComponent,
      dialogConfig
    );
    dialogRef.updatePosition(dialogConfig.position);
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
    dialogRef.componentInstance.closeActivityAtDialog.subscribe(async (res) => {
      console.log(res);
      if (res == null) {
        return;
      } else if (res['error'] != null || res['error'] != undefined) {
        return;
      } else {
        let param = { campaignId: campaign['_id'], status: res['value'] };
        try {
          let URL = `${webApi.domain + webApi.endPoint.changeCampaignStatus}`;
          const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this.userDetail['accessToken']}`,
          });
          await this.appService.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
            if (data['success']) {
              this.listOfCampaign.forEach((camp: any) => {
                if (camp['_id'] == campaign['_id']) {
                  camp['status'] = res['value'];
                }
              });
              this.showSuccessToaster('Status Changed Successfully.', 'Success');

            } else {
              this.showWarningToaster('Unable to change status.Please try again.', 'Warning');
            }
          });
        } catch (error) {
          this.showWarningToaster('Unable to change status.Please try again.', 'Warning');
        }
      }
    });
  }
  gotoAddCampaign() {
    this.router.navigate(['/admin/campaign/add-edit-campaign']);
  }
  editCampaign(campaign: any) {
    this.appService.campaignEdit = campaign;
    this.router.navigate(['/admin/campaign/add-edit-campaign']);
  }
  viewCampaign(campaign: any) {
    this.appService.campaignDetailToView = campaign;
    this.router.navigate(['/admin/campaign/campaign-statistic']);
  }
  pageChanged(event: any) {
    this.Page = event;
    console.log(event);

    if (this.Page * this.count >= this.listOfCampaignToDisplay.length - this.count * 3) {
      this.skip = this.skip + this.limit;
      if (!this.isLoadingExtraData) this.fetchExtraListData(false);
    } else if (this.Page * this.count >= this.listOfCampaignToDisplay.length) {
      this.skip = this.skip + this.limit;
      this.fetchExtraListData(true);
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title,{
      titleClass: "titleClass",
      messageClass: "messageClass"
    });
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title,{
      titleClass: "titleClass",
      messageClass: "messageClass"
    });
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title,{
      titleClass: "titleClass",
      messageClass: "messageClass"
    });
  }
}
