<div style="height: 100vh;">
    <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="height: 100%;">
        <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right" [dock]="dock"
            [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
            [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
            [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
            <app-add-edit-turf-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                [dataToEdit]="dataToEdit" [listOfSports]="listOfSports" [listOfBusiness]="listOfBusiness">
            </app-add-edit-turf-sidebar>
        </ng-sidebar>
        <div ng-sidebar-content class="sidebar-content">
            <!-- Top Data -->
            <div class="row align-center">
                <div class="top-heading col-md-3">
                    Venue List
                </div>
                <div class="col-md-9">
                    <div class="row align-center" style="justify-content: flex-end;">

                        <div class="col-md-3" style="text-align: right;">
                            <select class="input-field" name="region" id="region" aria-placeholder="Select Business"
                                (change)="onBusinessSelectionChange($event)">
                                <option value="Select Business" selected disabled>Select Business</option>
                                <option value="All">All</option>
                                <option *ngFor="let business of listOfBusiness" [value]="business['_id']"
                                    [selected]="selectedBusiness==business['_id']">{{business['user']['firstName']+'
                                    '+business['user']['lastName']}}</option>
                            </select>
                        </div>
                        <div class="col-md-2" style="text-align: right;">
                            <select style="width: 100%;" class="input-field" name="region" id="region"
                                aria-placeholder="Select Turf Status" (change)="onStatusSelectionChange($event)">
                                <option value="Select Turf Status" selected disabled>Select Turf Status</option>
                                <option [selected]="selectedStatus=='All'" value="All">All</option>
                                <option [selected]="selectedStatus=='PENDING'" value="PENDING">PENDING</option>
                                <option [selected]="selectedStatus=='APPROVED'" value="APPROVED">APPROVED</option>
                                <option [selected]="selectedStatus=='REJECTED'" value="REJECTED">REJECTED</option>

                            </select>
                        </div>
                        <div class="col-md-4" style="text-align: right;">
                            <input type="text" class="input-field" name="searchQuery" id="searchQuery"
                                placeholder="Search Here" (input)="searchData($event.target)">
                        </div>
                        <div class="col-md-3">
                            <button class="add-new-emp" (click)="addNewTurf()">
                                Add New Venue
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Table Data -->
            <div *ngIf="isLoading" class="display-flex align-items-center" style="height: 60vh;">
                <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
                </mat-progress-spinner>
            </div>

            <div *ngIf="listOfTurfToDisplay.length==0 && !isLoading">
                <div class="no-available">
                    No venue available.
                </div>
            </div>

            <div *ngIf="listOfTurfToDisplay.length>0 && !isLoading">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc" data-name="name"
                            scope="col" class="table-heading-content">Name & Phone</th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="fullAddress" scope="col" class="table-heading-content">Address</th>
                        <th style="cursor: pointer;width: 15em;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="sportsName" scope="col" class="table-heading-content">Sport</th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="ownerName" scope="col" class="table-heading-content">Owner Name</th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="ownerName" scope="col" class="table-heading-content">Owner Phone</th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="ownerName" scope="col" class="table-heading-content">Enable WhatsApp</th>
                        <!-- <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="recommendedPosition" scope="col" class="table-heading-content">
                            Position</th> -->
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="recommendedPosition" scope="col" class="table-heading-content">
                            Convenience Fee</th>
                        <th style="cursor: pointer;" data-order="desc" scope="col" class="table-heading-content">
                            Copy Link
                        </th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="status" scope="col" class="table-heading-content">Status</th>
                        <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                            data-name="status" scope="col" class="table-heading-content">Active</th>
                        <th class="table-heading-content">Action</th>
                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let turf of listOfTurfToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                            <th (click)="viewTurfDetails(turf)" style="cursor: pointer;" class="table-data-content">
                                {{turf.name}} ({{turf.phone}})</th>
                            <th class="table-data-content">{{turf.fullAddress}}</th>
                            <th style="width: 15em;" class="table-data-content">{{turf.sportsName}}</th>
                            <th style="width: 10em;" class="table-data-content">{{turf.ownerName}}
                            </th>
                            <th style="width: 10em;" class="table-data-content">{{turf.ownerPhone}}
                            </th>
                            <th class="table-data-content" style="padding-left: 2rem;">
                                <img *ngIf="turf.enableWhatsApp"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                    (click)="enableWhatsApp(turf['_id'],false)" slot="start" />
                                <img *ngIf="!turf.enableWhatsApp"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                    (click)="enableWhatsApp(turf['_id'],true)" slot="start" />
                            </th>
                            <th class="table-data-content" style="width:10em;text-align-last: center;"
                                *ngIf="turf['status'].toLowerCase()=='approved'">
                                <div style="display: contents;">
                                    <img title="Edit" (click)="viewConvenience(turf,false)"
                                        style="height: 1.1rem;margin-right: .5em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <div *ngIf="turf['convenience']==null ||turf['convenience']==undefined "
                                        style="display: inline;">
                                        (PENDING)
                                    </div>
                                </div>
                            </th>

                            <th *ngIf="turf['status'].toLowerCase()!='approved'" style="width: 10em;"
                                class="table-data-content">N.A.</th>
                            <th (click)="createLink(turf)"
                                style="text-decoration: underline;color: blue;cursor: pointer;"
                                class="table-data-content">Copy Link</th>
                            <th style="width: 10em;" class="table-data-content">{{turf.status}}</th>
                            <th class="table-data-content" style="padding-left: 2rem;">
                                <img *ngIf="turf.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-on"
                                    src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                    (click)="suspendTurf(turf,false)" slot="start" />
                                <img *ngIf="!turf.isActive"
                                    class="table-data-content__status-icon table-data-content__status-icon-off"
                                    src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                    (click)="suspendTurf(turf,true)" slot="start" />
                            </th>
                            <th class="table-data-content" style="width:10em;text-align-last: center;">
                                <div style="display: contents;">
                                    <img title="Edit" *ngIf="turf.status!='PENDING'" (click)="editTurf(turf)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/editing.png'>
                                    <img title="View" (click)="viewTurfDetails(turf)"
                                        style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/view.png'>
                                    <img title="Delete" (click)="deleteTurf(turf)"
                                        style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                        src='../../../assets/img/delete.png'>
                                </div>

                            </th>
                        </tr>

                    </tbody>
                </table>
                <div class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>
        </div>
    </ng-sidebar-container>
</div>
<!-- 
<div class="main-container">
    <div class="row align-center">
        <div class="top-heading col-md-3">
            Venue List
        </div>
        <div class="col-md-9">
            <div class="row align-center" style="justify-content: flex-end;">

                <div class="col-md-3" style="text-align: right;">
                    <select class="input-field" name="region" id="region" aria-placeholder="Select Business"
                        (change)="onBusinessSelectionChange($event)">
                        <option value="Select Business" selected disabled>Select Business</option>
                        <option value="All">All</option>
                        <option *ngFor="let business of listOfBusiness" [value]="business['_id']"
                            [selected]="selectedBusiness==business['_id']">{{business['user']['firstName']+'
                            '+business['user']['lastName']}}</option>
                    </select>
                </div>
                <div class="col-md-2" style="text-align: right;">
                    <select style="width: 100%;" class="input-field" name="region" id="region"
                        aria-placeholder="Select Turf Status" (change)="onStatusSelectionChange($event)">
                        <option value="Select Turf Status" selected disabled>Select Turf Status</option>
                        <option [selected]="selectedStatus=='All'" value="All">All</option>
                        <option [selected]="selectedStatus=='PENDING'" value="PENDING">PENDING</option>
                        <option [selected]="selectedStatus=='APPROVED'" value="APPROVED">APPROVED</option>
                        <option [selected]="selectedStatus=='REJECTED'" value="REJECTED">REJECTED</option>

                    </select>
                </div>
                <div class="col-md-4" style="text-align: right;">
                    <input type="text" class="input-field" name="searchQuery" id="searchQuery" placeholder="Search Here"
                        (input)="searchData($event.target)">
                </div>
                <div class="col-md-3">
                    <button class="add-new-emp" (click)="addNewTurf()">
                        Add New Venue
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">
        <ng-sidebar-container (onBackdropClicked)="onBackdropClicked()" style="overflow: hidden;">
            <ng-sidebar class="sidebar" [(opened)]="opened" mode="over" [keyClose]="keyClose" position="right"
                [dock]="dock" [dockedSize]="'50px'" [closeOnClickOutside]="closeOnClickOutside"
                [closeOnClickBackdrop]="closeOnClickBackdrop" [showBackdrop]="showBackdrop" [animate]="animate"
                [trapFocus]="trapFocus" [autoFocus]="autoFocus" [ariaLabel]="'My sidebar'">
                <app-add-edit-turf-sidebar *ngIf="isAddEditCalled" (closeSideForm)="toggleClose($event)"
                    [dataToEdit]="dataToEdit" [listOfSports]="listOfSports" [listOfBusiness]="listOfBusiness">
                </app-add-edit-turf-sidebar>
            </ng-sidebar>
            <div ng-sidebar-content style="overflow: hidden;padding: 1em;">


                <div *ngIf="listOfTurfToDisplay.length==0">
                    <div class="no-available">
                        No venue available.
                    </div>
                </div>
                <div *ngIf="listOfTurfToDisplay.length!=0">
                    <table class="table-style">
                        <thead class="table-heading">
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="name" scope="col" class="table-heading-content">Name & Phone</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="fullAddress" scope="col" class="table-heading-content">Address</th>
                            <th style="cursor: pointer;width: 15em;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="sportsName" scope="col" class="table-heading-content">Sport</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="ownerName" scope="col" class="table-heading-content">Owner Name</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="ownerName" scope="col" class="table-heading-content">Owner Phone</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="recommendedPosition" scope="col" class="table-heading-content">
                                Position</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="recommendedPosition" scope="col" class="table-heading-content">
                                Convenience Fee</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="status" scope="col" class="table-heading-content">Status</th>
                            <th style="cursor: pointer;" [appSort]="listOfTurfToDisplay" data-order="desc"
                                data-name="status" scope="col" class="table-heading-content">Active</th>
                            <th class="table-heading-content">Action</th>
                        </thead>
                        <tbody>
                            <tr class="table-data"
                                *ngFor="let turf of listOfTurfToDisplay | paginate: { itemsPerPage: count, currentPage: p }">
                                <th (click)="viewTurfDetails(turf)" style="cursor: pointer;" class="table-data-content">
                                    {{turf.name}} ({{turf.phone}})</th>
                                <th class="table-data-content">{{turf.fullAddress}}</th>
                                <th style="width: 15em;" class="table-data-content">{{turf.sportsName}}</th>
                                <th style="width: 10em;" class="table-data-content">{{turf.ownerName}}
                                </th>
                                <th style="width: 10em;" class="table-data-content">{{turf.ownerPhone}}
                                </th>
                                <th style="width: 10em;" class="table-data-content">{{turf.recommendedPosition}}</th>
                                <th class="table-data-content" style="width:10em;text-align-last: center;"
                                    *ngIf="turf['status'].toLowerCase()=='approved'">
                                    <div style="display: contents;">
                                        <img title="Edit" (click)="viewConvenience(turf,false)"
                                            style="height: 1.1rem;margin-right: .5em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>
                                        <div *ngIf="turf['convenience']==null ||turf['convenience']==undefined "
                                            style="display: inline;">
                                            (PENDING)
                                        </div>
                                    </div>
                                </th>
                                <th *ngIf="turf['status'].toLowerCase()!='approved'" style="width: 10em;"
                               
                                <th style="width: 10em;" class="table-data-content">{{turf.status}}</th>
                                <th class="table-data-content" style="padding-left: 2rem;">
                                    <img *ngIf="turf.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-on"
                                        src="../../../assets/svgIcons/switch-on.svg" style="width: 2rem;"
                                        (click)="suspendTurf(turf,false)" slot="start" />
                                    <img *ngIf="!turf.isActive"
                                        class="table-data-content__status-icon table-data-content__status-icon-off"
                                        src="../../../assets/svgIcons/switch-off.svg" style="width: 2rem;"
                                        (click)="suspendTurf(turf,true)" slot="start" />
                                </th>
                                <th class="table-data-content" style="width:10em;text-align-last: center;">
                                    <div style="display: contents;">
                                        <img title="Edit" *ngIf="turf.status!='PENDING'" (click)="editTurf(turf)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/editing.png'>
                                        <img title="View" (click)="viewTurfDetails(turf)"
                                            style="height: 1.5rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/view.png'>
                                        <img title="Delete" (click)="deleteTurf(turf)"
                                            style="height: 1.1rem;margin-right: 1em;cursor: pointer;"
                                            src='../../../assets/img/delete.png'>
                                    </div>

                                </th>
                            </tr>

                        </tbody>
                    </table>
                    <div class="text-right" style="font-size: medium;margin: 1em;">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>

            </div>
        </ng-sidebar-container>
    </div>
</div> -->