<div class="container__logout">
  <h2 class="container__logout__heading">Are you sure you want to resend the message to the {{ type }}?</h2>
  <div class="btnContainer">
    <button class="container__logout__btn-2 btn" (click)="close()">
      Cancel
    </button>
    <button class="container__logout__btn-1 btn" (click)="type === 'User' ? sendWhatsAppMessage('User') : sendWhatsAppMessage('Owner')">
      Yes
    </button>
  </div>
</div>
  