<div class="main-container">
    <div class="row align-center" style="margin-right: 1.4rem;margin-left: .1rem;">
        <div class="top-heading col-md-3">
            Withdrawal List
        </div>
        <div class="col-md-9">
            <div class="row align-center">
                <div class="col-md-12" style="display: flex;justify-content: space-around;align-items: center;">
                    <div class="col-md-5"
                        style="text-align: right;display: flex;justify-content: space-around;align-items: center;">
                        Withdrawal Date:- <input type="date" style="width: 45%;" class="input-field"
                            placeholder="Select Date" [(ngModel)]="dateResult" [ngModelOptions]="{standalone: true}"
                            name="dateQuery" id="dateQuery" (input)="fetchDataBasedOndate($event.target)">
                    </div>
                    <div class="col-md-4" style="text-align: right;">
                        <input type="text" class="input-field" style="width: 100%;" name="searchQuery" id="searchQuery"
                            placeholder="Search Here" [(ngModel)]="searchStr" (keyup.enter)="searchWithdrawalData()"
                            (input)="searchData($event.target)">
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isLoading">
        <div class="center-loading">
            <mat-progress-spinner mode="indeterminate" diameter="40" style="margin:0 auto;">
            </mat-progress-spinner>
        </div>
    </div>
    <div style="height: 79vh;" *ngIf="!isLoading">

        <div style="padding: 1em;">


            <div *ngIf="listOfWithdrawalToDisplay.length==0">
                <div class="no-available">
                    No withdrawal data available.
                </div>
            </div>
            <div *ngIf="listOfWithdrawalToDisplay.length!=0">
                <table class="table-style">
                    <thead class="table-heading">
                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="createdAt" scope="col" class="table-heading-content">Date</th>
                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="name" scope="col" class="table-heading-content">Name</th>
                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="phone" scope="col" class="table-heading-content">Phone</th>
                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="turfName" scope="col" class="table-heading-content">Turf</th>
                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="amount" scope="col" class="table-heading-content">Amount</th>

                        <th style="cursor: pointer;" [appSort]="listOfWithdrawalToDisplay" data-order="desc"
                            data-name="status" scope="col" class="table-heading-content">Status</th>

                    </thead>
                    <tbody>
                        <tr class="table-data"
                            *ngFor="let data of listOfWithdrawalToDisplay | paginate: { itemsPerPage: count, currentPage: Page }">
                            <th class="table-data-content">{{data.createdAt|date}}</th>
                            <th style="cursor: pointer;" class="table-data-content">
                                {{(data.user.firstName!=null && data.user.firstName!=undefined?data.user.firstName:'')+'
                                '+(data.user.lastName!=null && data.user.lastName!=undefined?data.user.lastName:'' )}}
                            </th>
                            <th style="cursor: pointer;" class="table-data-content">
                                {{data.user.phone}}</th>
                            <th class="table-data-content">{{data.turf['name']}}</th>
                            <th class="table-data-content">{{data.amount.toFixed(2)}}</th>
                            <th class="table-data-content">{{data.status}}</th>

                            <!-- <th style="width: 10em;" class="table-data-content">
                                {{data.status}}
                            </th> -->
                        </tr>

                    </tbody>
                </table>
                <div *ngIf="isLoadingExtraData" class="text-right" style="font-size: medium; margin: 1em">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isLoadingExtraData" class="text-right" style="font-size: medium;margin: 1em;">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>