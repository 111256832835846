<div class="container_editUser" *ngIf="!isLoading">
  <div class="container_editUser_main">
    <div class="container_editUser_title">Bank Details</div>
    <div>
      <form [formGroup]="addBankForm">
        <div class="row">
          <div class="col-md-6">
            <label class="label mt-5 required">Account Holder Name</label>
            <input
              type="text"
              class="input-style"
              placeholder="Account Holder Name"
              formControlName="accountHolderName"
            />
            <div
              class="error"
              *ngIf="submitted && f['accountHolderName'].errors"
            >
              <div *ngIf="f['accountHolderName'].errors['required']">
                This field is required
              </div>
              <div *ngIf="f['accountHolderName'].errors['pattern']">
                Account Holder Name is invalid
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="label mt-5 required">Bank Name</label>
            <input
              type="text"
              class="input-style"
              placeholder="Bank Name"
              formControlName="bankName"
            />
            <div class="error" *ngIf="submitted && f['bankName'].errors">
              <div *ngIf="f['bankName'].errors['required']">
                This field is required
              </div>
              <div *ngIf="f['bankName'].errors['pattern']">
                Bank Name is invalid
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="label mt-5 required">Account Number</label>
            <input
              type="text"
              class="input-style"
              placeholder="Account Number"
              formControlName="accountNumber"
              minlength="8"
              maxlength="15"
            />
            <div class="error" *ngIf="submitted && f['accountNumber'].errors">
              <div *ngIf="f['accountNumber'].errors['required']">
                This field is required
              </div>
              <div *ngIf="f['accountNumber'].errors['pattern']">
                Account Number is invalid
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="label mt-5 required">IFSC Code</label>
            <input
              type="email"
              class="input-style"
              placeholder="IFSC Code"
              formControlName="ifscCode"
            />
            <div class="error" *ngIf="submitted && f['ifscCode'].errors">
              <div *ngIf="f['ifscCode'].errors['required']">
                This field is required
              </div>
              <div *ngIf="f['ifscCode'].errors['pattern']">
                IFSC Code is invalid
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="label mt-5 required">Pan Card Details</label>
            <input
              type="text"
              class="input-style"
              id="pancardNumber"
              placeholder="Pan card Number"
              formControlName="pancardNumber"
              (blur)="trimPancard()"
            />
            <div class="error" *ngIf="submitted && f['pancardNumber'].errors">
              <div *ngIf="f['pancardNumber'].errors['required']">
                This field is required
              </div>
              <div *ngIf="f['pancardNumber'].errors['pattern']">
                Pancard Number is invalid
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="label mt-5" for="">Cancelled Cheque Leaf</label>
            <div *ngIf="!cancelledChequeLaefPreview || cancelledChequeLaefPreview == 'null' " class="uploadBox">
              <label
                for="upload"
                style="
                  color: #253b86;
                  font-size: 1.44rem;
                  cursor: pointer;
                  font-weight: 500;
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                "
                class="upload"
                ><img
                  src="../../../../../assets/svgIcons/upload-img.svg"
                  alt=""
                  srcset=""
              /></label>
              <div
                style="
                  color: #5e5e5e;
                  font-size: 1rem;
                  font-weight: 400;
                  display: contents;
                "
              >
                <span style="color: #798dff">Browse files</span>, Supports jpeg
                and jpg format
              </div>
              <div
                style="
                  color: #acacb4;
                  font-size: 1rem;
                  font-weight: 400;
                  padding-top: 1rem;
                "
              >
                Less than 2 Mb. 280 x 203 px
              </div>
              <input
                type="file"
                id="upload"
                for="upload"
                style="display: none"
                accept="image/*"
                (change)="selectCancelledLeafChequeFile($event)"
              />
            </div>
            <div
              *ngIf="cancelledChequeLaefPreview && cancelledChequeLaefPreview != 'null'"
              class="uploadBox"
              style="position: relative; height: 16rem; width: 18rem"
            >
              <img
                *ngIf="cancelledChequeLaefPreview"
                (click)="removeCancelledLeafChequeImage()"
                class="removeImage"
                src="../../../../../assets/svgIcons/close.svg"
                alt=""
                srcset=""
              />
              <img
                style="height: 16rem; width: 18rem; border-radius: 0.5rem"
                [src]="cancelledChequeLaefPreview"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="label mt-5" for="">Pan card</label>
            <div *ngIf="!pancardPreview || pancardPreview == 'null' " class="uploadBox">
              <label
                for="upload"
                style="
                  color: #253b86;
                  font-size: 1.44rem;
                  cursor: pointer;
                  font-weight: 500;
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                "
                class="upload"
                ><img
                  src="../../../../../assets/svgIcons/upload-img.svg"
                  alt=""
                  srcset=""
              /></label>
              <div
                style="
                  color: #5e5e5e;
                  font-size: 1rem;
                  font-weight: 400;
                  display: contents;
                "
              >
                <span style="color: #798dff">Browse files</span>, Supports jpeg
                and jpg format
              </div>
              <div
                style="
                  color: #acacb4;
                  font-size: 1rem;
                  font-weight: 400;
                  padding-top: 1rem;
                "
              >
                Less than 2 Mb. 280 x 203 px
              </div>
              <input
                type="file"
                id="upload"
                for="upload"
                style="display: none"
                accept="image/*"
                (change)="selectPancardFile($event)"
              />
            </div>
            <div
              *ngIf="pancardPreview && pancardPreview != 'null' "
              class="uploadBox"
              style="position: relative; height: 16rem; width: 18rem"
            >
              <img
                *ngIf="pancardPreview"
                (click)="removePancardImage()"
                class="removeImage"
                src="../../../../../assets/svgIcons/close.svg"
                alt=""
                srcset=""
              />
              <img
                style="height: 16rem; width: 18rem; border-radius: 0.5rem"
                [src]="pancardPreview"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="buttons">
      <button
        class="btn-1"
        mat-button
        (click)="cancelBtnHandler()"
      >
        Cancel
      </button>
      <button
        class="btn"
        mat-button
        (click)="
          isAddEditBankDetailLoading ? null : onSubmitBusinessBankDetail()
        "
      >
        <div>
          {{ "Save Changes" }}
        </div>
      </button>
    </div>
  </div>
</div>
