<footer class="footer1">
    <div class="container-fluid1">
        <div class="row">
            <div class="col-sm-6">
                <script>
                    document.write(new Date().getFullYear())
                </script> © NextEdgeLabs.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-sm-block">
                    Crafted with <i class="mdi mdi-heart text-danger"></i> by NextEdge
                </div>
            </div>
        </div>
    </div>
</footer>