import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-business',
  templateUrl: './view-business.component.html',
  styleUrls: ['./view-business.component.scss'],
})
export class ViewBusinessComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isAcceptButtonSelected: boolean = false;
  isRejectButtonSelected: boolean = false;
  remark: any;
  _albums: any = [];
  _bank: any = [];

  constructor(
    public dialogRef: MatDialogRef<ViewBusinessComponent>,
    private _lightbox: Lightbox,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log(this.data);
    dialogRef.disableClose = true;
    for (let i = 0; i < this.data['business']['document'].length; i++) {
      const src = this.data['business']['document'][i]['docUrl'];
      const caption =
        this.data['business']['document'][i]['docType'] +
        ' Image';
      const thumb = this.data['business']['document'][i]['docUrl'];
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
        docNumber: this.data['business']['document'][i]['docNumber'],
        docType: this.data['business']['document'][i]['docType'],
        docUrl: this.data['business']['document'][i]['docUrl'],
      };

      this._albums.push(album);
    }
    for (let i = 0; i < this.data['business']['bankDetails'].length; i++) {
      const src = this.data['business']['bankDetails'][i]['chequeImageUrl'];
      const caption = 'Cancelled Cheque';
      const thumb = this.data['business']['bankDetails'][i]['chequeImageUrl'];
      const album = {
        src: src,
        caption: caption,
        thumb: thumb,
        accountNumber: this.data['business']['bankDetails'][i]['accountNumber'],
        bankName: this.data['business']['bankDetails'][i]['bankName'],
        docUrl: this.data['business']['bankDetails'][i]['chequeImageUrl'],
        ifscCode: this.data['business']['bankDetails'][i]['ifscCode'],
        accountHolderName:
          this.data['business']['bankDetails'][i]['accountHolderName'],
      };

      this._bank.push(album);
    }
  }
  openPopup(list: any, index: number): void {
    // open lightbox
    this._lightbox.open(list, index);
  }

  closePopup(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }
  radioChangeHandler(event: any) {
    let radioSelectedValue = event.target.name;
    if (radioSelectedValue == 'Approve') {
      this.onAcceptClick();
    } else {
      this.onRejectClick();
    }
  }
  onAcceptClick() {
    this.isAcceptButtonSelected = true;
    this.isRejectButtonSelected = false;
  }
  onRejectClick() {
    this.isRejectButtonSelected = true;
    this.isAcceptButtonSelected = false;
  }
  onFranchiseSelectionChange(event: any) {
    console.log();
  }
  acceptBusiness() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Accept Business',
      value: this.data['business'],
    });
  }
  rejectBusiness() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      action: 'Reject Business',
      value: this.data['business'],
      moreVal: this.remark,
    });
  }
}
