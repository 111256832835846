import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { webApi } from 'src/app/api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resend-msg-popup',
  templateUrl: './resend-msg-popup.component.html',
  styleUrls: ['./resend-msg-popup.component.scss']
})
export class ResendMsgPopupComponent implements OnInit {
  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();

  isSendMessageLoading: boolean = false;

  userDetails: any;
  type: string | undefined;

  bookingId: any; 

  constructor(
    public appService: AppService,
    public dialogRef: MatDialogRef<ResendMsgPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    this.bookingId = data.booking._id; 
    // console.log('bookingId',this.bookingId);
   }

  ngOnInit(): void {
  }

  async sendWhatsAppMessage(src: any) {
    const info = this.data.booking; 
    this.isSendMessageLoading = true;
    try {
      let URL;
      if (src == 'User') {
        URL = `${webApi.domain + webApi.endPoint.sendUserMsgManually}/${info._id}`;
      } else {
        URL = `${webApi.domain + webApi.endPoint.sendOwnerMsgManually}/${info._id}`;
      }

      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      await this.appService.getMethodWithToken(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.showSuccessToaster(
            'Message Sent Successfully',
            'Success'
          );
          this.isSendMessageLoading = false;
          this.close();
        } else {
          this.showErrorToaster(
            'unable to send message',
            'Error'
          );
        }
        this.isSendMessageLoading = false;

      });
    } catch (error) {
      this.showErrorToaster(
        'unable to send message',
        'Error'
      );
      this.isSendMessageLoading = false;

    }
  }

  close() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }

}
