<div class="container__business">
  <div
    class="container__business__header"
    style="align-items: center; justify-content: space-between"
  >
    <div class="container__business__header__title">Venue Details</div>
    <div class="clear-button" (click)="close()">
      Close<mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
  </div>

  <div class="container__business__details">
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/courthouse.svg"
            alt="" /></span
        >Venue Details:
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <div class="title">Venue ID</div>
          <div>:</div>
          <div class="value">{{ data["turf"]["_id"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Owner Name</div>
          <div>:</div>
          <div class="value">{{ data["turf"]["ownerName"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Owner Phone</div>
          <div>:</div>
          <div class="value">{{ data["turf"]["ownerPhone"] }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Registration Date</div>
          <div>:</div>
          <div class="value">{{ data["turf"]["createdAt"] | date }}</div>
        </div>
        <div class="business-details__content__business">
          <div class="title">Address</div>
          <div>:</div>
          <div class="value" style="width: 30rem">
            {{ data["turf"]["address"]["streetName"] }} ,
            {{ data["turf"]["address"]["landmark"] }} ,
            {{ data["turf"]["address"]["city"] }} ,
            {{ data["turf"]["address"]["state"] }} ,
            {{ data["turf"]["address"]["pincode"] }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/fluent_sport-24-regular.svg"
            alt="" /></span
        >Available
        {{
          data["turf"]["sportCategory"]["categoryName"] == "Outdoor"
            ? "Sports"
            : "Games"
        }}:
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <ul *ngFor="let sport of data['turf']['sportsType']; let i = index">
            <li class="value">{{ sport["sport"] }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/ph_calendar-light.svg"
            alt="" /></span
        >Open Days
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <ul *ngFor="let day of data['turf']['days']; let i = index">
            <li class="value">{{ day }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/geo_turf-size.svg"
            alt="" /></span
        >Available Slot & Size:
      </div>
      <div class="grid-container">
        <div *ngFor="let turfSize of data['turf']['slots']" class="grid-item">
          <div style="display: flex">
            <div *ngIf="turfSize.session === 'Morning'">
              <img
                class="image"
                src="../../../../../assets/icons/sun.png"
                alt="Morning"
              />
            </div>
            <div *ngIf="turfSize.session === 'Afternoon'">
              <img
                class="image"
                src="../../../../../assets/icons/sunset (1).png"
                alt="Afternoon"
              />
            </div>
            <div *ngIf="turfSize.session === 'Evening'">
              <img
                class="image"
                src="../../../../../assets/icons/day-and-night.png"
                alt="Evening"
              />
            </div>
            <div *ngIf="turfSize.session === 'Night'">
              <img
                class="image"
                src="../../../../../assets/icons/half-moon.png"
                alt="Night"
              />
            </div>
            <div class="value">{{ turfSize["session"] }} Slot :</div>
          </div>
          <div style="display: flex">
            <div class="title" style="width: 10rem">Start Time</div>
            <div style="margin-right: 2rem">:</div>
            <div class="value">{{ getTimePeriod(turfSize["startTime"]) }}</div>
          </div>
          <div style="display: flex">
            <div class="title" style="width: 10rem">End Time</div>
            <div style="margin-right: 2rem">:</div>
            <div class="value">{{ getTimePeriod(turfSize["endTime"]) }}</div>
          </div>

          <div style="display: flex">
            <div class="title" style="width: 10rem">
              {{
                data["turf"]["sportCategory"]["categoryName"] == "Outdoor"
                  ? "Size & Pricing"
                  : "Game & Pricing"
              }}
            </div>
            <div style="margin-right: 2rem">:</div>
            <div class="value" style="margin-bottom: 1.5rem">
              <div
                *ngFor="let size of turfSize['priceAndQuantity']; let i = index" style="margin-bottom: 1rem;"
              >
                {{ i + 1 }}) {{ size["title"] }}
                {{
                  size["label"] == "" ||
                  size["label"] == null ||
                  size["label"] == undefined
                    ? ""
                    : "(" + size["label"] + ")"
                }}
                at {{ "\u20b9" + size["price"] }} per
                {{ data["turf"]["slotTimeDifference"] }}
                minutes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/material-symbols_meeting-room-outline.svg"
            alt="" /></span
        >Available Facilities:
      </div>
      <div class="business-details__content">
        <div class="business-details__content__business">
          <ul
            *ngFor="let facility of data['turf']['facilities']; let i = index"
          >
            <li class="value">{{ facility }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details">
      <div
        class="business-details__title"
        *ngIf="data['turf']['sportCategory']['categoryName'] == 'Outdoor'"
        style="display: flex; justify-content: center; align-items: center"
      >
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/openmoji_goal-net.svg"
            alt="" /></span
        >Is Turf Netting:
        <div class="business-details__title" style="margin-left: 2rem">
          {{ data["turf"]["isNet"] ? "Yes" : "No" }}
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div
      *ngIf="data['turf']['sportCategory']['categoryName'] == 'Outdoor'"
      class="business-details"
    >
      <div class="business-details">
        <div class="business-details__title">
          <span
            ><img
              class="image"
              src="../../../../../assets/svgIcons/ph_bank.svg"
              alt="" /></span
          >Turf Images :
        </div>
        <div class="row">
          <div
            class="col-md-12"
            style="
              display: grid;
              align-items: center;
              grid-template-columns: repeat(3, 1fr);
              gap: 1.5rem;
            "
          >
            <div
              *ngFor="let item of _albums; let i = index"
              style="display: flex; align-items: center; margin-right: 1em"
            >
              <img class="prod-img" [src]="item.thumb" (click)="openPopup(i)" />
              <div class="prod-name" style="margin-left: 1em">
                {{ item["docNumber"] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="business-details" >
      <div class="business-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/basil_document-outline.svg"
            alt="" /></span
        >Optional Details :</div>
    </div>
    <div
      *ngIf="
        this.data['turf']['business']['status'] != 'PENDING' &&
        this.data['turf']['business']['status'] != 'REJECTED'
      "
    >
      <div class="business-details__content">
        <div style="display: flex">
          <div class="title">Venue Type</div>
          <div style="margin-right: 2rem">:</div>
          <div class="value" *ngIf="data['turf']['turfType'] != null">
            {{ data["turf"]["turfType"] }}
          </div>
        </div>
        <div style="display: flex">
          <div class="title">Commission Rate</div>
          <div style="margin-right: 2rem">:</div>
          <div class="value" *ngIf="data['turf']['commission'] != null">
            {{ data["turf"]["commission"] }} %
          </div>
        </div>
        <div style="display: flex">
          <div class="title">Advance Payment</div>
          <div style="margin-right: 2rem">:</div>
          <div class="value">&#8377;{{ data["turf"]["advanceAmount"] }}</div>
        </div>
      </div>
      <div
        class="row"
        style="align-items: baseline; margin-top: 1.5rem;"
        *ngIf="data['turf']['status'] == 'PENDING'"
      >
        <div class="col-md-6" *ngIf="data['turf']['turfType'] == null">
          <h3
            style="font-weight: 600; margin-bottom: 5px; margin-right: 0.5rem"
          >
            Venue Type:
          </h3>
          <div>
            <select
              id="turfType"
              name="turfType"
              (change)="selectTypeDropdown($event.target)"
              style="padding-left: 0rem"
              class="input-style"
            >
              <option value="Select Type" selected disabled>Select Type</option>
              <option value="BOOK">BOOK</option>
              <option value="CALL">CALL</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="data['turf']['commission'] == null">
          <h3
            style="font-weight: 600; margin-bottom: 5px; margin-right: 0.5rem"
          >
            Commission Rate:
          </h3>
          <div>
            <input
              type="number"
              placeholder="Commission"
              id="commission"
              class="input-style"
              (input)="setCommission($event.target)"
            />
          </div>
        </div>
      </div>
      <div class="business-details">
        <div
          class="business-details__title"
          style="margin-top: 2rem !important"
        >
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/status.svg"
            alt="" /></span
        >
          Status :
        </div>
        <div class="row" *ngIf="data['turf']['status'] != 'PENDING'">
          <div class="col-md-12">
            <div
              style="display: contents"
              *ngIf="data['turf']['status'] == 'APPROVED'"
            >
              <img
                src="../../../../../assets/svgIcons/mdi_approve.svg"
                alt=""
              />
            </div>
            <div
              style="display: contents"
              *ngIf="data['turf']['status'] != 'PENDING'"
              [ngClass]="{
                'rejected-order': data['turf']['status'] == 'REJECTED',
                'accepted-order': data['turf']['status'] == 'APPROVED'
              }"
            >
              {{
                data["turf"]["status"] == "REJECTED"
                  ? "Rejected"
                  : data["turf"]["status"] == "APPROVED"
                  ? "Approved"
                  : "Pending"
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="data['turf']['status'] == 'PENDING'">
        <div
          class="col-md-12"
          style="display: flex; align-items: center; margin-top: 1.5rem"
        >
          <input
            type="radio"
            name="radio-btn"
            id="Approve"
            name="Approve"
            [checked]="isAcceptButtonSelected"
            (change)="radioChangeHandler($event)"
          />
          <label for="Approve" class="lbl-radio">Approve</label>
          <input
            type="radio"
            name="radio-btn"
            id="Reject"
            name="Reject"
            [checked]="isRejectButtonSelected"
            (change)="radioChangeHandler($event)"
          />
          <label for="Reject" class="lbl-radio">Reject</label>
        </div>
      </div>
      <div *ngIf="isAcceptButtonSelected">
        <div>
          <div
            style="width: 25em; font-size: medium; margin-top: 1em"
            mat-dialog-content
          >
            Are you sure you want to approve this Venue?
          </div>
        </div>
        <div mat-dialog-actions>
          <button class="canel-btn" mat-dialog-close (click)="close()">
            No
          </button>
          <button
            class="ok-btn"
            [disabled]="selectedTurfType == null || commission == null"
            (click)="
              selectedTurfType == null || commission == null
                ? null
                : acceptTurf()
            "
          >
            Yes
          </button>
        </div>
      </div>
      <div *ngIf="isRejectButtonSelected">
        <div>
          <label for="remark" class="label required">Remark</label>
          <input
            type="text"
            id="remark"
            class="input-style"
            placeholder="Enter remark"
            [(ngModel)]="remark"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div mat-dialog-actions>
          <button class="no-btn" mat-dialog-close (click)="close()">No</button>
          <button
            class="yes-btn"
            [disabled]="remark == null"
            (click)="remark == null ? null : rejectTurf()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
