import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { BusinessComponent } from './business/business.component';
import { TurfComponent } from './turf/turf.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { BookingComponent } from './booking/booking.component';
import { MainRentalComponent } from './main-rental/main-rental.component';
import { OfferManagementComponent } from './offer-management/offer-management.component';
import { SportManagementComponent } from './sport-management/sport-management.component';
import { ReportsComponent } from './reports/reports.component';
import { EmployeeManagementComponent } from './employee-management/employee-management.component';
import { CampaignComponent } from './campaign/campaign.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { CrmComponent } from './crm/crm.component';
import { ContestComponent } from './contest/contest.component';
import { BusinessManagementComponent } from './business-management/business-management.component';
import { VenueManagementComponent } from './venue-management/venue-management.component';
const routes: Routes = [
  {
    path: 'dashboard',
    component: AdminDashboardComponent,
  },

  // {
  //   path: 'business',
  //   component: BusinessComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './business/business.module#BusinessModule',
  //     },
  //   ],
  // },
  {
    path: 'business-management',
    component: BusinessManagementComponent,
    children: [
      {
        path: '',
        loadChildren: './business-management/business-management.module#BusinessManagementModule',
      },
    ],
  },
  {
    path: 'compliance',
    component: ComplianceComponent,
    children: [
      {
        path: '',
        loadChildren: './compliance/compliance.module#ComplianceModule',
      },
    ],
  },
  // {
  //   path: 'turf',
  //   component: TurfComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: './turf/turf.module#TurfModule',
  //     },
  //   ],
  // },
  {
    path: 'venue-management',
    component: VenueManagementComponent,
    children: [
      {
        path: '',
        loadChildren: './venue-management/venue-management.module#VenueManagementModule',
      },
    ],
  },
  {
    path: 'booking',
    component: BookingComponent,
    children: [
      {
        path: '',
        loadChildren: './booking/booking.module#BookingModule',
      },
    ],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    children: [
      {
        path: '',
        loadChildren: './reports/reports.module#ReportsModule',
      },
    ],
  },
  {
    path: 'rental',
    component: MainRentalComponent,
    children: [
      {
        path: '',
        loadChildren: './main-rental/main-rental.module#MainRentalModule',
      },
    ],
  },
  {
    path: 'offer',
    component: OfferManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './offer-management/offer-management.module#OfferManagementModule',
      },
    ],
  },
  {
    path: 'sport',
    component: SportManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './sport-management/sport-management.module#SportManagementModule',
      },
    ],
  },
  {
    path: 'crm',
    component: CrmComponent,
    children: [
      {
        path: '',
        loadChildren:
          './crm/crm.module#CrmModule',
      },
    ],
  },
  {
    path: 'employee',
    component: EmployeeManagementComponent,
    children: [
      {
        path: '',
        loadChildren:
          './employee-management/employee-management.module#EmployeeManagementModule',
      },
    ],
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    children: [
      {
        path: '',
        loadChildren:
          './campaign/campaign.module#CampaignModule',
      },
    ],
  },
  {
    path: 'withdrawal',
    component: WithdrawalComponent,
    children: [
      {
        path: '',
        loadChildren:
          './withdrawal/withdrawal.module#WithdrawalModule',
      },
    ],
  },
  {
    path: 'contest',
    component: ContestComponent,
    children: [
      {
        path: '',
        loadChildren: './contest/contest.module#ContestModule',
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
