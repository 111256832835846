import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ListBusinessManagementComponent } from './list-business-management/list-business-management.component';
import { AddEditBusinessComponent } from './add-edit-business/add-edit-business.component';

const routes: Routes = [
  {
    path: '',
    component: ListBusinessManagementComponent,
  },
  {
    path: 'add-business',
    component: AddEditBusinessComponent,
  },
  {
    path: 'edit-business',
    component: AddEditBusinessComponent,
  },

  { path: '', pathMatch: 'full', redirectTo: '/admin/business-management' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessManagementRoutingModule {}
