import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-apply-crm-coupon',
  templateUrl: './apply-crm-coupon.component.html',
  styleUrls: ['./apply-crm-coupon.component.scss']
})
export class ApplyCrmCouponComponent implements OnInit {
  userDetails: any;
  isLoading: boolean = true;
  selectedCoupon: any;

  listOfCoupon: any = [];
  listOfCouponToDisplay: any = [];

  @Output() closeActivityAtDialog: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ApplyCrmCouponComponent>,
    private toastr: ToastrService,
    public as: AppService,
    @Inject(MAT_DIALOG_DATA) public dialogParentData: any,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    dialogRef.disableClose = true;

  }
  ngOnInit(): void {
    this.fetchAllCoupon();
  }

  async fetchAllCoupon() {
    this.isLoading = true;
    this.listOfCoupon = [];
    this.listOfCouponToDisplay = [];
    try {
      let URL;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchCRMCoupon}`;
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfCoupon = data['result'];
          this.listOfCoupon.forEach((element: any) => {
            element['isDisabled'] = false;
          });
          this.listOfCouponToDisplay = this.listOfCoupon;

          if (this.dialogParentData != null && this.dialogParentData != undefined) {
            if (this.dialogParentData['coupon'] != null && this.dialogParentData['coupon'] != undefined) {
              this.listOfCoupon.forEach((element: any) => {
                if (element['_id'] == this.dialogParentData['coupon']['_id']) {
                  this.selectedCoupon = element;
                }
              });

            }
          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
    }
  }

  clearCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit(null);
  }

  selectCoupon(coupon: any) {
    if (coupon._id == (this.selectedCoupon != null && this.selectedCoupon != undefined ? this.selectedCoupon._id : null)) {
      this.selectedCoupon = null;
    } else {
      this.selectedCoupon = coupon;
    }
  }

  removeCoupon() {
    this.selectedCoupon = null;
  }

  applyCoupon() {
    this.dialogRef.close();
    this.closeActivityAtDialog.emit({
      result: this.selectedCoupon
    });
  }
}
