import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-add-edit-rental',
  templateUrl: './add-edit-rental.component.html',
  styleUrls: ['./add-edit-rental.component.scss'],
})
export class AddEditRentalComponent implements OnInit {
  @Input() dataToEdit: any;
  @Input() listOfBusiness: any;
  @Output() closeSideForm: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;

  // Pan Card Image
  selectedItemImage: any = null;
  selectedItemName: any = null;
  defaultItemImage: any;

  myHeaders = new Headers();
  formdata = new FormData();
  listOfSports: any = [];

  venueDropdownSetting: IDropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  listOfVenue: any = [];
  selectedVenues: any = [];

  productNameControl = new FormControl('', [Validators.required]);
  priceControl = new FormControl('', [Validators.required]);
  durationControl = new FormControl('', [Validators.required]);
  sportControl = new FormControl('Select Sport', [Validators.required]);

  addRentalForm: FormGroup = new FormGroup({
    productName: this.productNameControl,
    price: this.priceControl,
    duration: this.durationControl,
    sport: this.sportControl,
  });
  userDetails: any;
  selectedSportId: string = '';

  constructor(
    private matDialog: MatDialog,
    public as: AppService,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
  }
  ngOnInit(): void {
    this.addRentalForm = this.formBuilder.group({
      productName: this.productNameControl,
      price: this.priceControl,
      duration: this.durationControl,
      sport: this.sportControl,
    });
    if (this.dataToEdit != null) {
      this.addRentalForm
        .get('productName')
        ?.setValue(this.dataToEdit['productName']);
      this.addRentalForm.get('price')?.setValue(this.dataToEdit['price']);
      this.addRentalForm.get('duration')?.setValue(this.dataToEdit['duration']);

      this.selectedVenues = this.dataToEdit['turfs'];

      this.addRentalForm
        .get('sport')
        ?.setValue(this.dataToEdit['sport']['_id']);
      this.defaultItemImage = this.dataToEdit['productImage'];
    }
    this.fetchAllSportsCategory();
    this.fetchVenueAdmin();
  }

  async fetchVenueAdmin() {
    this.isLoading = true;
    this.listOfVenue = [];
    try {
      let URL;
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      URL = `${webApi.domain + webApi.endPoint.fetchTurfAdmin}?admin=1`;
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfVenue = data['result'];
          this.isLoading = false;
        } else {
          this.listOfVenue = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.listOfVenue = [];
    }
  }

  displayPancard(event: any) {
    this.selectedItemImage = event.target.files[0];
    this.selectedItemName = this.selectedItemImage['name'];
    var reader = new FileReader();
    reader.onload = (event1: any) => {
      this.defaultItemImage = event1.target.result;
    };
    reader.readAsDataURL(this.selectedItemImage);
  }

  downloadImage(url: any) {
    window.open(url);
  }

  searchSportChangeHandler(event: any) {
    this.addRentalForm.get('sport')?.setValue(event.value);
  }

  cancel(success: any) {
    this.closeSideForm.emit(success);
  }

  async fetchAllSportsCategory() {
    this.isLoading = true;
    this.listOfSports = [];
    try {
      let param = {};
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      let URL;
      URL = `${webApi.domain + webApi.endPoint.fetchSport}`;
      await this.as.fetchDetailViaGet(URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          this.listOfSports = data['result'];
          console.log(this.listOfSports);
          this.isLoading = false;
        } else {
          this.listOfSports = [];
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.listOfSports = [];
      this.isLoading = false;
    }
  }

  async addEditItem() {
    this.isLoading = true;
    if (this.dataToEdit != null) {
      this.formdata.append('itemId', this.dataToEdit['_id']);
      this.formdata.append(
        'productImage',
        JSON.stringify(this.defaultItemImage)
      );
    } else {
      this.formdata.append('itemId', '0');
    }
    if (this.selectedItemImage != null) {
      this.formdata.append(
        'productImage',
        this.selectedItemImage,
        this.selectedItemName
      );
      // this.formdata.append('productImage', this.selectedItemImage[0]);
    }

    this.formdata.append('duration', this.addRentalForm.get('duration')?.value);
    this.formdata.append('price', this.addRentalForm.get('price')?.value);

    let turfs: any = [];

    this.selectedVenues.forEach((data: any) => {
      turfs.push(data['_id']);
    });

    this.formdata.append('turfs', JSON.stringify(turfs));

    this.formdata.append(
      'productName',
      this.addRentalForm.get('productName')?.value
    );
    this.formdata.append('sport', this.addRentalForm.get('sport')?.value);

    this.myHeaders.append(
      'Authorization',
      `Bearer ${this.userDetails['accessToken']}`
    );
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: this.myHeaders,
      body: this.formdata,
      redirect: 'follow',
    };
    var URL;
    if (this.dataToEdit != null) {
      URL = `${webApi.domain + webApi.endPoint.addAndEditRentalItem}`;
    } else {
      URL = `${webApi.domain + webApi.endPoint.addAndEditRentalItem}`;
    }
    try {
      fetch(URL, requestOptions)
        .then((result) => {
          if (result) {
            let data = result.json();
            data.then((res) => {
              if (res['success']) {
                this.isLoading = false;
                this.addRentalForm.reset();
                this.cancel(res['result']);
              } else {
                this.isLoading = false;
                this.cancel('Error');
              }
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.cancel('Error');
          console.log('error', error);
        });
      return;
    } catch (error) {
      this.isLoading = false;
      this.cancel('Error');
    }
  }
}
