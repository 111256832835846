<div class="container_editUser" *ngIf="!isLoading">
    <div class="container_editUser_main">
        <div class="container_editUser_title">About Venue</div>
        <div>
            <form [formGroup]="addTurfForm">
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Add Venue Name</label>
                        <input type="text" class="input-style"  placeholder="Enter store name" formControlName="name"/>
                        <div class="error" *ngIf="submitted && f['name'].errors">
                            <div *ngIf="f['name'].errors['required']">This field is required</div>
                            <div *ngIf="f['name'].errors['pattern']">Name is invalid</div>
                          </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">Add Contact Number</label>
                        <input type="text" class="input-style"  placeholder="Add Contact Number" formControlName="phone" maxlength="10" />
                        <div class="error" *ngIf="submitted && f['phone'].errors">
                            <div *ngIf="f['phone'].errors['required']">This field is required</div>
                            <div *ngIf="f['phone'].errors['pattern']">Phone no. is invalid</div>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Select Business</label>


                        <select id="business" name="business" class="input-style" formControlName="business">
                            <option value="Select Business">Select Business</option>
                            <option *ngFor="let data of listOfBusiness" [value]="data['_id']">{{data?.user?.firstName || '-' +'
                                '+data?.user?.lastName || '-'}}</option>
                        </select>
                        <div class="error" *ngIf="submitted && f['business'].errors && f['business'].errors['required']">
                            Please select a business
                          </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">Select Venue Type</label>
                        <select id="turfType" name="turfType" class="input-style" formControlName="turfType">
                            <option value="Select Turf Type">Select Turf Type</option>
                            <option value="BOOK">BOOK</option>
                            <option value="CALL">CALL</option>
                        </select>
                        <div class="error" *ngIf="submitted && f['turfType'].errors">
                            <div *ngIf="f['turfType'].errors['required']">This field is required</div>
                            <div *ngIf="f['turfType'].errors['pattern']">Turf type is invalid</div>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Advance Payment Amount</label>
                            <input type="number" class="input-style" placeholder="Advance Amount" id="advanceAmount" formControlName="advanceAmount" min="0"/>
                            <div class="error" *ngIf="submitted && f['advanceAmount'].errors">
                                <div *ngIf="f['advanceAmount'].errors['required']">This field is required</div>
                                <div *ngIf="f['advanceAmount'].errors['pattern']">Advance Amount Should be in postive</div>
                              </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">Owner WhatsApp Confirmation</label>
                        <select id="ownerWhatsAppConfirmation" name="ownerWhatsAppConfirmation" class="input-style" formControlName="ownerWhatsAppConfirmation">
                            <option value="Select Option" disabled>Select Option</option>
                            <option value="Owner">Turf Owner</option>
                            <option value="Caretaker">Caretaker/Manager</option>
                        </select>
                        <div class="error" *ngIf="submitted && f['ownerWhatsAppConfirmation'].errors">
                            <div *ngIf="f['ownerWhatsAppConfirmation'].errors['required']">This field is required</div>
                            <div *ngIf="f['ownerWhatsAppConfirmation'].errors['pattern']">Owner WhatsApp Confirmation is invalid</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Recommended Venue</label>
                        <select id="recommendedVenue" name="recommendedVenue" class="input-style" formControlName="recommendedVenue" (change)="recommendedVenueChange($event)">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        <div class="error" *ngIf="submitted && f['recommendedVenue'].errors">
                            <div *ngIf="f['recommendedVenue'].errors['required']">This field is required</div>
                            <div *ngIf="f['recommendedVenue'].errors['pattern']">Recommended Venue is invalid</div>
                          </div>
                    </div>
                    <div class="col-md-6" *ngIf="addTurfForm.get('recommendedVenue')?.value == true ">
                        <label class="label mt-5">Recommended Position</label>
                        <input type="text" class="input-style" name="recommendedPosition" 
                        placeholder="Recommended Position" id="recommendedPosition" formControlName="recommendedPosition" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="label mt-5 required">Commission Rate %</label>
                        <input type="number" class="input-style"
                        placeholder="Commission" id="commission" formControlName="commission" min="0"/>
                        <div class="error" *ngIf="submitted && f['commission'].errors">
                            <div *ngIf="f['commission'].errors['required']">This field is required</div>
                            <div *ngIf="f['commission'].errors['pattern']">Commission should be in positive</div>
                          </div>
                    </div>
                    <div class="col-md-6">
                        <label class="label mt-5 required">Venue Status</label>


                        <select id="status" name="status" class="input-style" formControlName="status" id="status">
                            <option value="Select Status">Select Status</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="REJECTED">REJECTED</option>
                            <option value="PENDING">PENDING</option>
                        </select>
                        <div class="error" *ngIf="submitted && f['status'].errors">
                            <div *ngIf="f['status'].errors['required']">This field is required</div>
                            <div *ngIf="f['status'].errors['pattern']">Status is invalid</div>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label mt-5">Add Descripton</label>
                        <textarea type="text" placeholder="Description" id="description" formControlName="description"></textarea>
                        <div class="error" *ngIf="submitted && f['description'].errors">
                            <div *ngIf="f['description'].errors['required']">This field is required</div>
                            <div *ngIf="f['description'].errors['pattern']">Description is invalid</div>
                          </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="buttons">
            <button *ngIf="isAdd" class="btn-1" mat-button (click)="cancelBtnHandler()">
                Cancel
            </button>
            <button *ngIf="!isAdd" class="btn-1" mat-button (click)="closeEditBtnHandler()">
                Cancel
            </button>
            <button class="btn" mat-button (click)="isAddEditAboutLoading?null:onSubmitAboutTurf()">
                <div *ngIf="!isAddEditAboutLoading">
                    {{isAdd?'Save and Continue':'Save Changes'}}
                </div>
                <div *ngIf="isAddEditAboutLoading">
                    <mat-progress-spinner mode="indeterminate" diameter="20" style="margin: 0 auto">
                    </mat-progress-spinner>
                </div>
            </button>
        </div>
    </div>
</div>