<div *ngIf="data['source']=='Cancel Booking'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        cancel this booking?</div>
    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="cancelBooking()">Yes</button>
    </div>
</div>

<!-- <div *ngIf="data['source']=='Delete Coupon'">
    <h1 mat-dialog-title style="font-weight: 500;">Alert</h1>
    <div style="width: 25em;font-size: medium;" mat-dialog-content>Are you sure you want to
        delete this coupon?</div>

    <div mat-dialog-actions>
        <button class="no-btn" mat-button mat-dialog-close (click)="close()">No</button>
        <button class="yes-btn" mat-button mat-dialog-close (click)="deleteCoupon()">Yes</button>
    </div>
</div> -->