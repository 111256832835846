<div class="container__booking">
  <div
    class="container__booking__header"
    style="align-items: center; justify-content: space-between"
  >
    <div class="container__booking__header__title">Booking Details</div>
    <div class="clear-button" (click)="close()">
      Close<mat-icon class="mat-clear-icon">clear</mat-icon>
    </div>
  </div>

  <div class="container__booking__details">
    <div class="booking-details">
      <div class="booking-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/clarity_details-line.svg"
            alt="" /></span
        >Booking Details:
      </div>
      <div class="booking-details__content">
        <div class="booking-details__content__business">
          <div class="title">Booking ID</div>
          <div>:</div>
          <div>{{ data["booking"]["_id"] }}</div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Client Name</div>
          <div>:</div>
          <div>{{ data["booking"]["name"] }}</div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Client Phone</div>
          <div>:</div>
          <div>{{ data["booking"]["phone"] }}</div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Booking Date</div>
          <div>:</div>
          <div>{{ data["booking"]["bookingDate"] | date }}</div>
        </div>
        <div
          class="booking-details__content__business"
          *ngIf="data['booking']['sportCategory']['categoryName'] == 'Outdoor'"
        >
          <div class="title">Turf Size</div>
          <div>:</div>
          <div>{{ data["booking"]["sizeOrSport"] }}</div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">
            {{
              data["booking"]["sportCategory"]["categoryName"] == "Outdoor"
                ? "Sport"
                : "Game"
            }}:
          </div>
          <div>:</div>
          <div>{{ data["booking"]["sport"]["sport"] }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="booking-details">
      <div class="booking-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/courthouse.svg"
            alt="" /></span
        >Venue Details:
      </div>
      <div class="booking-details__content">
        <div class="booking-details__content__business">
          <div class="title">Venue Name</div>
          <div>:</div>
          <div
            *ngIf="
              data['booking']['label'] != '' ||
              data['booking']['label'] != null ||
              data['booking']['label'] != undefined
            "
          >
            {{ data["booking"]["turfId"]["name"] }} ({{
              data["booking"]["label"] ? data["booking"]["label"] : "NA"
            }})
          </div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Venue Address</div>
          <div>:</div>
          <div style="width: 18rem">
            {{ data["booking"]["turfId"]["address"]["streetName"] }} ,
            {{ data["booking"]["turfId"]["address"]["landmark"] }} ,
            {{ data["booking"]["turfId"]["address"]["city"] }} ,
            {{ data["booking"]["turfId"]["address"]["state"] }} ,
            {{ data["booking"]["turfId"]["address"]["pincode"] }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="booking-details">
      <div class="booking-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/geo_turf-size.svg"
            alt="" /></span
        >Slot Details:
      </div>
      <div class="booking-details__content">
        <div class="booking-details__content__business">
          <div class="title">Start Time</div>
          <div>:</div>
          <div>
            {{ getTimeFormat(data["booking"]["bookingSlotStartTime"]) }}
          </div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">End Time</div>
          <div>:</div>
          <div>{{ getTimeFormat(data["booking"]["bookingSlotEndTime"]) }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="booking-details">
      <div class="booking-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/tabler_transaction-rupee.svg"
            alt="" /></span
        >Transaction Details:
      </div>
      <div class="booking-details__content">
        <div class="booking-details__content__business">
          <div class="title">Total Amount</div>
          <div>:</div>
          <div>
            {{ "\u20b9" + " " + data["booking"]["totalAmount"].toFixed(2) }}
          </div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Paid Amount</div>
          <div>:</div>
          <div>
            {{ "\u20b9" + " " + data["booking"]["amountPaid"].toFixed(2) }}
          </div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Remaining Amount</div>
          <div>:</div>
          <div>
            {{
              "\u20b9" +
                " " +
                (
                  data["booking"]["totalAmount"] - data["booking"]["amountPaid"]
                ).toFixed(2)
            }}
          </div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Advance Amount</div>
          <div>:</div>
          <div>
            {{ "\u20b9" + " " + data["booking"]["turfId"]["advanceAmount"].toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="booking-details">
      <div class="booking-details__title">
        <span
          ><img
            class="image"
            src="../../../../../assets/svgIcons/status.svg"
            alt="" /></span
        >Status
      </div>
      <div class="booking-details__content">
        <div class="booking-details__content__business">
          <div class="title">Booking Status</div>
          <div>:</div>
          <div [ngClass]="{'yellow': data['booking']['bookingStatus'] === 'PENDING', 'red': data['booking']['bookingStatus'] === 'REJECTED', 'green': data['booking']['bookingStatus'] === 'COMPLETED'}">{{ data["booking"]["bookingStatus"] }}</div>
        </div>
        <div class="booking-details__content__business">
          <div class="title">Payment Status</div>
          <div>:</div>
          <div [ngClass]="{'yellow': data['booking']['paymentStatus'] === 'PENDING', 'red': data['booking']['paymentStatus'] === 'REJECTED', 'green': data['booking']['paymentStatus'] === 'SUCCESS'}">{{ data["booking"]["paymentStatus"] }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
